<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { exportFile } from 'quasar'
import { flowService } from 'src/services'

import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent ({
  name: 'ExportFlowModal',
  props: {
    flow: {},
    single: {}
  },
  components: {
    SqDialog,
    JsonEditor
  },
  emits: ['exported'],
  data() {
    return {
      isLoading: false,
      exportFlow: null
    }
  },
  computed: {
    getExport: {
      get: function() {
        if(this.exportFlow) return JSON.stringify(JSON.parse(this.exportFlow),null,2)
        return ''
      },
      set: function(val) {
        this.exportFlow = val;
      }
    }
  },
  methods: {
    fetchFlows() {
      this.isLoading = true
      flowService.exportFlow(this.$props.flow, (data) => {
        this.isLoading = false

        if (data?.['@type'] === "hydra:Error") {
          this.$router.push({
            name: 'ERROR',
            params: { n: true }
          })
        } else {
          this.exportFlow = data;
        }
      })
    },
    downloadExport() {
      const fileName = this.$props.flow ? JSON.parse(this.getExport)[0].name : window.location.hostname
      const status = exportFile(fileName + '.json', this.getExport, {
        encoding: 'windows-1252',
        mimeType: 'text/csv;charset=windows-1252;'
      })
      if (status === true) {
        this.$store.dispatch('alert/success', 'flow.overview.modal.exportSuccess', { root: true });
        this.$emit('exported')
      }
      else {
        this.$store.dispatch('alert/error', 'flow.overview.modal.exportFail', { root: true });
      }
    }
  }
})
</script>

<template>
  <sq-dialog
    type="export"
    :save-button-label="$t('flow.overview.modal.exportButton')"
    :loading="isLoading"
    :disabled="!getExport || getExport === ''"
    size="lg"
    @save="downloadExport"
    @show="fetchFlows"
    @hide="exportFlow = null"
  >
    <template #title>
      {{ !single ? $t('flow.overview.modal.exportFlow') : $t('flow.overview.exportFlowSingle') }}
    </template>

    <template #content>
      <div class="text-h6">
        {{ $t('flow.overview.modal.flowsExportText') }}
      </div>

      <json-editor
        v-model="getExport"
        wrapped
        :toolbar-config="['copy']"
        max-height="70vh"
        min-height="50vh"
        allow-empty
      />
    </template>
  </sq-dialog>
</template>
