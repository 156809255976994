<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ItemsPerPage',

    props: {
      paginationOptions: {
        type: Array,
        required: false,
        default: [5,10,15,25,50]
      },

      modelValue: {
        type: Number,
        default: 10
      },

      label: {
        type: String,
        default: 'general.itemsPerPage'
      }
    },

    emits: ['update:modelValue'],

    data () {
      return {
        localValue: this.modelValue
      }
    },

    computed: {
      requestedItemsPerPage: {
        get() {
          return this.modelValue;
        },

        set(val) {
          this.localValue = val;
          this.$emit('update:modelValue', val);
        }
      }
    }
  })
</script>

<template>
  <q-select
    v-model.number="requestedItemsPerPage"
    :label="$t(this.label)"
    behavior="menu"
    dense borderless
    :options="paginationOptions"
    class="app-items-per-page-select"
  />
</template>

<style lang="scss">
.app-items-per-page-select {
  background-color: $background2;
  width: 10rem;
}

body.body--dark {
  .app-items-per-page-select {
    background-color: $dark;
  }
}
</style>
