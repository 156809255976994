<script>
import ConfigForm from 'pages/JsonToForm/ConfigForm.vue'

export default {
  name: 'FormPreview',

  components: { ConfigForm },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      configList: [],
      selectedConfig: null,
      expertMode: false
    }
  },

  created() {
    const data = localStorage.getItem('configs')

    if (data) {
      this.configList = JSON.parse(data)
      this.selectedConfig = this.configList.find(conf => conf.id === this.id)
    }
  }
}
</script>

<template>
  <div class="form-preview q-pa-md">
    <q-breadcrumbs class="custom-breadcrumb q-mb-md">
      <q-breadcrumbs-el icon="list_alt" label="Config List" :to="{ name: 'FormBuilder' }" />
      <q-breadcrumbs-el label="Preview" icon="preview" />
    </q-breadcrumbs>

    <template v-if="selectedConfig">
      <config-form
        :show-top="false"
        :schema="selectedConfig.schema"
        :expert-mode="expertMode"
      />

      <q-page-sticky
        position="bottom-right"
        :offset="[18, 18]"
      >
        <q-btn
          fab
          color="primary"
          padding="10px"
          :label="expertMode ? 'Form Mode' : 'Expert Mode'"
          size="sm"
          class="q-ml-md"
          @click="expertMode = !expertMode"
        />
      </q-page-sticky>
    </template>

    <template>
      <div>Cannot find config</div>
    </template>
  </div>
</template>

<style lang="scss">
  .form-preview {
    min-height: calc(100vh - 100px);
  }
</style>
