import validationInfo from 'src/services/Api/validationInfo'
import entityAPI from 'src/services/Api/entityAPI'
import failedMessages from 'src/services/Api/failedMessages'
import filesystem from 'src/services/Api/filesystem'
import redisMemoryUsage from 'src/services/Api/redisMemoryUsage'
import formBuilder from 'src/services/Api/formBuilder'
import configSnippet from 'src/services/Api/configSnippet'

// register collections
export default {
  validationInfo,
  entityAPI,
  failedMessages,
  filesystem,
  redisMemoryUsage,
  formBuilder,
  configSnippet
}
