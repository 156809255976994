import client from '../helpers/authenticated-axios'

export const logService = {
  getTotalLogs(callback) {
    client().get('/log_entries').then((response) => {
      callback(response.data['hydra:totalItems'])
    });
  },
  getLogs(page, itemsPerPage, filter, messageFilter, flowExecutionFilter, callback) {
    let filterUrl = "";
    let noFilter = true;
    if (Array.isArray(filter)) {
      filter.forEach(filterItem => {
        noFilter = false;
        filterUrl += "&level_name[]=" + filterItem;
      });
    }
    if (noFilter) {
      callback('')
      return;
    }

    if(localStorage.getItem('logSort') === null) localStorage.setItem('logSort', "desc"); // Init + Fallback
    let apiUrl = '/log_entries?itemsPerPage=' + itemsPerPage;
    // '&page=' + page + filterUrl + '&message=' + messageFilter + '&flow_execution_id=' + flowExecutionFilter + '&order[datetime]=' + localStorage.getItem('logSort')
    let parameterMap = {
      page,
      message: messageFilter,
      "flow_execution_id": flowExecutionFilter,
      "order[datetime]": localStorage.getItem('logSort')
    }
    for (const [key, val] of Object.entries(parameterMap)) {
      if (val) {
        apiUrl += `&${key}=${val}`
      }
    }
    apiUrl += filterUrl

    client().get(apiUrl).then((response) => {
      callback(response.data)
    }).catch(function(error) {
      callback(error.response);
    })
  }
}
