<script>
  import { defineComponent } from 'vue'
  import usePaginator from 'src/composables/usePaginator'
  import LoadingIndicator from 'components/LoadingIndicator';
  import FailedMessageModal from 'pages/FailedMessages/components/FailedMessageModal';
  import DateDisplay from 'components/DateDisplay';
  import Paginator from 'components/Paginator.vue'

  export default defineComponent({
    name: 'FailedMessages',

    components: {
      Paginator,
      LoadingIndicator,
      DateDisplay,
      FailedMessageModal
    },

    setup () {
      const { pagination, updateRouteQuery, readFromQuery, buildQueryData } = usePaginator()

      return {
        pagination,
        readFromQuery,
        updateRouteQuery,
        buildQueryData
      }
    },

    data () {
      return {
        showFailedMessageInfo: false,
        selectedFailedMessage: null,
        failedMessages: [],
        loading: true,
        searchString: '',
        query: {
          flowExecutionId: null,
          message: null,
          "order[created_at]": "desc"
        },
        hideSearch: false,

        columns: [
          {
            name: 'fqcn',
            required: true,
            label: 'FQCN',
            align: 'left',
            field: row => row.fqcn,
            format: val => `${val}`
          },
          {
            name: 'flowExecutionId',
            required: true,
            label: this.getTranslation("flowExecutionId"),
            align: 'left',
            field: row => row.flowExecutionId,
            format: val => `${val}`
          },
          {
            name: 'message',
            required: true,
            label: this.getTranslation("message"),
            align: 'left',
            field: row => row.message,
            format: val => `${val}`
          },
          {
            name: 'createdAt',
            required: true,
            label: this.getTranslation("createdAt"),
            align: 'left',
            field: row => row.created_at,
            format: val => `${val}`
          }
        ]
      }
    },

    methods: {
      getTranslation(key) {
        return this.$t('failedMessages.label.' + key);
      },

      getFailedMessages(fromSearchInput) {
        this.loading = true;
        this.updateRouteQuery(this.pagination)

        const queryData = {
          ...this.buildQueryData(this.pagination),
          flowExecutionId: this.query.flowExecutionId,
          message: this.query.message,
          "order[created_at]": this.query["order[created_at]"]
        }

        this.$api.failedMessages.list(queryData)
          .then(res => {
            this.failedMessages = res.data["hydra:member"] ?? [];

            if (res.data["hydra:member"].length > 0) {
              this.pagination.pageCount =  Math.ceil(res.data["hydra:totalItems"] / this.pagination.itemsPerPage);
              this.hideSearch = false
            } else {
              this.hideSearch = !fromSearchInput;
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(_ => {
            this.loading = false;
          })
      },

      viewMessageDetails(evt, failedMessage) {
        this.showFailedMessageInfo = true
        this.selectedFailedMessage = failedMessage
      },

      search(searchInput) {
        this.query.flowExecutionId = searchInput;
        this.query.message = null;
        if (isNaN(searchInput)) {
          this.query.flowExecutionId = null;
          this.query.message = searchInput;
        }
        this.getFailedMessages(true);
      },

      resetPageAndReload() {
        this.pagination.page = 1
        this.getFailedMessages(!!this.searchString)
      }
    },

    created() {
      this.readFromQuery(this.pagination)
      this.getFailedMessages(false);
    }
  })
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">

      <h1>{{ $t('failedMessages.headline') }}</h1>
      <p>{{ $t('failedMessages.desc') }}</p>

      <div v-if="!hideSearch" class="row justify-end q-mb-md">
        <q-input
          v-model="searchString"
          dense
          color="primary"
          :placeholder="$t('failedMessages.searchString')"
          style="width:15rem"
          @update:model-value="search"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>

      <q-card flat>
        <q-card-section>
        <div class="row q-pa-sm">
          <q-table
            flat
            :no-data-label="$t('failedMessages.emptyList') "
            :rows="failedMessages"
            :columns="columns"
            color="primary"
            hide-pagination
            :loading="loading"
            :rows-per-page-options="[pagination.itemsPerPage]"
            class="col-12 transparent"
            @row-click="viewMessageDetails"
          >
            <template #body-cell-message="scope">
              <q-td class="font-500 ellipsis" :props="scope" style="max-width: 30vw;">
                {{ scope.row.message }}
              </q-td>
            </template>

            <template #body-cell-createdAt="scope">
              <q-td class="font-500 ellipsis" :props="scope">
                <date-display :start-time="scope.row.created_at" only-date />
              </q-td>
            </template>
          </q-table>
        </div>
        </q-card-section>
      </q-card>

      <paginator
        v-model:page="pagination.page"
        v-model:itemsPerPage="pagination.itemsPerPage"
        :total-pages="pagination.pageCount"
        class="q-mt-lg"
        @update:page="getFailedMessages"
        @update:items-per-page="resetPageAndReload"
      />
    </div>

    <failed-message-modal
      v-model="showFailedMessageInfo"
      :failed-message="selectedFailedMessage"
    />
  </q-page>
</template>

<style scoped>
  .app-empty-list {
    min-height: 100px;
  }
</style>
