export function receivedData(state, data) {
  state.heartbeat = data;
  state.isLoading = false;
}

export function startLoading(state) {
  state.heartbeat = null;
  state.isLoading = true;
}

export function startReloading(state) {
  state.isRefreshing = true;
}

export function freeComponent(state) {
  state.isRefreshing = false;
  state.isLoading = false;
}
