export default {
  common: {
    note: 'Notiz',
    action: 'Aktion | Aktionen',
    config: 'Config',
    delete: 'Löschen',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    save: 'Speichern',
    close: 'Schließen',
    saveAndClose: 'Speichern und Schließen',
    label: 'Label',
    description: 'Beschreibung | Beschreibungen',
    localization: 'Lokalisierung',
    key: 'Schlüssel | Schlüssel',
    path: 'Pfad | Pfade',
    deleteConfirmation: 'Bestätigung löschen',
    default: 'Standard',
    defaultOptions: 'Standardoptionen',
    _dataType: {
      string: 'Zeichenfolge',
      integer: 'Ganze Zahl',
      float: 'Fließkommazahl',
      boolean: 'Boolescher Wert',
      object: 'Objekt | Objekte',
      array: 'Array',
    },
    _validationMessages: {
      required: 'Dieses Feld ist erforderlich',
      number: 'Ungültiger numerischer Wert',
      min: 'Der Wert sollte nicht kleiner sein als {val}',
      max: 'Der Wert sollte nicht größer sein als {val}',
      minLen: 'Die Länge sollte nicht kleiner sein als {val}',
      maxLen: 'Die Länge sollte nicht größer sein als {val}',
      regExp: 'Ungültiges Regex-Muster erkannt',
      noWhiteSpace: 'Dieses Feld akzeptiert keine Leerzeichen',
      invalidData: 'Ungültige Daten'
    }
  }
}
