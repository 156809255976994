import { flowExecutionService } from 'src/services'

export function pageLoad({ dispatch, commit, state }, {pageNumber, itemsPerPage, flowId}) {
  commit('changePage', pageNumber);
  commit('changeItemsPerPage', itemsPerPage);
  // Axios API Request
  flowExecutionService.getFlowExecutions(state.currentPage, state.itemsPerPage,  flowId, function(data) {
    if(data.status === 401) dispatch('authentication/expired', data, {root:true});
    if(data?.['hydra:member']?.length === 0 && data?.['hydra:totalItems'] > 0) {
      // NO DATA ON THIS PAGE ANYMORE. GET THE LAST AVAILABLE ONE
      // This case can happen when being on one of the last pages, and decide to show more items per page
      let lastAvailablePage = Math.ceil(data?.['hydra:totalItems'] / itemsPerPage);
      if(lastAvailablePage < 1) lastAvailablePage = 1;
      dispatch('pageLoad', {pageNumber: lastAvailablePage, itemsPerPage: itemsPerPage });
      commit('updatePageRequest', lastAvailablePage);
    } else {
      commit('receivedItems', {
        items: data['hydra:member'],
        totalItems: data['hydra:totalItems']
      })
    }
  })
}
