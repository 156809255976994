<script>
  /**
   * Milliseconds from which the modal will not be able to close after opening it.
   * This is so when users accidentally or intentionally double-click the button or any element
   * that will open a modal it will not close immediately.
   */
  const MS_FROM_INITIAL_PERSISTENCE = 200;

  export default {
    name: 'SqDialog',

    props: {
      model: {
        type: Boolean,
        default: false
      },

      isDraggable: {
        type: Boolean,
        required: false,
        default: false
      },

      type: {
        type: String,
        required: false
      },

      customClass: {
        type: String,
        required: false
      },

      saveButtonLabel: {
        type: String,
        required: false
      },

      loading: {
        type: Boolean,
        required: false,
        default: false
      },

      loaderText: {
        type: String,
        required: false
      },

      size: {
        type: String,
        required: false,
        default: 'sm',
        validator(value) {
          return ['sm', 'md', 'lg'].includes(value)
        }
      },

      fullHeight: {
        type: Boolean,
        required: false,
        default: false
      },

      persist: {
        type: Boolean,
        required: false,
        default: false
      },

      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    emits: ['save', 'show', 'hide'],

    data() {
      return {
        // initial seconds from which the modal will be persistent. Which means it will not close if escaped or
        // the backdrop is clicked. 10 is just a placeholder so we know that we have to enable persistence on show
        initialPersistence: MS_FROM_INITIAL_PERSISTENCE,
        timeoutRunner: null
      }
    },

    computed: {
      modalConfiguration() {
        const config = {
          toolbarIcon: 'add',
          color: 'primary'
        }

        if (this.type === 'update') {
          config.toolbarIcon = 'edit'
        }

        if (this.type === 'delete') {
          config.toolbarIcon = 'delete'
          config.color = 'negative'
        }

        if (this.type === 'cancel') {
          config.toolbarIcon = 'cancel'
          config.color = 'negative'
        }

        if (this.type === 'import') {
          config.toolbarIcon = 'arrow_downward'
        }

        if (this.type === 'export') {
          config.toolbarIcon = 'arrow_upward'
        }

        if (this.type === 'viewFile') {
          config.toolbarIcon = 'insert_drive_file'
        }

        if (this.type === 'error') {
          config.toolbarIcon = 'report'
          config.color = 'negative'
        }

        return config
      },

      shouldPersist() {
        if (this.$props.persist || this.initialPersistence > 0) return true;

        return this.loading
      },

      sizeClass() {
        const sizesAndClasses = {
          sm: 'size-small',
          md: 'size-medium',
          lg: 'size-large'
        }

        return sizesAndClasses[this.size]
      }
    },

    methods: {
      handleSave() {
        this.$emit('save')
      },

      // do the countdown to lift initial persistence
      initialPersistenceCoolDown() {
        const LOOP_MS = 100;
        if (this.initialPersistence > 0) {
          this.initialPersistence -= LOOP_MS;
          if(this.initialPersistence < 0) this.initialPersistence = 0;
          this.timeoutRunner = setTimeout(() => {
            this.initialPersistenceCoolDown()
          }, LOOP_MS)
        }
      },

      handleShow() {
        this.initialPersistenceCoolDown()

        this.$emit('show')
      },

      handleHide() {
        clearTimeout(this.timeoutRunner)

        this.initialPersistence = MS_FROM_INITIAL_PERSISTENCE

        this.$emit('hide')
      },

      // TODO allow draggable modal
      handleKeypress() {}
    }
  }
</script>

<template>
  <q-dialog
    class="sq-dialog"
    ref="sqDialogRef"
    :persistent="shouldPersist"
    @show="handleShow"
    @hide="handleHide"
    @keydown="handleKeypress"
  >
    <q-card
      class="inner-container"
      :class="sizeClass, {'sq-dialog-full-height': $props.fullHeight }"
    >
      <slot name="toolbar">
        <q-toolbar class="q-my-sm">
          <q-toolbar-title class="flex items-center">
            <q-avatar
              :icon="modalConfiguration.toolbarIcon"
              :color="modalConfiguration.color"
              class="q-mr-sm"
              text-color="white"
            />
            <slot name="title"></slot>
          </q-toolbar-title>

          <q-btn
            v-close-popup
            flat
            round
            dense
            icon="close"
          />
        </q-toolbar>
      </slot>

      <q-separator />

      <q-card-section class="q-mx-md">
        <slot name="content" />
      </q-card-section>

      <q-separator />

      <q-card-actions
        align="right"
        class="q-pt-md"
      >
        <slot name="actions">
          <slot name="cancelButton">
            <q-btn
              flat
              v-close-popup
              :label="$t('general.cancel')"
              @click="handleHide"
            />
          </slot>

          <slot name="saveButton">
            <q-btn
              flat
              :color="modalConfiguration.color"
              :label="saveButtonLabel || $t('general.save')"
              :disabled="$props.disabled"
              @click="handleSave"
            />
          </slot>
        </slot>
      </q-card-actions>

      <q-inner-loading
        :showing="loading"
        color="primary"
      >
        <q-spinner
          color="primary"
          :thickness="3"
          size="38px"
        />

        <span v-if="loaderText" class="text-primary text-weight-medium">{{ loaderText }}</span>
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>

<style lang="scss">
  .sq-dialog .inner-container {
    resize: both;
    max-width: 95vw;
    min-height: 20vh;
    &.size-small {
      min-width: 40%;
    }
    &.size-medium {
      min-width: 60%;
    }
    &.size-large {
      min-width: 80%;
    }
  }

  .sq-dialog-full-height {
    min-height: 90vh;
  }
</style>
