<script>
  import { ucwords } from 'src/utils/stringUtils'
  import VueApexCharts from 'vue3-apexcharts'
  import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";
  import severityLevels from 'src/meta/severityLevels';

  export default {
    name: "SeverityLevelsChart",

    components: {
      VueApexCharts,
      CustomChartLegend,
    },
    setup() {
      return {
        severityLevels
      }
    },
    data() {
      return {
        legendData: [],
        loading: false,
        options: {
          chart: {
            type: 'pie',
            width: 350,
            height: 350,
            events: {
              dataPointSelection: (event, chartContext, config) => {
                this.clickHandler(event, chartContext, config)
              }
            }
          },
          legend: {
            show: false
          },
          labels: [],
          colors: []
        },
        series: []
      }
    },

    created() {
      this.options.colors = this.severityColors;
      this.getSeverityLevels()
    },
    computed: {
      severityColors() {
        let colors = [];
        for (const color of Object.values(severityLevels.apexChartStatusColors)) {
          colors.push(color)
        }
        // Warning and Info need to be swapped in order for whatever reason
        [colors[0], colors[2]] = [colors[2], colors[0]];
        return colors;
      }
    },
    methods: {
      clickHandler(event, chartContext, config) {
        const level = this.legendData[config.dataPointIndex]?.id;
        if(level) {
          const query = { severity: encodeURIComponent(level) };
          this.$router.push({path: 'infos', query: query});
        }
      },
      getSeverityLevels() {
        this.loading = true;
        this.$api.validationInfo.getSeverityGroup()
          .then(res => {
            if (res.data) {
              const severityGroupData = res.data['hydra:member']
              this.series = []
              this.options.labels = []
              this.legendData = [];

              severityGroupData.forEach((data, index) => {
                this.options.labels.push(this.$t('validation.severityLevelTypes.' + data['severity']))
                this.series.push(data['count'])
                this.legendData.push({
                  id: data['severity'],
                  color: this.options.colors[index],
                  description: this.$t('validation.severityLevelTypes.' + data['severity']),
                  url: '#/validation/infos' + '?severity=' + encodeURIComponent(data['severity'])
                })
              })

              this.$refs.severityLevelChart.updateOptions(this.options)
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(_ => {
            this.loading = false
          })
      }
    }
  }
</script>

<template>
  <div class="q-pa-md severity-chart">

    <div class="column items-center">
      <p class="chart-header">{{ $t('validation.severityLevels') }}</p>
    </div>

    <div class="row justify-center">
      <div class="col-xl-6 self-center">
        <vue-apex-charts
          ref="severityLevelChart"
          :width="options.chart.width"
          :type="options.chart.type"
          :options="options"
          :series="series"
          class="app-linkable-graph q-mb-lg"
        />
      </div>

      <div class="col-12 col-xl-2 self-center">
        <custom-chart-legend :legend-data="legendData" />
        <q-inner-loading
          :showing="loading"
          color="primary"
        >
          <q-spinner
            color="primary"
            size="42px"
          />
          <span class="text-primary text-weight-medium">{{ $t('validation.loadingData') }}</span>
        </q-inner-loading>
      </div>
    </div>

    <div class="absolute-center" v-if="legendData.length === 0 && !loading">
      <span class="text-primary text-weight-medium">{{ $t('validation.noData') }}</span>
    </div>
  </div>
</template>

<style lang="scss">
  .severity-chart {
    min-height: 450px;
  }
</style>
