import client from 'src/helpers/authenticated-axios'

export default {
  /**
   * Get all entities
   *
   * @returns {*}
   */
  list() {
    return client().get('/documents/')
  },

  /**
   * Get the data of the given entity
   *
   * @param entity
   * @param query
   * @param cancelToken
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  getEntityData(entity, query, cancelToken) {
    return client().get(`/documents/${entity}`, {
      params: query,
      cancelToken
    })
  },

  /**
   * Clear all data of the given entity
   *
   * @param entity
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  clearEntity(entity) {
    return client().delete(`/documents/${entity}/truncate`)
  },

  /**
   * Deletes a specific entity in our database.
   *
   * @param entityId
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteEntity(entityId) {
    return client().delete(`/documents/${entityId}`)
  },

  /**
   * Updates an entity
   *
   * @param entityId
   * @param postData
   *
   * @returns {*}
   */
  updateEntity(entityId, postData) {
    return client().put(`/documents/${entityId}`, postData)
  }
}
