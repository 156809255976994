<script>
import LoadingIndicator from 'components/LoadingIndicator.vue'
import { defineComponent } from 'vue'
import Particles from 'components/Particles'
import Logo from './components/Logo'
import LoginForm from './components/LoginForm'

export default defineComponent({
  name: 'Login',
  components: {
    LoadingIndicator,
    Particles,
    LoginForm,
    Logo
  },
  created () {
    /* Redirect to dashboard if already logged in */
    if(this.$store.state.authentication.user != null) this.$router.push({path: '/dashboard'})
  },
})
</script>

<template>
  <q-page class="app-page-login justify-center">
    <div class="row">
      <div class="app-login-col">
        <login-form />
      </div>
      <logo />
    </div>

    <particles />
    <!-- Uses free Font Awesome icons | https://fontawesome.com/license/free -->
  </q-page>
</template>
