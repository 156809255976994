import client from 'src/helpers/authenticated-axios'

const BASE_PATH = '/config-snippets'
/**
 *
 */
export default {
  /**
   * Retrieve all available config snippets. Note that it will include all the config snippets.
   *
   * @param {object} params
   *
   * @returns {Promise<Array>}
   */
  async list(params = {}) {
    return client().get(BASE_PATH, {
      params
    })
  },

  /**
   * Get a Config Snippet based on a given ID
   *
   * @param {string} id - The id of the Config Snippet to retrieve.
   * @returns {Promise<AxiosResponse<any>>}
   */
  get(id) {
    return client().get(`${BASE_PATH}/${id}`)
  },

  /**
   * Creates a new Config Snippet.
   *
   * @param {object} payload - The data used to create the config snippet.
   * @returns {Promise<AxiosResponse<any>>}
   */
  async create(payload) {
    return client().post(BASE_PATH, payload)
  },

  /**
   * Updates a Config Snippet
   *
   * @param {string} id - the Config Snippet ID
   * @param {Object} payload - the data to update the Config Snippet from
   */
  async update(id, payload) {
    return await client().put(`${BASE_PATH}/${id}`, payload)
  },

  /**
   * Deletes a Config Snippet. Note that this endpoint will both support soft and hard delete but for safety reasons
   * we're only going to expose soft-deleting
   *
   * @param {string} id - the config snippet ID
   * @returns {Promise<AxiosResponse<any>>}
   */
  async remove(id) {
    return client().delete(`${BASE_PATH}/${id}`)
  }
}
