/**
 * A global event bus. This is mainly used to fire and listen events globally.
 * Since in Vue3 the $on and $off built in methods where removed we need to create something
 * that would emulate the same behavior.
 *
 * @author Chaps
 */
class EventBus {
  /**
   * EventBus constructor
   */
  constructor() {
    this.events = {};
  }

  /**
   * Call an event from the event register. It will call all the methods that subscribes to this event.
   *
   * @param eventName
   * @param data
   */
  fire(eventName, data) {
    const event = this.events[eventName];

    if (event) {
      event.forEach((fn) => {
        fn(data);
      });
    }
  }

  /**
   * Register a new single event. The event will be added to the event register as a unique key.
   * An event key will have an array consisting of methods that will be triggered when the event is called.
   *
   * Note. Functions will stack up in the event key and manual clean up is necessary to prevent duplicated methods being executed.
   *
   * ex.
   *
   * 'ADD_USER_EVENT': [
   * 		function_one(),
   * 		function_two()
   * ]
   *
   * @param eventName
   * @param method
   */
  listen(eventName, method) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(method);
  }

  /**
   * Remove an event from the event registry. Used for manual clean up of events.
   *
   * @param eventName
   */
  off(eventName) {
    delete this.events[eventName];
  }

  /**
   * Clears all registered events
   */
  clear() {
    this.events = {};
  }
}

export default EventBus;
