export function receivedItems(state, {items, totalItems}) {
  state.items = items;
  state.totalItems = totalItems;
  state.isLoading = false;
}

export function changePage(state, nextPage) {
  state.items = [];
  state.isLoading = true;
  state.totalItems = 0;
  state.currentPage = nextPage;
}

export function changeItemsPerPage(state, itemsPerPage) {
  state.itemsPerPage = itemsPerPage;
  state.isLoading = true;
}

export function updatePageRequest(state, page) {
  state.currentPage = page;
}
