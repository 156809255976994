<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Error',
  methods: {
    goBack() {
      this.$router.go(-2);
    }
  }
})
</script>

<template>
  <div class="app-error-page fullscreen bg-primary text-white text-center q-pa-md flex flex-center">
    <div>
      <div style="font-size: 30vh">
        {{ $t('error.404.title') }}
      </div>

      <div class="text-h2" style="opacity:.4">
        {{ $t('error.404.desc') }}
      </div>
      <div class="app-error-action-container q-mt-xl">
        <q-btn
                class="app-action-btn q-mx-sm"
                unelevated
                to="/"
                :label="$t('error.backToIndex')"
        />
        <q-btn
                class="app-action-btn q-mx-sm"
                unelevated
                @click="goBack()"
                :label="$t('error.backToPrevious')"
                v-if="!$route.params.n"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .q-btn:hover {
    text-decoration: none;
  }
  .app-error-action-container .app-action-btn {
    background-color: darken(#29D3BF1A, 40%);
  }
</style>
