import { Notify } from 'quasar'
import { StatusCodes } from 'http-status-codes'
import { authService } from 'src/services'

/**
 * Handle successful response from the API
 *
 * @param response
 *
 * @returns {*}
 */
export const responseSuccess = (response) => {
  return response
}

/**
 * Handle error response from the API
 *
 * @param error
 * @returns {Promise<never>}
 */
export const responseError = (error) => {
  /**
   * handle axios cancellable request response. The cancelled request does not contain a response key rather only
   * a message key which is configurable.
   */
  if (!error.response) {
    return Promise.reject({
      cancelled: true,
      content: error
    });
  }

  if (error.response.status === StatusCodes.UNAUTHORIZED) {
    authService.logout()
    window.localStorage.setItem('isUnauthorized', JSON.stringify(true))
    window.location.reload()
  }

  else if (error.response.status === StatusCodes.FORBIDDEN) {
    Notify.create({
      message: 'Oops! You do not have enough permission or your session may have expired. Refresh the page to know if you need to login again.',
      multiLine: true,
      color: 'negative',
      actions: [
        {
          label: 'Okay',
          color: 'white',
          handler: () => {
            authService.logout()
            window.location.reload();
          }
        }
      ]
    })
  }

  else if (error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR) {
    Notify.create({
      message: 'Oops! Something went wrong. Please report this to your technical support.',
      multiLine: true,
      color: 'negative',
      actions: [
        {
          label: 'View details',
          color: 'white',
          handler: () => {
            window.localStorage.setItem('isInternalServerError', JSON.stringify(error.response));
            window.location.reload();
          }
        }
      ]
    })
  }

  return Promise.reject(error);
}

