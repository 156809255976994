<script>
import MenuLink from 'layouts/menu/components/MenuLink'

export default {
  name: "DropdownMenu",

  components: {
    MenuLink
  },

  props: {
    menu: {
      type: Object,
      required: true
    },

    isOpened: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      opened: this.isOpened
    }
  },
  computed: {
    isInSubmenu() {
      return !!this.$route.path.includes(this.menu.link)
    }
  }
}
</script>

<template>

  <q-list>
    <q-expansion-item
      v-model="opened"
      expand-separator
      :icon="menu.icon ? menu.icon : 'label'"
      :label="menu.title || 'Menu Label'"
      :header-class="isInSubmenu ? 'q-item--active' : null"
    >
      <q-list class="full-width q-ml-lg">
        <menu-link
          v-for="childMenu in menu.children"
          :title="childMenu.title"
          :caption="childMenu.caption"
          :icon="childMenu.icon"
          :link="childMenu.link"
        />
      </q-list>
    </q-expansion-item>
  </q-list>
</template>
