<script>
import VueApexCharts from 'vue3-apexcharts'
import CustomChartLegend from "components/Charts/Legend/CustomChartLegend";
import severityLevels from 'src/meta/severityLevels'
import SelectInputFilter from "components/Charts/Filter/SelectInputFilter";

export default {
  name: "CommonMessagesChart",

  components: {
    VueApexCharts,
    CustomChartLegend,
    severityLevels,
    SelectInputFilter
  },

  data() {
    return {
      severities: Object.keys(severityLevels.colors),
      entityTypes: [],
      filters: {
        severityLevel: [],
        documentClass: [],
      },
      legendData: [],
      loading: false,
      options: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: false
          }
        },
        title: {
          text: ''
        },
        tooltip: {
          y: {
            formatter: () => null,
            title: {
              formatter: (val, i) => { return this.legendData[i.dataPointIndex].description }
            },
          }
        },
        colors: ['#3363FF', '#332EE8', '#7940FF', '#992EE8', '#DF33FF'],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        }
      },
      series: [
        {
          data: []
        }
      ],
    }
  },

  created() {
    this.getCommonMessageGroup()
    this.getEntityListFilter();
  },

  methods: {
    getCommonMessageGroup(params) {
      this.loading = true;
      this.$api.validationInfo.getCommonMessageGroup(params)
        .then(res => {
          if (res.data) {
            const commonMessageGroupData = res.data['hydra:member']
            this.series[0].data = []
            this.legendData = [];

            commonMessageGroupData.forEach((data, index) => {
              this.series[0].data.push({x: "" + data['count'], y: data['count']});
              this.legendData.push({
                color: this.options.colors[index],
                description: data['count'] + ': ' + data['message'],
                url: '#/validation/infos' + '?message=' + encodeURIComponent(data['message'].split('{')[0]) // @TODO @API: Provide technical identifier filter
              })
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },
    getEntityListFilter() {
      this.loading = true;

      this.$api.entityAPI.list()
        .then(res => {
          const entityListData = res.data['hydra:member']
          entityListData.forEach(data => {
            this.entityTypes.push(data.collectionName)
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false;
        })
    },
    updateSeverityLevels(newSeverities) {
      this.filters.severityLevel = newSeverities;
    },
    updateEntityType(newEntities) {
      this.filters.documentClass = newEntities;
    },
    clickHandler(event, chartContext, config) {
      // @TODO @API: Provide technical identifier filter
      const desc = this.legendData[config.dataPointIndex]?.description;
      if(desc) {
        const query = { message: encodeURIComponent(desc.split('{')[0]) }; // Need to slice off when a variable gets passed, or filter will fail
        this.$router.push({path: 'infos', query: query});
      }
    }
  },

  watch: {
    filters: {
      handler: function (newFilters) {
        this.getCommonMessageGroup({ severityLevel: newFilters.severityLevel, documentClass: newFilters.documentClass });
      },
      deep: true
    }
  }
}
</script>


<template>
  <div class="q-pa-md common-messages-chart">

    <div class="column items-center">
      <p class="chart-header">{{ $t('validation.commonMessages') }}</p>

      <div class="chart-filter">
        <select-input-filter v-on:updateModel="updateSeverityLevels" type="multiple" :options="severities" :label="$t('validation.severity')" translatable />
        <select-input-filter v-on:updateModel="updateEntityType" type="multiple"  :options="entityTypes" :label="$t('validation.entityType')" class="q-mt-sm" />
      </div>
    </div>

    <div class="row justify-center items-center">
        <div class="col-xl-4 justify-center items-center">
          <vue-apex-charts
            ref="commonChart"
            :type="options.chart.type"
            :options="options"
            :series="series"
            class="validation-graph app-linkable-graph q-pl-sm"
            @click="clickHandler"
          />
        </div>

        <div class="col-xl-4 self-center">
          <custom-chart-legend :legend-data="legendData" v-if="!loading" />
          <q-inner-loading
            :showing="loading"
            color="primary"
          >
            <q-spinner
              color="primary"
              size="42px"
            />
            <span class="text-primary text-weight-medium">{{ $t('validation.loadingData') }}</span>
          </q-inner-loading>
        </div>
      </div>

      <div class="absolute-center" v-if="legendData.length === 0 && !loading">
        <span class="text-primary text-weight-medium">{{ $t('validation.noData') }}</span>
      </div>
  </div>
</template>

<style lang="scss" scoped>
  .common-messages-chart {
    min-height: 400px;
  }
</style>
