<script>
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  export default {
    name: 'SqSwitch',
    components: { SqMarkdownDisplay },

    props: {
      modelValue: {
        type: Boolean
      },

      hint: {
        type: String,
        required: false,
        default: ''
      }
    },

    emits: ['update:model-value'],

    data() {
      return {
        value: this.modelValue
      }
    },

    methods: {
      updateModelValue() {
        this.$emit('update:model-value', this.value)
      }
    },

    watch: {
      modelValue(value) {
        if (value !== this.value) {
          this.value = value
        }
      }
    }
  }
</script>

<template>
  <div>
    <q-toggle
      v-model="value"
      v-bind="$attrs"
      class="full-width"
      @update:model-value="updateModelValue"
    />

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>
  </div>
</template>
