import client from '../helpers/authenticated-axios'

export const supervisorService = {
  getSupervisor(callback) {
    client().get('/supervisors').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  restartSupervisor(callback) {
    client().get('/supervisors/restart').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  refreshSupervisor(callback) {
    client().get('/supervisors/refresh').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  }
};
