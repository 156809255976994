<script>
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'
import { useQuasar, exportFile } from 'quasar'
import { fileNameRules } from 'src/helpers/name-validator'
import JSZip from "jszip";
import LoadingIndicator from 'components/LoadingIndicator.vue'
import Alert from 'components/Alert.vue'
import AddFileModal from './modal/AddFileModal'
import DeleteFileModal from './modal/DeleteFileModal'
import ViewFileModal from './modal/ViewFileModal'
import DownloadModal from './modal/DownloadModal'
import {fileManagerService} from "src/services";

export default defineComponent({
  name: 'FileManager',
  emits: ["pathUpdate"],
  components: {
    AddFileModal,
    ViewFileModal,
    DeleteFileModal,
    DownloadModal,
    LoadingIndicator,
    Alert
  },
  props: {
    path: {
      type: String,
      default: "/",
      required: true
    },
    filesystemName: {
      type: String,
      required: true
    }

  },
  setup () {
    const $q = useQuasar();
    return {
      qInstance: $q
    }
  },
  data () {
    return {
      viewedFile: null,
      showAddFileModal: false,
      showViewFileModal: false,
      showDeleteFileModal: false,
      showDownloadModal: false,
      nameNewFolder: false,
      newFolderInputModel: ref(''),
      changeName: false,
      changeNameInputModel: ref(''),
      selectedFile: [],
      selectedFileIndex: [],
      copyCount: 0,
      cutCount: 0
    }
  },
  computed: {
    ...mapGetters('fileManager', [
      'isLoading',
      'isRefreshing',
      'filemanager',
      'fileContent'
    ]),
    selectedFoldersOnly() {
      if(this.selectedFile.length === 0) return false;
      for(const file of this.selectedFile) if(file.type === "file") return false;
      return true;
    }
  },
  watch: {
    filesystemName: {
      handler: function () {
        this.updateCutCopyCounter();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('fileManager/filemanagerLoad', this.path);
    this.updateCutCopyCounter();
  },
  methods: {
    isImage(file) {
      if (!file) return false

      const allowedImageFormats = ["png", "jpg", "jpeg", "gif", "webp"];
      let isImage = false;
      allowedImageFormats.forEach(fileFormat => {
        if(typeof file.meta_data.path !== "undefined" && file.meta_data.path.toLowerCase().endsWith("." + fileFormat)) {
          isImage = true;
        }
      });
      return isImage;
    },
    fileFormat(filename) {
      if (!filename) return false
      return filename.split('.').pop();
    },
    updateCutCopyCounter() {
      this.copyCount = 0;
      this.cutCount = 0;
      const items = JSON.parse(localStorage.getItem("pasteItems"));

      if (items?.filesystem === this.filesystemName) {
        const length = items.data.length;
        if (items.action === 'copy') {
          this.copyCount = length;
          return;
        }
        this.cutCount = length;
      }
    },
    base64Decode(str) {
      try {
        return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      } catch(e) {
        this.$store.dispatch('alert/error', e,{ root: true });
      }
    },
    base64Encode(str) {
      try {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
              return String.fromCharCode('0x' + p1);
            })
        );
      }
      catch(e) {
        this.$store.dispatch('alert/error', e,{ root: true });
      }
    },
    resetSelection() {
      this.selectedFile = [];
      this.selectedFileIndex = [];
    },
    handleNameInputShortcuts(e, method, input, modelVar) {
      if (e === void 0) {
        return
      }
      if (e.key === "Escape") {
        this[modelVar] = false;
      }
      if (e.key === "Enter") {
        this[method](input);
      }
    },
    fileNameRules (val, castAsBool) {
      return fileNameRules(val, castAsBool, this, true)
    },
    addFolder(name) {
      if(name !== '') {
        this.nameNewFolder = false;
        this.changeName = false;
        this.$store.dispatch('fileManager/addFolder', {
          path: this.path,
          folder: name
        });
        this.newFolderInputModel = '';
      }
    },
    addFile() {
      this.nameNewFolder = false;
      this.changeName = false;
      this.showAddFileModal = true
    },
    renameItem (name) {
      if(name !== '') {
        this.nameNewFolder = false;
        this.changeName = false;
        this.$store.dispatch('fileManager/renameFile', {
          path: this.path,
          oldName: this.selectedFile[this.selectedFile.length - 1].basename,
          newName: name
        });
        this.resetSelection();
        this.changeNameInputModel = '';
      }
    },
    pasteItem () {
      let itemData = JSON.parse(localStorage.getItem("pasteItems"))
      let files = itemData.data;
      let action = itemData.action;

      this.copyCount = 0;
      this.cutCount = 0;
      localStorage.removeItem("pasteItems");

      if (action === 'copy') {
        const fs = this.path.split("/", 2).join("/") + "/"
        const subPath = this.path.replace(fs, "")
        let copiesInsideItself = false;
        for(const file of files) {
          if(subPath.startsWith(file.path)) {
            copiesInsideItself = true;
            break;
          }
        }
        if(!copiesInsideItself) {
          this.$store.dispatch('fileManager/copyFile', {
            path: this.path,
            file: files
          });
        } else {
          this.$store.dispatch('alert/error', 'browser.copyInsideItselfError', { root: true });
        }
        return;
      }

      this.$store.dispatch('fileManager/moveFile', {
        path: this.path,
        file: files,
      });
    },
    copyItem () {
      this.nameNewFolder = false;
      this.changeName = false;
      this.cutCount = 0;
      this.copyCount = this.selectedFile.length
      localStorage.setItem("pasteItems", JSON.stringify({ filesystem: this.filesystemName, action: "copy", data: this.selectedFile }));
      this.resetSelection();
    },
    backToParentFolder() {
      let path = this.path;
      if(path.endsWith("/")) path = path.slice(0, -1);
      if(!this.isLoading && !this.isRefreshing) this.updateFilemanager(path.substring(0, path.lastIndexOf('/')));
    },
    moveItem () {
      this.nameNewFolder = false;
      this.changeName = false;
      this.copyCount = 0;
      this.cutCount = this.selectedFile.length
      localStorage.setItem("pasteItems", JSON.stringify({ filesystem: this.filesystemName, action: "move", data: this.selectedFile }));
      this.resetSelection();
    },
    downloadItem() {
      this.showDownloadModal = true;
    },
    handleContext(item, index) {
      if(!this.isLoading && !this.isRefreshing) {
        this.resetSelection();
        this.selectFile(item, index)
        if (item.type === "dir") {
          this.updateFilemanager(this.path + "/" + item.path.substring(item.path.lastIndexOf('/') + 1));

          return
        }
        this.showViewFileModal = true
        this.viewedFile = item
      }
    },
    mobileSelect(evt, file, index) {
      this.selectFile(file, index);
    },
    handleFileViewerClose() {
      this.showViewFileModal = false
      this.viewedFile = null
    },
    handleDownloadClose() {
      this.showDownloadModal = false
    },
    updateFilemanager(newPath) {
      this.resetSelection();
      this.$store.dispatch('fileManager/filemanagerLoad', newPath);
      this.$emit('pathUpdate', newPath)
    },
    selectFile(file, index) {
      if(!this.changeName) {
        let i = this.selectedFileIndex.indexOf(index);
        if (i === -1) {
          this.selectedFile.push(file);
          this.selectedFileIndex.push(index);
        } else {
          this.selectedFile.splice(i, 1);
          this.selectedFileIndex.splice(i, 1);
        }
      }
    },
    handleDeleted() {
      this.showDeleteFileModal = false
      this.resetSelection()
    }
  }
})
</script>

<template>
  <loading-indicator v-if="isLoading" wrapper />
  <div class="q-pt-md q-gutter-sm" v-if="!isLoading">
    <div v-if="filemanager !== null" class="q-mx-md flex q-gutter-xs">
      <span>
        <q-btn flat dense
               icon-right="create_new_folder"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.addFolder')"
               :title="$t('browser.addFolder')"
               @click.capture.stop='this.nameNewFolder = !this.nameNewFolder, this.changeName = false'
               :disabled="isRefreshing"
               data-cy="addFolder"
        />
      </span>
      <span>
        <q-btn flat dense
               icon-right="note_add"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.addFile')"
               :title="$t('browser.addFile')"
               @click.capture.stop='addFile()'
               :disabled="isRefreshing"
               data-cy="addFile"
        />
      </span>
      <span>
        <q-btn flat dense icon-right="file_copy"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.copy')" :title="$t('browser.copy')"
               @click.capture.stop='copyItem()'
               :disabled="selectedFileIndex.length === 0 || isRefreshing"
               data-cy="copyFile"
        >
          <q-badge v-if="copyCount > 0" class="z-top" color="#332EE8" :label="copyCount" rounded floating></q-badge>
        </q-btn>
      </span>
      <span>
        <q-btn flat dense icon-right="drive_file_rename_outline"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.rename')" :title="$t('browser.rename')"
               @click.capture.stop='this.changeName = !this.changeName, this.nameNewFolder = false'
               :disabled="selectedFileIndex.length === 0 || isRefreshing"
               data-cy="renameFile"
        />
      </span>
      <span>
        <q-btn flat dense icon-right="content_cut"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.cut')" :title="$t('browser.cut')"
               @click.capture.stop='moveItem()'
               :disabled="selectedFileIndex.length === 0 || isRefreshing"
               data-cy="cutFile"
        >
          <q-badge v-if="cutCount > 0" class="z-top" color="#332EE8" :label="cutCount" rounded floating></q-badge>
        </q-btn>
      </span>
      <span>
        <q-btn flat dense icon-right="delete"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.delete')" :title="$t('browser.delete')"
               @click.capture.stop="showDeleteFileModal = true"
               :disabled="selectedFileIndex.length === 0 || isRefreshing"
               data-cy="deleteFile"
        />
      </span>
      <span>
        <q-btn flat dense icon-right="content_paste"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.paste')" :title="$t('browser.paste')"
               @click.capture.stop='pasteItem()'
               :disabled="isRefreshing || (copyCount+cutCount) === 0"
               data-cy="pasteFile"
        />
      </span>
      <span>
        <q-btn flat dense icon-right="download"
               class="col-5 col-sm-3 col-md q-mr-sm justify-end app-action-btn"
               :label="$t('browser.download')"
               :title="$t('browser.download')"
               @click.capture.stop='downloadItem()'
               :disabled="selectedFileIndex.length === 0 || selectedFoldersOnly || isRefreshing"
               data-cy="downloadFile"
        />
        <q-tooltip
            anchor="top middle" self="bottom middle"
            v-if='selectedFoldersOnly && !isRefreshing && !isLoading'
            class="app-tooltip-mobile"
        >
          {{ $t('browser.folderDownloadNotPossible') }}
        </q-tooltip>
      </span>
    </div>
    <div class="q-mx-md q-mt-md flex">
      <q-btn flat dense round icon="arrow_back"
             class="col q-mr-sm justify-end app-action-btn"
             :title="$t('browser.backToParentFolder')"
             @click.capture.stop='backToParentFolder()'
             :disabled="isRefreshing"
             v-if="this.path.match((/\//g) || []).length > 1"
             data-cy="backToParentFolder"
      />
    </div>

    <div>
      <q-input dense outlined autofocus
               v-if="nameNewFolder === true"
               v-model="newFolderInputModel"
               :placeholder="$t('browser.nameInput.newFolder')"
               :rules="[fileNameRules]"
               @keydown="handleNameInputShortcuts($event, 'addFolder', newFolderInputModel, 'nameNewFolder')"
               class="q-mx-sm"
               data-cy="folderNameInput"
      >
        <template v-slot:after>
          <q-btn round dense flat icon="cancel" @click.capture.stop='this.nameNewFolder = false' data-cy="folderNameInputCancel" />
          <q-btn :disable="!newFolderInputModel || fileNameRules(newFolderInputModel, true)" round dense flat icon="send" @click.capture.stop='this.addFolder(newFolderInputModel)' data-cy="folderNameInputSubmit" />
        </template>
      </q-input>

      <q-input dense outlined autofocus
               v-if="changeName === true"
               v-model="changeNameInputModel"
               :placeholder="$t('browser.nameInput.changeName')"
               :rules="[fileNameRules]"
               @keydown="handleNameInputShortcuts($event, 'renameItem', changeNameInputModel, 'changeName')"
               class="q-mx-sm"
               data-cy="renameInput"
      >
        <template v-slot:after>
          <q-btn round dense flat
                 icon="cancel"
                 @click.capture.stop='this.changeName = false'
                 data-cy="renameInputCancel"
          />
          <q-btn round dense flat
                 :disable="!changeNameInputModel || fileNameRules(changeNameInputModel, true)"
                 icon="send"
                 @click.capture.stop='this.renameItem(changeNameInputModel)'
                 data-cy="renameInputSubmit"
          />
        </template>
      </q-input>

    </div>

    <div class="q-ma-none q-pa-sm">
      <q-list class="app-filemanager row">
        <div class="q-ma-md" v-if="filemanager && filemanager.length === 0">{{ $t('browser.emptyFolder') }}</div>
        <template v-if="!(filemanager && filemanager.length === 0)"
                  v-for="(file, index) in filemanager"
        >
          <q-item v-if="typeof file.type !== 'undefined'"
                  :class="{
                  'active': selectedFileIndex !== null && selectedFileIndex.indexOf(index) !== -1,
                  'renaming': changeName === true && selectedFileIndex[selectedFileIndex.length - 1] === index
                  }"
                  clickable v-ripple
                  :disable="isRefreshing || changeName"
                  class="col-12 col-sm-6 col-lg-4 col-xl-3"
                  @click.capture.stop="!$q.platform.is.mobile ? selectFile(file, index) : handleContext(file, index)"
                  @dblclick.capture.stop="!$q.platform.is.mobile ? handleContext(file, index): null"
                  v-touch-hold="$q.platform.is.mobile ? event => mobileSelect(event, file, index) : null"
          >
            <template v-slot:default>
              <q-item-section class="flex">
                <div class="flex items-center">
                  <q-icon class="q-mr-xs" :name="file.type === 'dir' ? 'folder' : 'insert_drive_file'" />
                  <span>{{ file.basename }}</span><br>
                </div>
              </q-item-section>
            </template>
          </q-item>
        </template>
      </q-list>
    </div>

    <add-file-modal
        v-model="showAddFileModal"
        :path="path"
        :base64-encode="base64Encode"
        @created="showAddFileModal = false"
    />

    <delete-file-modal
        v-model="showDeleteFileModal"
        :path="path"
        :file="selectedFile"
        @deleted="handleDeleted"
    />

    <view-file-modal
        v-model="showViewFileModal"
        :file="viewedFile"
        :path="path"
        :base64-decode="base64Decode"
        :base64-encode="base64Encode"
        @hide="handleFileViewerClose"
        @saved="handleFileViewerClose"
    />

    <download-modal
        v-model="showDownloadModal"
        :selected-file="selectedFile"
        :path="path"
        @hide="handleDownloadClose"
        @saved="handleDownloadClose"
    />
  </div>
</template>

<style lang="scss">
.app-browser-filesystem-item-wrapper {
  cursor: pointer;
  transition: .33s;
  .app-browser-filesystem-item {
    padding: .25rem .5rem;
  }
}
.q-tree.app-browser-filetree > .q-tree__node--child {
  > .q-tree__node-header {
    padding-left: unset;
  }
}

.app-filemanager .q-item {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &.active {
    i.q-icon {
      color: $primary;
    }
  }
  &.renaming {
    border: 2px dashed black;
  }
}

.app-filemanager {
  .q-focusable:focus > .q-focus-helper, body.desktop .q-manual-focusable--focused > .q-focus-helper, body.desktop .q-hoverable:hover > .q-focus-helper,
  .q-focus-helper:before {
    background: unset !important;
  }
}

body.body--light {
  .app-browser-filesystem-item-wrapper,
  .app-filemanager .q-item {
    &.active {
      background: mix($dark, $background, 10%);
    }
    &:hover {
      background: mix($dark, $background, 15%);
    }
  }
}
body.body--dark {
  .app-browser-filesystem-item-wrapper,
  .app-filemanager .q-item {
    &.active {
      background: mix($light, $dark-page, 10%);
    }
    &:hover {
      background: mix($light, $dark-page, 15%);
    }
  }
}
</style>
