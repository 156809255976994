<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { userManagementService } from 'src/services'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'DeleteUserModal',

  components: {
    SqDialog
  },

  props: {
    user: {
      type: Object
    }
  },

  emits: ['deleted'],

  setup (props, { emit }) {
    const $store = useStore();

    const isLoading = ref(false)

    function deleteUser() {
      isLoading.value = true

      userManagementService.deleteUser(props.user, (data) => {
        isLoading.value = false;

        if (data?.data?.['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', "user.deleteUserFail", { root: true })
        } else {
          $store.dispatch('alert/success', "user.deleteUserSuccess", { root: true })

          emit('deleted', data)
        }
      })
    }

    return {
      isLoading,

      deleteUser
    }
  }
})
</script>

<template>
  <sq-dialog
    type="delete"
    :save-button-label="$t('general.delete')"
    :loading="isLoading"
    @save="deleteUser"
  >
    <template #title>
      {{ $t('user.deleteUser') }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('user.deleteUserConfirm') }}
        <b class="app-text-weight-semibold">{{ user.email }}</b>?
      </div>
    </template>
  </sq-dialog>
</template>
