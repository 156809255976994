<script>
  import { mapActions } from 'vuex'
  import { noSpace, required } from 'pages/JsonToForm/Helpers/validations'
  import FormBuilder from 'pages/JsonToForm/FormBuilder'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog'
  import TabLabelGenerator from 'pages/JsonToForm/Fields/TabLabelGenerator'
  import SqCheckbox from 'pages/JsonToForm/Fields/SqCheckbox.vue'
  import SqSelectWithAdd from 'pages/JsonToForm/Fields/SqSelectWithAdd.vue'
  import LocalizationGenerator from 'pages/JsonToForm/Fields/LocalizationGenerator.vue'

  export default {
    name: 'ConfigObjectWrapper',

    components: {
      LocalizationGenerator,
      SqSelectWithAdd,
      SqCheckbox,
      FormBuilder,
      TabLabelGenerator,
      ConfirmationDialog
    },

    props: {
      modelValue: {
        type: Object,
        required: true
      },

      wrapperId: {
        type: String,
        required: true
      },

      parentTabLabels: {
        required: false
      },
    },

    emits: ['update-schema', 'delete', 'copy'],

    data() {
      return {
        hasValidationError: false,
        showConfirmation: false,
        keyName: this.modelValue.name,
        label: this.modelValue.label,
        defaultCollapsed: this.modelValue.defaultCollapsed || false,
        description: this.modelValue.description,
        searchKeys: this.modelValue.searchKeys,
        tabLabels: this.modelValue.tabLabels || this.parentTabLabels || [],
        isValid: !!this.modelValue.isValid,
        wrapperType: this.modelValue.wrapperType,
        localization: this.modelValue.localization || {},
        config: {}
      }
    },

    computed: {
      isFormValid() {
        return !this.hasValidationError && !!(this.keyName && this.label && this.tabLabels.length)
      }
    },

    methods: {
      required,
      noSpace: noSpace(),

      ...mapActions('formBuilder', ['checkToRemoveTabLabel']),

      handleConfigChange(builder) {
        this.config = builder

        this.updateConfig()
      },

      async updateConfig() {
        const isKeyNameValid = await this.$refs.keyNameRef.validate()
        const config = this.config
        this.isValid = this.isFormValid && isKeyNameValid
        this.hasValidationError = !isKeyNameValid

        this.$emit('update-schema', {
          name: this.keyName,
          label: this.label,
          description: this.description,
          tabLabels: this.tabLabels,
          isObjectConfig: true,
          isValid: this.isValid,
          defaultCollapsed: this.defaultCollapsed,
          searchKeys: this.searchKeys,
          wrapperType: this.wrapperType,
          wrapperId: this.wrapperId,
          localization: this.localization,
          schemaModel: config.schemaModel,
          uiElements: config.uiElements
        })
      },

      deleteConfig() {
        if (this.tabLabels?.length) {
          this.tabLabelRemover(this.tabLabels)
        }

        if (this.config.schemaModel) {
          this.seekAndDeleteChildTabLabels(this.config.schemaModel)
        }

        this.$emit('delete')
      },

      seekAndDeleteChildTabLabels(models) {
        for (let model in models) {
          if (models[model].tabLabels?.length) {
            this.tabLabelRemover(models[model].tabLabels)
          }

          if (models[model].isObjectConfig) {
            this.seekAndDeleteChildTabLabels(models[model])
          }
        }
      },

      tabLabelRemover(labels) {
        labels.forEach(label => this.checkToRemoveTabLabel(label))
      },

      handleParentLabelUpdate() {
        if (!this.tabLabels.length) {
          this.tabLabels = this.parentTabLabels
        }
      },

      handleCopyNode() {
        this.$emit('copy')
      }
    },

    watch: {
      parentTabLabels() {
        this.handleParentLabelUpdate()
      }
    },

    mounted() {
      // delay to prevent racing issue with the UI and model being created
      setTimeout(_ => {
        this.updateConfig({
          schemaModel: {},
          uiElements: []
        })
      }, 10)

    }
  }
</script>

<template>
  <q-card flat class="q-my-md">
    <q-expansion-item
      default-opened
      dense
      dense-toggle
      switch-toggle-side
      header-class="expansion-header"
    >
      <template #header>
        <div class="flex items-center full-width">
          <div
            class="text-weight-bolder"
            :class="{
              'text-red': !isFormValid,
              'text-grey-8': isFormValid
            }"
          >
            {{ `${label || ''}` }} {{ $t('jsonToForm.formBuilder.form.objectConfig') }}
          </div>

          <div
            v-if="!isFormValid"
            class="q-ml-md text-red"
          >
            {{ $t('jsonToForm.formBuilder.validation.invalidObjectConfig') }}
          </div>

          <div class="q-ml-auto">
            <q-btn
              dense
              flat
              icon="content_copy"
              @click.stop
            >
              <q-popup-proxy>
                <q-list dense>
                  <q-item
                    v-ripple
                    v-close-popup
                    clickable
                    @click="$emit('copy')"
                  >
                    <q-item-section>{{ $t('jsonToForm.formBuilder.form.copySingleNode') }}</q-item-section>
                  </q-item>

                  <q-item
                    v-ripple
                    v-close-popup
                    clickable
                    @click="$emit('copy', true)"
                  >
                    <q-item-section>{{ $t('jsonToForm.formBuilder.form.copyNodeWithChildren') }}</q-item-section>
                  </q-item>
                </q-list>
              </q-popup-proxy>
            </q-btn>
          </div>
        </div>
      </template>

      <div class="q-px-md q-pb-md">
        <div class="q-pt-md relative-position">
          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6 col-lg-6">
              <q-input
                ref="keyNameRef"
                v-model="keyName"
                clearable
                dense
                filled
                :label="`* ${$t('jsonToForm.formBuilder.form.keyName') }`"
                :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('jsonToForm.formBuilder.form.keyName') })), noSpace]"
                class="q-pb-md"
                @blur="updateConfig"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <q-input
                v-model="label"
                :model-value="label"
                clearable
                dense
                filled
                :label="`* ${$t('common.label') }`"
                :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('common.label') }))]"
                class="q-pb-md"
                @blur="updateConfig"
              />
            </div>
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6 col-lg-6">
              <tab-label-generator
                v-model="tabLabels"
                :parent-tab-labels="parentTabLabels"
                from="object"
                @has-error="isValid"
                @update:model-value="updateConfig"
              />
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <q-select
                v-model="wrapperType"
                dense
                options-dense
                filled
                emit-value
                map-options
                clearable
                :options="[
                  { label: $t('common._dataType.object'), value: 'object' },
                  { label: $t('common._dataType.array'), value: 'array' }
                ]"
                :label="$t('jsonToForm.formBuilder.form.wrapperType')"
                class="q-py-sm"
                @update:model-value="updateConfig"
              />
            </div>
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6 col-lg-6">
              <sq-checkbox
                v-model="defaultCollapsed"
                :label="$t('jsonToForm.formBuilder.form.defaultCollapsed')"
                @update:model-value="updateConfig"
              />
            </div>

            <div class="col-12 col-md-6 col-lg-6">
              <sq-select-with-add
                v-model="searchKeys"
                use-chips
                multiple
                :label="$t('jsonToForm.formBuilder.form.searchKey')"
                @update:model-value="updateConfig"
              />
            </div>
          </div>

          <q-input
            v-model="description"
            clearable
            dense
            filled
            type="textarea"
            :label="$t('common.description')"
            class="q-pb-md"
            @blur="updateConfig"
          />

          <localization-generator
            v-model="localization"
            class="q-mb-md"
            @update:model-value="updateConfig"
          />

          <q-btn
            dense unelevated
            size="sm"
            :label="$t('common.delete')"
            color="negative"
            icon-right="delete"
            class="config-delete-button q-mb-xs"
            @click="showConfirmation = true"
          />
        </div>

        <form-builder
          :model-value="modelValue"
          emit-event
          :parent-id="wrapperId"
          :parent-tab-labels="tabLabels"
          @update:model-value="handleConfigChange"
        />
      </div>
    </q-expansion-item>

    <confirmation-dialog
      v-model="showConfirmation"
      :title="$t('jsonToForm.formBuilder.dialog.deleteConfig')"
      @confirm="deleteConfig"
      @close="showConfirmation = false"
    >
      <template #content>
        <span>
          {{ $t('jsonToForm.formBuilder.dialog.deleteConfigMessage') }}
        </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>
