import client from 'src/helpers/authenticated-axios'

export default {
  /**
   * Fetched the JSON schema from the module's JDM start message
   *
   * @param jdmId
   * @returns {Promise}
   */
  getModuleSchema(jdmId) {
    return client().get(`/module/${jdmId}/ui-schema`)
  }
}
