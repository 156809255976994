<script>
  import { defineComponent, ref } from 'vue'
  import { mapState } from 'vuex'
  import { useQuasar } from 'quasar'

  export default defineComponent({
    name: 'Toolbar',
    emits: ["drawer-toggle"],
    setup () {
      const $q = useQuasar();
      return {
        qInstance: $q,
        tooltipMenuToggle: ref(false)
      }
    },
    computed: {
      ...mapState('preferences', ['isRefreshing']),

      isLoggedIn() {
        return (this.$store.state.authentication.user !== null) && (this.$store.state.authentication.status.loggingIn !== true)
      },
      userDisplayName () {
        return this.$store.state.authentication.user['display_name'];
      },
      userUsername () {
        return this.$store.state.authentication.user.username;
      },
      userInitials () {
        var name = this.$store.state.authentication.user['display_name'];
        if (typeof name !== "undefined") return name.split(" ").map((i) => {return i[0]}).join("");
      }
    },
    methods: {
      logout (e) {
        this.$store.dispatch('authentication/logout').then(() => {
          this.$router.push('/login')
        })
      },
      toggleDrawer() {
        this.tooltipMenuToggle = false;
        this.$emit('drawer-toggle');
      }
    }
  })
</script>

<template>
  <div class="flex full-width justify-between q-mt-sm">
    <div class="flex inline">
      <q-btn
          flat dense rounded
          class="app-topbar-btn lt-md"
          icon="img:assets/icons/drawer.svg"
          :title="$t('header.menuToggle')"
          aria-label="Menu"
          @click.capture.stop="toggleDrawer()"
      >
      <!--
      <q-tooltip class="app-tooltip-mobile" v-if='$q.platform.is.mobile' v-model="tooltipMenuToggle">{{ $t('header.menuToggle') }}</q-tooltip>
      -->
      </q-btn>
    </div>
    <div class="flex inline items-center" v-if="isLoggedIn">
      <div class="q-mr-md">
        <q-btn
            round flat
            to="/preferences"
            icon="img:assets/icons/account.svg"
            :title="$t('header.preferences')"
            class="app-topbar-btn"
            :disable="isRefreshing"
            data-cy="preferencesBtn"
        >
          <q-tooltip anchor="top middle" self="bottom middle" v-if='$q.platform.is.mobile' class="app-tooltip-mobile">{{ $t('header.preferences') }}</q-tooltip>
        </q-btn>
      </div>
      <div class="flex q-mr-sm app-user-container" :class="{'app-user-viewport-xs': $q.screen.xs}">
        <div class="app-avatar self-center non-selectable line-height-1">
          {{ userInitials }}
        </div>
        <div class="app-user q-ml-md flex column self-center non-selectable">
          <span class="app-user-real-name">{{ userDisplayName }}</span>
          <span class="app-user-technical-name">{{ userUsername }}</span>
        </div>
      </div>
      <q-btn
          round flat
          icon="img:assets/icons/logout.svg"
          class="app-topbar-btn"
          :title="$t('header.logout')"
          @click.capture.stop="logout"
      >
        <q-tooltip anchor="top middle" self="bottom middle" class="app-tooltip-mobile" v-if='$q.platform.is.mobile'>{{ $t('header.logout') }}</q-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<style lang="scss">
  .app-user div {
    line-height: 1.25;
  }
  .app-name-accent1 {
    font-weight: bold;
    color: $dark;
  }
  .app-name-accent2 {
    font-weight: bold;
    color: $primary;
  }
  .app-avatar {
    background: $primary;
    color: $light;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-user {
    .app-user-real-name {
      font-weight: bold;
    }
    .app-user-technical-name {
      font-weight: normal;
      font-size: .8rem;
    }
  }
  .app-topbar-btn {
    color: $dark;
  }
  .app-user-viewport-xs {
    .app-avatar {
      display: none;
    }
    .app-user {
      margin-left: unset;
      .app-user-real-name, .app-user-technical-name {
        margin-left: unset;
        max-width: 35vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  body.body--light {
    .app-user-real-name {
      color: $dark;
    }
    .app-user-technical-name {
      color: $subtext;
    }
  }

  body.body--dark {
    .q-drawer {
      .app-name-accent1 {
        color: white;
      }
    }
    .app-avatar {
      background: $primary-dark-page;
    }
    .app-user-real-name, .app-topbar-btn {
      color: $light;
    }
    .app-user-technical-name {
      color: $grey;
    }
  }

</style>
