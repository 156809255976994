<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'StatusBadge',
    props: ['running', 'status', 'singleBadge']
  })
</script>

<template>
  <q-badge v-if="$props.running"
           class="app-flow-status-badge justify-center running"
           color="info"
           :title="$t('flow.status.runningTooltip')"
  >
    {{ $t('flow.status.running') }}
    <div class="snippet" data-title=".dot-pulse">
      <div class="stage">
        <div class="dot-pulse"></div>
      </div>
    </div>
  </q-badge>
  <template v-if="!$props.running">
    <q-badge v-if="$props.status"
             class="app-flow-status-badge justify-center"
             color="positive"
             :label="$t('flow.status.positive')"
             :title="$t('flow.status.positiveTooltip')"
    >
    </q-badge>
    <q-badge v-if="!$props.status"
             class="app-flow-status-badge justify-center"
             color="negative"
             :label="$t('flow.status.negative')"
             :title="$t('flow.status.negativeTooltip')"
    >
    </q-badge>
  </template>
</template>
