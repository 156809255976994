/**
 * Determine if the value is null or undefined
 *
 * @param value {any}
 *
 * @returns {boolean}
 */
export function isNullOrUndefined(value) {
  return value === null || isUndefined(value)
}

/**
 * Determine if the value is undefined
 *
 * @param value {any}
 *
 * @returns {boolean}
 */
export function isUndefined(value) {
  return value === undefined;
}

/**
 * Check if a given string is a valid JSON string
 *
 * @param str {string} JSON string
 * @returns {boolean}
 */
// Function to check if a string is valid JSON or a primitive type
export function isJsonValid(str) {
  try {
    // Try parsing the string as JSON
    let parsedJson = JSON.parse(str);

    // If parsing succeeded without errors, it's valid JSON
    return parsedJson !== null && typeof parsedJson === 'object';
  } catch (error) {
    console.error(error)
    return false;
  }
}
