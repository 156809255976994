export function success({ commit }, message) {
  commit('success', message);
}

export function error({ commit }, message) {
  commit('error', message);
}

export function info({ commit }, message) {
  commit('info', message);
}

export function clear({ commit }) {
  commit('clear');
}

export function setErrorData({ commit }, report) {
  commit('setErrorData', report);
}
