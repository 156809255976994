export default {
  common: {
    note: 'Note',
    action: 'Action | Actions',
    config: 'Config | Configs',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    close: 'Close',
    saveAndClose: 'Save & Close',
    object: 'Object | Objects',
    array: 'Array',
    label: 'Label',
    description: 'Description | Descriptions',
    localization: 'Localization',
    key: 'Key | Keys',
    path: 'Path | Paths',
    deleteConfirmation: 'Delete Confirmation',
    default: 'Default',
    defaultOptions: 'Default Options',
    _dataType: {
      string: 'String',
      integer: 'Integer',
      float: 'Float',
      boolean: 'Boolean',
      array: 'Array',
      object: 'Object'
    },
    _validationMessages: {
      required: 'This field is required',
      number: 'Invalid numeric value',
      min: 'Value should not be less than {val}',
      max: 'Value should not be greater than {val}',
      minLen: 'Length should not be lesser than {val}',
      maxLen: 'Length should not be greater than {val}',
      regExp: 'Invalid regex pattern detected',
      noWhiteSpace: 'This field does not accept whitespaces',
      invalidData: 'Invalid data'
    }
  }
}
