export default function () {
  const initialState = {
    type: "alert-danger",
    color: "negative",
    message: "",
    action: null,
    label: "View",
    report: null
  }
  return initialState
}
