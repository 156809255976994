<script>
import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

export default {
  name: 'SqCheckbox',
  components: { SqMarkdownDisplay },

  props: {
    modelValue: {
      type: Boolean
    },

    hint: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:model-value'],

  data() {
    return {
      value: this.modelValue
    }
  },

  methods: {
    updateModelValue() {
      this.$emit('update:model-value', this.value)
    }
  },

  watch: {
    modelValue(value) {
      if (value !== this.value) {
        this.value = value
      }
    }
  }
}
</script>

<template>
  <div>
    <q-checkbox
      v-model="value"
      v-bind="$attrs"
      class="full-width"
      @update:model-value="updateModelValue"
    >
    </q-checkbox>

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>

    <slot name="tooltip" />
  </div>
</template>
