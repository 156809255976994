<script>
import { debounce } from 'lodash'
import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
import SqInputNumberField from 'pages/JsonToForm/Fields/SqInputNumberField.vue'
import SqCheckbox from 'pages/JsonToForm/Fields/SqCheckbox.vue'
import SqDatePicker from 'pages/JsonToForm/Fields/SqDatePicker.vue'

export default {
  name: 'SingleFieldConfig',

  components: {
    SqCheckbox,
    SqInputField,
    SqDatePicker,
    SqInputNumberField
  },

  props: {
    modelValue: {
      type: Object,
      required: false
    },

    type: {
      type: String,
      required: true
    },

    removable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['update:model-value', 'remove'],

  data() {
    return {
      key: this.modelValue?.key,
      value: this.modelValue?.value
    }
  },

  methods: {
    updateValue: debounce(function () {
      this.$emit('update:model-value', { key: this.key, value: this.value })
    }, 500)
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.key = newValue?.key
        this.value = newValue?.value
      },

      deep: true
    },
  }
}
</script>

<template>
  <div class="row q-gutter-md flex items-center">
    <div class="col">
      <sq-input-field
        v-model="key"
        label="Key Name"
        :validations="[{name: 'noSpace'}]"
        @update:model-value="updateValue"
      />
    </div>
    <div class="col flex no-wrap items-center">
      <sq-input-field
        v-if="type === 'string'"
        v-model="value"
        label="Value"
        class="full-width"
        @update:model-value="updateValue"
      />

      <sq-input-number-field
        v-else-if="type === 'numeric'"
        v-model="value"
        label="Value"
        class="full-width"
        @update:model-value="updateValue"
      />

      <sq-checkbox
        v-else-if="type === 'boolean'"
        v-model="value"
        label="Value"
        class="full-width"
        @update:model-value="updateValue"
      />

      <sq-date-picker
        v-else-if="type === 'date'"
        v-model="value"
        class="full-width BESCHREIBUNGTYP1"
        @update:model-value="updateValue"
      />

      <q-btn
        v-if="removable"
        dense
        flat
        padding="none"
        color="negative"
        icon="delete"
        class="q-ml-sm"
        @click="$emit('remove')"
      />
    </div>
  </div>
</template>
