<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  name: 'RedisMemoryUsage',

  components: {
    VueApexCharts
  },

  props: {
    col: {
      type: String,
      required: false,
      default: ''
    }
  },

  data () {
    return {
      series: [{
        name: "MB",
        data: []
      }],
      chartOptions: {
        chart: {
          id: 'area-datetime',
          type: 'area',
          zoom: {
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          },
          foreColor: this.$q.dark.isActive ? '#FFFFFF' : '#373d3f'
        },
        colors: ['#332EE8'],
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          style: 'hollow',
        },
        xaxis: {
          type: 'datetime',
          min: null,
        },
        tooltip: {
          x: {
            format: 'yyyy-MM-dd HH:mm:ss'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
      },

      selection: 'one_year',
      loading: false,
      filter: {
        tsName: "RedisMemoryUsage",
        sampleCount: 10
      }
    }
  },

  created () {
    this.getRedisMemoryUsage();
  },

  methods: {
    getRedisMemoryUsage () {
      this.loading = true;
      this.series[0].data = [];
      this.chartOptions.xaxis.min = null;

      this.$api.redisMemoryUsage.getRedisMemoryUsageTimeSeries(this.filter)
        .then(res => {
          if (res.data) {
            const data = res.data["hydra:member"];
            this.chartOptions.xaxis.min = (new Date(data[0].data.dateEndString)).getTime();

            data.forEach(value => {
              let time = (new Date(value.data.dateEndString)).getTime();
              let usage = (value.data.averageValue * 0.000001).toFixed(2)

              this.series[0].data.push([time, usage]);
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    refreshRedisMemoryUsage() {
      this.getRedisMemoryUsage()
    }
  }

}
</script>

<template>
  <div class="flex justify-between q-pt-md relative-position" :class="col">
    <q-card class="app-redis-memory-usage full-width" flat>
      <q-icon size="3rem" name="memory" class="app-card-bg-icon"></q-icon>
      <q-item>
        <q-item-section>
          <q-item-label class="app-text-weight-semibold">{{ $t('dashboard.redisMemory.headline') }}</q-item-label>
        </q-item-section>
      </q-item>

      <div class="apex-chart-container row justify-center">
        <div class="flex justify-end items-end">
          <vue-apex-charts
            v-if="!loading && series[0].data.length > 0"
            ref="redisMemoryChart"
            height="325"
            width="365"
            type="area"
            :options="chartOptions"
            :series="series"
          />
        </div>

        <div class="flex justify-start items-center">
          <q-inner-loading
            :showing="loading"
            color="primary"
          >
            <q-spinner
              color="primary"
              size="42px"
            />
            <span class="text-primary text-weight-medium">{{ $t('dashboard.redisMemory.loading') }}</span>
          </q-inner-loading>
        </div>
      </div>

      <div class="absolute-center" v-if="series[0].data.length === 0 && !loading">
        <span class="text-primary text-weight-medium">{{ $t('dashboard.redisMemory.noData') }}</span>
      </div>

      <q-card-section class="app-message-queue-action col-auto q-pl-none q-mb-sm q-mx-sm">
        <div class="flex justify-end">
          <q-btn flat dense no-caps icon-right="refresh" class="q-pr-sm justify-end app-action-btn"
                 :label="$t('dashboard.redisMemory.refresh')"
                 data-cy="redisRefreshButton"
                 :disabled="loading"
                 @click.capture.stop="loading ? null : refreshRedisMemoryUsage()"
          />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<style>
  .app-redis-memory-usage {
    min-height: 465px;
    .apexcharts-zoomout-icon {
      order: -1;
      margin-right: unset;
    }
  }
</style>
