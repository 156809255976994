<script>
  import { cloneDeep, isEmpty } from 'lodash'
  import { v4 as uuid } from 'uuid'
  import { required } from 'pages/JsonToForm/Helpers/validations'
  import {
    FIELD_DESCRIPTION,
    FIELD_DEFAULT_OPTIONS,
    FIELD_DEFAULT_PROPERTIES,
    FIELD_OBJECT_PROPERTY,
    VALIDATION_REQUIRED,
    VALIDATION_PATTERN,
    VALIDATION_MIN,
    VALIDATION_MAX,
    VALIDATION_MIN_LEN,
    VALIDATION_MAX_LEN,
    FIELD_TYPE_SELECT,
    FIELD_IS_SELECT_MULTIPLE,
    FIELD_IS_SELECT_CAN_ADD,
    FIELD_SHOULD_MASK,
    FIELD_TYPE_INPUT,
    FIELD_SEARCH_KEYS,
    FIELD_LOCALIZATION
  } from 'pages/JsonToForm/utils'

  import VueFormJsonSchema from '@synqup/synqup-test-two'
  import { notifyError } from 'src/utils/notify'

  export default {
    name: 'FormBuilder',

    components: {
      VueFormJsonSchema
    },

    props: {
      modelValue: {
        type: Object
      },

      parentId: {
        type: String,
        required: false,
        default: null
      },

      parentTabLabels: {
        required: false
      },

      emitEvent: {
        type: Boolean,
        default: false
      }
    },

    emits: ['update:model-value', 'save', 'close-builder'],

    data() {
      return {
        configName: null,
        schemaModel: {},
        schema: {},
        uiElements: [],
        options: {
          ajv: {
            options: {
              strict: false
            }
          }
        },
        VALIDATION_REQUIRED,
        VALIDATION_PATTERN
      }
    },

    computed: {
      isConfigValid() {
        return this.configName
      }
    },

    methods: {
      required,

      addPropsAndEvents(elements) {
        const uiElements = [ ...elements ]

        for (const element of uiElements) {
          if (element.children?.length) {
            this.addPropsAndEvents(element.children)
          }

          if (element.isFieldConfig) {
            element.fieldOptions.on = {
              'update:model-value': (config) => config,
              'delete': () => this.deleteField(element.id),
              'copy': () => console.log('Field Config: copy this node')
            }
          }

          if (element.isObjectConfig) {
            element.fieldOptions.on = {
              'update-schema': (config) => this.objectChangeHandler(config),
              'delete': () => this.deleteField(element.id),
              'copy': () => console.log('Object Config: copy this node')
            }
          }

          if (element.isButtonSelector) {
            element.fieldOptions.on = {
              'selected': (type) => this.handleOptionSelected(type)
            }
          }
        }

        return uiElements
      },

      initialize() {
        if (this.modelValue?.schemaModel) {
          this.configName = this.modelValue.configName
          this.schemaModel = this.modelValue.schemaModel
          this.uiElements = this.addPropsAndEvents(this.modelValue.uiElements)

          return
        }

        const uiStructure = {
          id: null,
          component: null,
          default: null,
          model: null,
          required: null,
          valueProp: 'model-value',
          fieldOptions: {
            props: {},
            on: {}
          },
          children: []
        }

        const parentId = '__elements.baseContainer'
        const baseButtonId = `${parentId}.baseButton`

        uiStructure.component = 'div'
        uiStructure.id = parentId

        const baseNewAddButton = {
          component: 'config-selector',
          model: baseButtonId,
          isButtonSelector: true,
          fieldOptions: {
            on: {
              'selected': (type) => this.handleOptionSelected(type)
            },
            props: {
              containerClass: 'q-mt-sm'
            }
          }
        }

        uiStructure.children = [baseNewAddButton]

        this.uiElements.push(uiStructure)

        this.fireEvent()

        return uiStructure
      },

      fireEvent() {
        if (this.emitEvent) {
          const schemaModel = { ...this.schemaModel }
          delete schemaModel.__elements

          this.$emit('update:model-value', {
            schemaModel: schemaModel,
            schema: this.schema,
            uiElements: this.uiElements
          })
        }
      },

      handleOptionSelected(type) {
        type === 'field_config' ? this.generateFieldConfig() : this.generateObjectConfig()
      },

      deleteField(id) {
        const index = this.uiElements.findIndex(element => element.id === id)

        if (index > -1) {
          this.uiElements.splice(index, 1)

          this.$refs.objectFormBuilder.setVfjsUiSchema(this.uiElements)

          setTimeout(_ => {
            delete this.schemaModel[id.split('.').at(-1)]

            this.fireEvent()
          }, 100)
        }
      },

      objectChangeHandler(object) {
        this.mapToParentUi(object.wrapperId, object.uiElements, object)

        return {
          name: object.name,
          label: object.label,
          description: object.description,
          isObjectConfig: object.isObjectConfig,
          tabLabels: object.tabLabels,
          isValid: object.isValid,
          wrapperType: object.wrapperType,
          defaultCollapsed: object.defaultCollapsed,
          searchKeys: object.searchKeys,
          localization: object.localization,
          ...object.schemaModel
        }
      },

      mapToParentUi(parentId, children, config) {
        const parentIndex = this.uiElements.findIndex(element => element.id === parentId)

        if (parentIndex > -1) {
          this.uiElements[parentIndex].children = children
          this.uiElements[parentIndex].fieldOptions.props.modelValue = config
        }
      },

      tabLabelUpdateCascadeToChild(parentTabLabels, elements) {
        const uiElements = elements || this.uiElements

        for (const ui of uiElements) {
          if (ui.isFieldConfig || ui.isObjectConfig) {
            ui.fieldOptions.props.parentTabLabels = parentTabLabels
          }
        }

        this.$refs.objectFormBuilder.setVfjsUiSchema(this.uiElements)
      },

      generateFieldConfig() {
        const fieldModel = `config_${uuid()}`
        const fieldIdentity = this.parentId ? `${this.parentId}.${fieldModel}` : fieldModel

        const uiStructure = {
          id: fieldIdentity,
          component: 'field-config-generator',
          model: fieldModel,
          path: fieldModel,
          isFieldConfig: true,
          valueProp: 'model-value',
          fieldOptions: {
            props: {
              parentTabLabels: this.parentTabLabels,
              id: fieldIdentity,
              parent: this.modelValue
            },
            on: {
              'update:model-value': (config) => config,
              'delete': () => this.deleteField(fieldIdentity),
              'copy': () => this.nodeDuplication(cloneDeep(uiStructure), true)
            }
          }
        }

        this.uiElements.push(uiStructure)

        this.$refs.objectFormBuilder.setVfjsUiSchema(this.uiElements)

        this.fireEvent()
      },

      generateObjectConfig() {
        const fieldModel = `config_wrapper_${uuid()}`
        const fieldIdentity = this.parentId ? `${this.parentId}.${fieldModel}` : fieldModel

        const uiStructure = {
          id: fieldIdentity,
          component: 'config-object-wrapper',
          model: fieldModel,
          path: fieldModel,
          isObjectConfig: true,
          valueProp: 'model-value',
          fieldOptions: {
            props: {
              wrapperId: fieldIdentity,
              parentTabLabels: this.parentTabLabels,
              modelValue: {}
            },
            on: {
              'update-schema': (config) => this.objectChangeHandler(config),
              'delete': () => this.deleteField(fieldIdentity),
              'copy': (children) => this.nodeDuplication(cloneDeep(uiStructure), false, children)
            }
          },
          children: []
        }

        this.uiElements.push(uiStructure)

        this.$refs.objectFormBuilder.setVfjsUiSchema(this.uiElements)

        this.fireEvent()
      },

      nodeDuplication(ui, isField, withChildren = false) {
        const sourceElIndex = this.uiElements.findIndex(element => element.id === ui.id)

        if (isField) {
          const fieldModel = `config_${uuid()}`
          const fieldIdentity = this.parentId ? `${this.parentId}.${fieldModel}` : fieldModel

          ui.id = fieldIdentity
          ui.model = fieldModel
          ui.path = fieldModel
          ui.fieldOptions.props.id = fieldIdentity
        } else {
          const fieldModel = `config_wrapper_${uuid()}`
          const fieldIdentity = this.parentId ? `${this.parentId}.${fieldModel}` : fieldModel

          ui.id = fieldIdentity
          ui.model = fieldModel
          ui.path = fieldModel
          ui.fieldOptions.props.wrapperId = fieldIdentity

          if (!withChildren) {
            ui.fieldOptions.props.modelValue.uiElements = ui.fieldOptions.props.modelValue.uiElements.filter(uiEl => uiEl.id === '__elements.baseContainer')
          } else {
            ui.fieldOptions.props.modelValue.uiElements = this.reprocessChildNodes(ui.fieldOptions.props.modelValue.uiElements, ui.id)
          }
        }

        this.uiElements.splice(sourceElIndex + 1, 0, ui)
        this.$refs.objectFormBuilder.setVfjsUiSchema(this.uiElements)
        this.fireEvent()
      },

      reprocessChildNodes(children, parentId) {
        for (let child of children) {
          if (!child.isObjectConfig && !child.isFieldConfig) continue

          if (child.isObjectConfig) {
            const fieldModel = `config_wrapper_${uuid()}`
            const fieldIdentity = parentId ? `${parentId}.${fieldModel}` : fieldModel

            child.id = fieldIdentity
            child.model = fieldModel
            child.path = fieldModel
            child.fieldOptions.props.wrapperId = fieldIdentity

            child.fieldOptions.props.modelValue.uiElements = this.reprocessChildNodes(child.fieldOptions.props.modelValue.uiElements, child.id)
          } else {
            const fieldModel = `config_${uuid()}`
            const fieldIdentity = parentId ? `${parentId}.${fieldModel}` : fieldModel

            child.id = fieldIdentity
            child.model = fieldModel
            child.path = fieldModel
            child.fieldOptions.props.id = fieldIdentity
          }
        }

        return children
      },

      generateJsonSchema() {
        const config = {
          "$schema": "https://json-schema.org/draft/2020-12/schema",
          "$id": "https://json-schema.org/draft/2020-12/schema",
          "title": this.configName,
          "type": "object",
          "properties": {
            "type": "object"
          },
          "required": []
        }

        const mappedSchema = this.buildSchema(this.schemaModel)

        config["properties"] = { ...config["properties"], ...mappedSchema.config }
        config["required"].push(...mappedSchema.required)

        return config
      },

      buildSchema(model) {
        const config = {}
        const required = []

        for (const key in model) {
          const value = model[key]

          if (!value?.isValid) continue

          if (typeof value === 'object') {
            if (value.isObjectConfig) {
              const mappedSchema = this.buildSchema(value);
              config[value.name] = {
                type: value.wrapperType,
                label: value.label,
                description: value.description,
                uiFieldType: 'wrapper',
                tabLabels: value.tabLabels,
                defaultCollapsed: value.defaultCollapsed,
                isObject: true,
                properties: mappedSchema.config,
                required: mappedSchema.required
              }

              if (value[FIELD_SEARCH_KEYS]?.length) config[value.name][FIELD_SEARCH_KEYS] = value[FIELD_SEARCH_KEYS]
              if (!isEmpty(value[FIELD_LOCALIZATION])) config[value.name][FIELD_LOCALIZATION] = value[FIELD_LOCALIZATION]
            }

            if (value.isFieldConfig) {
              config[value.keyName] = {
                type: value.dataType,
                isField: true,
                label: value.label,
                default: typeof value.default === 'boolean' ? !!value.default : value.default,
                uiFieldType: value.fieldType,
                tabLabels: value.tabLabels,
              }

              if (value[FIELD_DESCRIPTION]) config[value.keyName][FIELD_DESCRIPTION] = value[FIELD_DESCRIPTION]
              if (value[FIELD_DEFAULT_OPTIONS]?.length) config[value.keyName][FIELD_DEFAULT_OPTIONS] = value[FIELD_DEFAULT_OPTIONS]
              if (value[FIELD_DEFAULT_PROPERTIES]?.length) config[value.keyName][FIELD_DEFAULT_PROPERTIES] = value[FIELD_DEFAULT_PROPERTIES]
              if (value[FIELD_OBJECT_PROPERTY]?.length) config[value.keyName][FIELD_OBJECT_PROPERTY] = value[FIELD_OBJECT_PROPERTY]
              if (value[FIELD_SEARCH_KEYS]?.length) config[value.keyName][FIELD_SEARCH_KEYS] = value[FIELD_SEARCH_KEYS]

              if (value.fieldType === FIELD_TYPE_SELECT) {
                config[value.keyName][FIELD_IS_SELECT_MULTIPLE] = value[FIELD_IS_SELECT_MULTIPLE]
                config[value.keyName][FIELD_IS_SELECT_CAN_ADD] = value[FIELD_IS_SELECT_CAN_ADD]
              }

              if (value.fieldType === FIELD_TYPE_INPUT) {
                config[value.keyName][FIELD_SHOULD_MASK] = value[FIELD_SHOULD_MASK]
              }

              if (!isEmpty(value.validations)) {
                if (value.validations[VALIDATION_REQUIRED]) required.push(value.keyName)
                if (value.validations[VALIDATION_PATTERN]) config[value.keyName][VALIDATION_PATTERN] = value.validations[VALIDATION_PATTERN].value
                if (value.validations[VALIDATION_MIN]) config[value.keyName][VALIDATION_MIN] = value.validations[VALIDATION_MIN].value
                if (value.validations[VALIDATION_MAX]) config[value.keyName][VALIDATION_MAX] = value.validations[VALIDATION_MAX].value
                if (value.validations[VALIDATION_MIN_LEN]) config[value.keyName][VALIDATION_MIN_LEN] = value.validations[VALIDATION_MIN_LEN].value
                if (value.validations[VALIDATION_MAX_LEN]) config[value.keyName][VALIDATION_MAX_LEN] = value.validations[VALIDATION_MAX_LEN].value
              }

              if (!isEmpty(value[FIELD_LOCALIZATION])) {
                config[value.keyName][FIELD_LOCALIZATION] = value[FIELD_LOCALIZATION]
              }
            }
          }
        }

        return {
          config,
          required
        }
      },

      cleanUi(elements) {
        const schemas = cloneDeep(elements || this.uiElements)
        const uiSchemas = []

        for (const item of schemas) {
          const isConfig = item?.isObjectConfig || item?.isFieldConfig

          if (!isConfig) {
            uiSchemas.push(item)

            continue
          }

          const model = this.getUiSchemaModel(item.model)

          if (!model || !model?.isValid) {
            continue
          }

          if (item.isObjectConfig) {
            item.children = this.cleanUi(item.children)
            uiSchemas.push(item)
          } else {
            uiSchemas.push(item)
          }
        }

        return uiSchemas
      },

      getUiSchemaModel(uiModel, objectConfig) {
        const models = objectConfig || this.schemaModel

        for (const model in models) {
          const modelValue = models[model]

          if (typeof modelValue !== 'object') continue

          const isConfig = modelValue.isObjectConfig || modelValue.isFieldConfig

          if (!isConfig) continue;

          if (model === uiModel) return modelValue

          if (modelValue.isObjectConfig) {
            const childModel = this.getUiSchemaModel(uiModel, modelValue)

            if (childModel) return childModel
          }
        }

        return null
      },

      cleanModel(models) {
        for (const model in models) {
          const modelValue = models[model]

          if (typeof modelValue !== 'object') continue

          const isConfig = modelValue.isObjectConfig || modelValue.isFieldConfig

          if (!isConfig) continue;

          if (!modelValue.tabLabels?.length) {
            delete models[model]

            continue
          }

          if (modelValue.isObjectConfig) {
            this.cleanModel(modelValue)
          }
        }

        return models
      },

      getBuilderInformation() {
        const schemaModel = cloneDeep(this.schemaModel)

        delete schemaModel.__elements

        return {
          schema: this.generateJsonSchema(),
          uiElements: this.cleanUi(),
          schemaModel: this.cleanModel(schemaModel),
          configName: this.configName
        }
      },

      validateConfig(config) {
        for (const item in config) {
          const modelValue = config[item]

          if (typeof modelValue !== 'object') continue

          const isConfig = modelValue.isObjectConfig || modelValue.isFieldConfig

          if (!isConfig) continue;

          if (modelValue.isValid === false) return false

          if (modelValue.isObjectConfig) {
            const isValid = this.validateConfig(modelValue)

            if (!isValid) return false
          }
        }

        return true
      },

      async saveForm(close = false) {
        setTimeout(() => {
          if (this.validateConfig(this.schemaModel)) {
            return this.$emit('save', {
              builder: this.getBuilderInformation(),
              close
            })
          }

          notifyError(this.$t('jsonToForm.formBuilder.notification.saveFormError'))
        }, 100)
      }
    },

    mounted() {
      this.initialize()
    },

    watch: {
      schemaModel: {
        handler() {
          this.fireEvent()
        },
        deep: true
      },

      parentTabLabels: {
        handler(value) {
          setTimeout(() => {
            this.tabLabelUpdateCascadeToChild(value)
          }, 100)
        },

        deep: true
      }
    }
  }
</script>

<template>
  <q-form ref="builderBaseForm">
    <q-input
      v-if="!parentId"
      v-model="configName"
      clearable
      dense
      filled
      :rules="[required($t('jsonToForm.formBuilder.validation.isRequired', { field: $t('jsonToForm.formBuilder.table.configName') }))]"
      :label="$t('jsonToForm.formBuilder.table.configName')"
      class="q-mb-md"
    />

    <vue-form-json-schema
      ref="objectFormBuilder"
      v-model="schemaModel"
      :schema="schema"
      :ui-schema="uiElements"
      :options="options"
    />

    <div
      class="flex items-end q-mt-md"
      v-if="!parentId"
    >
      <q-btn
        dense flat
        icon-right="close"
        :label="$t('jsonToForm.formBuilder.closeBuilder')"
        size="md"
        :color="$q.dark.isActive ? 'white' : 'text-body1'"
        class="q-ml-auto q-mr-md"
        @click="$emit('close-builder')"
      />

      <q-btn
        dense unelevated
        :disable="!isConfigValid"
        color="primary"
        icon-right="save_as"
        :label="$t('common.save')"
        size="md"
        class="q-mr-md q-px-sm"
        @click="saveForm(false)"
      />

      <q-btn
        dense unelevated
        :disable="!isConfigValid"
        color="primary"
        icon-right="save"
        :label="$t('common.saveAndClose')"
        size="md"
        class="q-px-sm"
        @click="saveForm(true)"
      />
    </div>
  </q-form>
</template>
