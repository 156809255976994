import EventBus from 'src/services/EventBus'

export default ({ app }) => {
  /**
   * Registers a global custom EventBus
   * @type {EventBus}
   */
  app.config.globalProperties.$eventBus = new EventBus();
  app.provide('$eventBus', new EventBus());
};
