<script>
  import { CONFIG_SNIPPET_COLUMNS } from 'pages/ConfigSnippets/template'
  import { DEFAULT_PAGINATION, translateColumns } from 'src/utils'
  import { notifyError, notifySuccess } from 'src/utils/notify'
  import ConfigSnippetFormDialog from 'pages/ConfigSnippets/ConfigSnippetFormDialog.vue'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import ConfigSnippetPreviewDialog from 'pages/ConfigSnippets/ConfigSnippetPreviewDialog.vue'

  export default {
    name: 'ConfigSnippetIndex',

    components: {
      ConfigSnippetPreviewDialog,
      ConfirmationDialog,
      ConfigSnippetFormDialog
    },

    data() {
      return {
        DEFAULT_PAGINATION,

        loading: false,
        showSnippetFormDialog: false,
        showDeleteConfirmation: false,
        showPreviewDialog: false,
        configSnippets: [],
        selectedConfigSnippet: null
      }
    },

    computed: {
      columns() {
        return translateColumns(this.$t, CONFIG_SNIPPET_COLUMNS);
      }
    },

    methods: {
      async getSnippets() {
        try {
          this.loading = true;

          const response = await this.$api.configSnippet.list()

          if (response.data) {
            this.configSnippets = response.data.list

            return
          }

          notifyError('Something went wrong while loading config snippets. Please refresh and try again.')
        } catch (error) {
          console.error(error)

          notifyError('Something went wrong while loading config snippets. Please refresh and try again.')
        } finally {
          this.loading = false;
        }
      },

      handleSnippetCreated(configSnippet) {
        this.configSnippets.unshift(configSnippet)
        this.showSnippetFormDialog = false;
      },

      handleSnippetUpdated(configSnippet) {
        this.replaceFromList(configSnippet.id, configSnippet)
        this.showSnippetFormDialog = false
        this.selectedConfigSnippet = null
      },

      handleEditSnippet(configSnippet) {
        this.selectedConfigSnippet = { ...configSnippet }
        this.showSnippetFormDialog = true;
      },

      handlePreviewSnippet(configSnippet) {
        this.selectedConfigSnippet = { ...configSnippet }
        this.showPreviewDialog = true
      },

      handlePreviewClose() {
        this.selectedConfigSnippet = null
      },

      handleDeleteSnippet(configSnippet) {
        this.selectedConfigSnippet = { ...configSnippet }
        this.showDeleteConfirmation = true
      },

      closeDeleteSnippetDialog() {
        this.selectedConfigSnippet = null
        this.showDeleteConfirmation = false;
      },

      async handleConfirmDeleteConfigSnippet() {
        try {
          this.loading = true

          const id = this.selectedConfigSnippet.id
          const response = await this.$api.configSnippet.remove(id)

          if (response.data) {
            this.removeFromList(id)

            notifySuccess('Config Snippet successfully deleted.')

            this.showDeleteConfirmation = false

            return
          }

          notifyError('Something went wrong while deleting a Config Snippet')
        } catch (error) {
          console.error(error)

          const status = error.response?.status
          if (status === 404) {
            return notifyError('Config Snippet not found.')
          }

          notifyError('Something went wrong while deleting a Config Snippet')
        } finally {
          this.loading = false
        }
      },

      handleFormDialogClose() {
        this.showSnippetFormDialog = false
        this.selectedConfigSnippet = null
      },

      removeFromList(id) {
        const index = this.configSnippets.findIndex((cs) => Number(cs.id) === Number(id))

        if (index > -1) this.configSnippets.splice(index, 1)
      },

      replaceFromList(id, payload) {
        const index = this.configSnippets.findIndex((cs) => Number(cs.id) === Number(id))

        if (index > -1) this.configSnippets.splice(index, 1, payload)
      }
    },

    created() {
      this.getSnippets()
    }
  }
</script>

<template>
  <q-page class="block q-pa-md relative-position">
    <h1>{{ $t('configSnippets.title') }}</h1>

    <section class="q-mb-md flex">
      <q-btn
        dense
        color="primary"
        icon="add"
        :label="$t('configSnippets.newSnippet')"
        class="q-ml-auto q-pr-sm"
        @click="showSnippetFormDialog = true"
      />
    </section>

    <q-table
      hide-bottom
      :rows="configSnippets"
      :columns="columns"
      :rows-per-page-options="[25, 50, 100, 500]"
      :pagination="DEFAULT_PAGINATION"
      row-key="name"
    >
      <template #body-cell-actions="{ row }">
        <q-td>
          <span>
            <q-btn
              dense
              flat
              round
              color="primary"
              size="sm"
              icon="edit"
              class="q-mx-xs"
              @click="handleEditSnippet(row)"
            />
            <q-tooltip>{{ $t('configSnippets.editSnippet') }}</q-tooltip>
          </span>

            <span>
            <q-btn
              dense
              flat
              round
              size="sm"
              icon="preview"
              class="q-mx-xs"
              @click="handlePreviewSnippet(row)"
            />
            <q-tooltip>{{ $t('configSnippets.viewSnippet') }}</q-tooltip>
          </span>

          <span>
            <q-btn
              dense
              flat
              round
              color="negative"
              size="sm"
              icon="delete"
              class="q-mx-xs"
              @click="handleDeleteSnippet(row)"
            />
            <q-tooltip>{{ $t('configSnippets.deleteSnippet') }}</q-tooltip>
          </span>
        </q-td>
      </template>
    </q-table>

    <q-inner-loading
      :showing="loading"
      color="primary"
      :label="$t('configSnippets.processing')"
    />

    <config-snippet-form-dialog
      v-model="showSnippetFormDialog"
      :config-snippet="selectedConfigSnippet"
      @created="handleSnippetCreated"
      @updated="handleSnippetUpdated"
      @hide="handleFormDialogClose"
    />

    <config-snippet-preview-dialog
      v-model="showPreviewDialog"
      :config-snippet="selectedConfigSnippet"
      @hide="handlePreviewClose"
    />

    <confirmation-dialog
      v-model="showDeleteConfirmation"
      title="Delete Config Snippet"
      type="negative"
      @confirm="handleConfirmDeleteConfigSnippet"
      @close="closeDeleteSnippetDialog"
    >
      <template #content>
        <span>
          {{ $t('configSnippets.deleteSnippetConfirm') }}
        </span>
      </template>
    </confirmation-dialog>
  </q-page>
</template>
