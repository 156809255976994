import client from '../helpers/authenticated-axios'

export const preferenceService = {
  getPreferences(callback) {
    client().get('/user_preferences/').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  /**
   * Update a certain preference item/s.
   *
   * @param payload {Object}
   *
   * @returns {Promise<*>}
   */
  async updatePreference(payload) {
    return await client().post(
      '/user_preferences/',
      {
        payload
      }
    )
  }
};
