<script>
import SeverityLevelsChart from 'components/Charts/Validation/SeverityLevelsChart.vue'
import CommonMessagesChart from 'components/Charts/Validation/CommonMessagesChart'
import FlowSeveritiesChart from 'components/Charts/Validation/FlowSeveritiesChart'
import MostSeenEntitiesChart from 'components/Charts/Validation/MostSeenEntitiesChart'
import AlertEntitiesChart from 'components/Charts/Validation/AlertEntitiesChart'
import SelectInputFilter from "components/Charts/Filter/SelectInputFilter.vue";

export default {
  name: "ValidationStats",
  components: {
    SelectInputFilter,
    SeverityLevelsChart,
    CommonMessagesChart,
    FlowSeveritiesChart,
    MostSeenEntitiesChart,
    AlertEntitiesChart
  },
  data() {
    return {
      types: [
        {value: 0, label: this.$t('validation.severityLevels') + ' (' + this.$t('validation.pieChart') + ')'},
        {value: 1, label: this.$t('validation.flowSeverities') + ' (' + this.$t('validation.barChart') + ')'}
      ],
      selectedType: 1
    }
  }
}
</script>

<template>
  <div class="q-pa-md">
    <h1 class="q-mb-lg">{{ $t('validation.validationStats') }}</h1>

    <div class="row relative-position">
      <div class="chart-filter">
        <select-input-filter v-model="selectedType" type="single" :options="types" :label="$t('validation.severityChartType')" translatable mapped />
      </div>

      <div class="col-12" v-if="selectedType === 0">
        <q-card flat class="severity-levels-card full-height">
          <q-card-section>
            <severity-levels-chart />
          </q-card-section>
        </q-card>
      </div>

      <div class="col-12" v-if="selectedType === 1">
        <q-card flat class="flow-severities-card full-height">
          <q-card-section>
            <flow-severities-chart />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row q-mt-md">
      <div class="col">
        <q-card flat>
          <q-card-section>
            <alert-entities-chart />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row q-mt-md">
      <div class="col">
        <q-card flat>
          <q-card-section>
            <common-messages-chart />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div class="row q-mb-xl q-mt-md">
      <div class="col">
          <q-card flat>
            <q-card-section>
              <most-seen-entities-chart />
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
</template>

<style lang="scss">
  .flow-severities-card  {
    body.screen--xs, body.screen--sm &, body.screen--md {
      margin-top: 16px;
    }
  }
  .severity-levels-card {
    body.screen--lg, body.screen--xl {
      margin-right: 16px;
    }
  }
  .chart-header {
    font-size: 16px;
    font-weight: 900;
    opacity: 1;
  }
  .validation-graph {
    body.screen--xs, body.screen--sm {
      width: 350px;
    }
    body.screen--md, body.screen--lg {
      width: 500px;
    }
  }
  .chart-filter {
    body.screen--xs, body.screen--sm {
      position: relative;
    }
    body.screen--md {
      margin-top: 15px;
      margin-right: 15px;
    }
    body.screen--lg, body.screen--xl {
      margin-top: 20px;
      margin-right: 20px;
    }
  }
</style>
