/**
 * Transform an array of object into a flat array where the values are from the specified key
 * of the object
 *
 * @param items {array}
 * @param key {string}
 * @param excluded {string|array}
 */
export function valueMapTransformer(items, key, excluded) {
  if (!!excluded) excluded = Array.isArray(excluded) ? excluded : [excluded]

  return items.reduce((acc, cur) => {
    const value = cur[key]

    if (excluded && !excluded.includes(value)) acc.push(value)
    else acc.push(value)

    return acc
  }, [])
}
