<script>
  import { defineComponent } from 'vue'
  import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
  import { flowExecutionService } from 'src/services'
  import usePaginator from 'src/composables/usePaginator'

  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import DateDisplay from 'components/DateDisplay.vue'
  import StatusBadge from 'components/StatusBadge.vue'
  import FilterSection from './components/FilterSection.vue'
  import Paginator from 'components/Paginator.vue'

  export default defineComponent({
    name: 'FlowExecutionOverview',

    components: {
      StatusBadge,
      LoadingIndicator,
      DateDisplay,
      FilterSection,
      Paginator
    },

    setup () {
      const { pagination, readFromQuery, updateRouteQuery, clearQuery } = usePaginator()

      return {
        pagination,
        readFromQuery,
        updateRouteQuery,
        clearQuery
      }
    },

    data () {
      return {
        items: [],
        isLoading: true,
        filterUrl: [],
        filterObject: [],
        filterNoItems: false,
        flow: this.$router.currentRoute._value.params.flowid,
        columns: [
          {
            name: 'id',
            required: true,
            label: this.getTranslation("id"),
            align: 'left',
            field: row => row.id
          },
          {
            name: 'success',
            required: false,
            label: this.getTranslation("success"),
            align: 'left'
            //field: row => this.renderSuccessCol(row.success, row.active)
          },
          {
            name: 'processStep',
            required: true,
            label: !(this.$q.screen.xs || this.$q.screen.sm) ? this.getTranslation("processStep") : this.getTranslation("processStepShort"),
            align: 'left',
            field: row => row.currentProcessStepIdentifier
          },
          {
            name: 'name',
            required: true,
            label: this.getTranslation("name"),
            align: 'left',
            field: row => '#' + row.flowId + ': ' + row.flowName
          },
          {
            name: 'duration',
            required: false,
            label: this.getTranslation("duration"),
            align: 'left'
            // field content is defined via slot in template
          },
          {
            name: 'created',
            required: false,
            label: this.getTranslation("createdAt"),
            align: 'left'
            // field content is defined via slot in template
          },
          {
            name: 'time',
            required: false,
            label: this.getTranslation("time"),
            align: 'left'
            // field content is defined via slot in template
          },
          {
            name: 'link',
            required: false,
            label: this.getTranslation("link")
            // field content is defined via slot in template
          }
        ],
        autoRefreshLooping: null,
        runningFlow: null
      }
    },

    methods: {
      getTranslation(key) {
        return this.$t('flow.executions.thLabel.' + key);
      },

      pageLoad(softReload = false) {
        if(!softReload) {
          this.isLoading = true
          this.updateRouteQuery(this.pagination)
        }

        flowExecutionService.getFlowExecutions(this.pagination.page, this.pagination.itemsPerPage, this.flow, this.filterUrl, (data) => {
          this.pagination.totalItems = data['hydra:totalItems'] ?? 0
          this.pagination.pageCount = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)
          this.items = data['hydra:member'] ?? []
          this.items.forEach(member => member.active ? this.runningFlow = true : null)

          const flows = data['hydra:member'];
          if(flows?.length > 0 ) {
            let foundRunningFlow = false;
            for (const flow of flows) {
              if(flow.active === true) {
                foundRunningFlow = true;
                break;
              }
            }
            this.runningFlow = foundRunningFlow;
            if(!foundRunningFlow) clearLooping(this.autoRefreshLooping);

            this.isLoading = false;
            this.filterNoItems = (this.pagination.totalItems === 0) ? 1 : 0;
            if (this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageLoad(true));
          }
          else {
            // There is no data available on current page anymore. Reload, jump to last one with data.
            if(data['hydra:totalItems'] > 0 && this.pagination.page !== 1) {
              this.pagination.page = this.pagination.pageCount;
              this.pageLoad();
            } else {
              // There are no elements at all. Nothing to do here.
              // Need to empty the list Array when deleting the last available item though
              this.isLoading = false;
            }
          }
        })
      },

      goToDetail (evt, row, newTab) {
        const url = '/flowexecutions/detail/' + row.id
        if (newTab) window.open('#' + url, '_blank').focus();
        else this.$router.push(url);
      },

      buildUrlFromFilterObject(filterObject) {
          let filterUrl = '';

          let separator = '&flow.id[]='
          if (filterObject.flowId) {
              filterUrl += separator + filterObject.flowId.join(separator)
          }

          if (filterObject.status) {
              let { active, success, fail } = filterObject.status;
              if (active !== null) { active ? filterUrl += '&active=1' : filterUrl += '&active=0' }

              // prevent setting the success/fail filter, if only 'active' flows should be shown
              if (!active) {
                  if (success !== fail) { success || !fail ? filterUrl += '&success=1' : filterUrl += '&success=0' }
              }
          }

          if (filterObject.dates) {
              let { dates } = filterObject;
              if (dates.createdFrom) {
                filterUrl += '&created_at[after]=' + dates.createdFrom;
              }
              if (dates.createdTo) {
                filterUrl += '&created_at[before]=' + dates.createdTo;
              }
          }
          this.filterObject = filterObject;
          this.filterUrl = filterUrl;
          this.pageLoad()
      },

      resetPageAndReload() {
        this.pagination.page = 1

        this.pageLoad()
      }
    },

    mounted() {
      this.readFromQuery(this.pagination)
      this.pageLoad()
    },

    unmounted() {
      this.clearQuery();
    },

    beforeUnmount() {
      clearLooping(this.autoRefreshLooping);
    },
  })

</script>

<template>
  <q-page class="block">
    <div class="q-pa-md relative-position">
      <h1>{{ $t('flow.executions.headline') }}</h1>
      <p>{{ $t('flow.executions.desc') }}</p>

      <filter-section
        v-if="items.length > 0 || filterUrl.length > 0"
        @update="buildUrlFromFilterObject"
        @reset="pageLoad"
      />

      <loading-indicator v-if="isLoading" wrapper />

      <template v-if="!isLoading && items.length > 0">
        <q-card flat>
          <q-card-section>
            <div class="row q-pa-sm">
              <q-table
                v-if="!isLoading && items.length > 0"
                :rows="items" :row-key="items.id"
                :columns="this.columns"
                color="primary"
                :hide-bottom=true
                :hide-pagination=true
                :rows-per-page-options="[pagination.itemsPerPage]"
                flat
                class="app-flow-execution-table col-12 transparent"
              >
                <template v-slot:body="props">
                  <q-tr
                    :props="props"
                    class="cursor-pointer"
                    @click.exact="goToDetail(null, props.row)"
                    @click.middle.exact="goToDetail(null, props.row, true)"
                    @click.ctrl.exact="goToDetail(null, props.row, true)"
                  >
                    <q-td
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                    >
                      <template v-if="col.name === 'success'">
                        <status-badge :running="props.row.active" :status="props.row.success" />
                      </template>
                      <template v-else-if="col.name === 'duration'">
                        <date-display :start-time="props.row.created_at" :end-time="props.row.updated_at" />
                      </template>
                      <template v-else-if="col.name === 'created'">
                        <date-display :start-time="props.row.created_at" only-date />
                      </template>
                      <template v-else-if="col.name === 'time'">
                        <date-display :start-time="props.row.updated_at" only-date />
                      </template>
                      <template v-else-if="col.name === 'link'">
                        <q-btn
                            type="a"
                            flat dense
                            :ripple="!isLoading"
                            class="app-jump-to-execution"
                            icon="img:assets/icons/link.svg"
                            :title="$t('flow.executions.gotoDetail')"
                            :disable="isLoading"
                            @click="$event.stopPropagation(); goToDetail(null, props.row, true)"
                        />
                      </template>
                      <template v-else>
                        <template v-if="col.name === 'id'">#</template>
                        {{ col.value }}
                      </template>
                    </q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>
          </q-card-section>
        </q-card>

        <paginator
          v-model:page="pagination.page"
          v-model:itemsPerPage="pagination.itemsPerPage"
          :total-pages="pagination.pageCount"
          class="q-mt-lg"
          @update:page="pageLoad"
          @update:items-per-page="resetPageAndReload"
        />
      </template>

      <template v-if="!isLoading && this.filterNoItems">
        <p class="text-center text-light">{{ $t('flow.executions.filter.noItems') }}</p>
      </template>

      <template v-if="!isLoading && items.length <= 0 && !this.filterNoItems">
        <p class="text-center text-light">{{ $t('flow.executions.noExecutions') }}</p>
        <div class="column col-12">
          <div class="q-mx-auto">
            <q-btn flat dense
              class="app-action-btn"
              :label="$t('flow.executions.noExecutionsButton')"
              :title="$t('flow.executions.noExecutionsButton')"
              to="/flow"
            />
          </div>
        </div>
      </template>
    </div>
  </q-page>
</template>
