export function fileNameRules (val, castAsBool, app, allowSpace) {
  return validator(val, castAsBool, app, allowSpace ? new RegExp(/^[a-zA-Z0-9_.-]+(?:\s[a-zA-Z0-9_.-]+)*$/) : new RegExp('^[a-zA-Z0-9_.-]+$'))
}

export function pathRules (val, castAsBool, app, allowSpace) {
  return validator(val, castAsBool, app, allowSpace ? new RegExp(/^[a-zA-Z0-9_.\/-]+(?:\s[a-zA-Z0-9_.\/-]+)*$/) : new RegExp('/^[a-zA-Z0-9_.\/-]+$/\n'))
}

export function validator (val, castAsBool, app, regex) {
  if (val.length <= 0) return app.$t('user.form.rules.name.empty');
  else {
    const REGEX = regex;
    const CONDITION = !REGEX.test(val);
    if(castAsBool) return !!CONDITION;
    if(CONDITION) {
      return app.$t('browser.modal.forbiddenChar');
    }
  }
}
