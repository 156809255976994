export default {
  currentPage: function(state) {
    return state.currentPage;
  },
  itemsPerPage: function(state) {
    return state.itemsPerPage
  },
  items: function(state) {
    return state.items
  },
  totalPages(state) {
    return Math.ceil(state.totalItems / state.itemsPerPage)
  },
  totalItems(state) {
    return state.totalItems
  },
  isLoading(state) {
    return state.isLoading;
  }
}
