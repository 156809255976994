<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'LoadingIndicator',
    props: {
      wrapper: Boolean, /* reserve space for the component to be loaded */
      centered: Boolean /* Shop spinner in absolute center of display. Does not work with wrapper */
    }
  })
</script>

<template>
  <div v-if="wrapper" class="lds-ring-wrapper full-width relative-position">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  <div v-if="!wrapper" class="lds-ring" :class="{ 'centered': centered}" ><div></div><div></div><div></div><div></div></div>
</template>

<style lang="scss">
  .q-spinner {
    z-index: 9999;
  }
</style>
