/**
 * Parse a sting to object
 *
 * @param string
 *
 * @returns {boolean|any}
 */
export function parseJson(string) {
  try {
    return JSON.parse(string)
  } catch (err) {
    console.error(`Unable to parse string ${string}.`)

    return false;
  }
}
