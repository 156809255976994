<script>
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator'
  import Logo from "pages/Auth/components/Logo.vue";

  export default defineComponent({
    name: 'PasswordRecoverRequest',
    components: {
      Logo,
      LoadingIndicator
    },
    data () {
      return {
        mail: '',
        submitPending: false,
        requestSent: false
      }
    },
    computed: {
      ...mapGetters('authentication', [
        'pwRequestPending',
        'pwRequestSuccess'
      ]),
      isValidEmail () {
        const EMAILPATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return EMAILPATTERN.test(this.mail);
      }
    },
    methods: {
      handleSubmit() {
        this.submitPending = true;
        this.requestSent = true;
        this.$store.dispatch('authentication/requestReset', this.mail).then(data =>{
          setTimeout(() => this.submitPending = false, 1000); // 1s timeout required for animation to play
        });
      }
    }
  })
</script>

<template>
  <q-page class="app-page-password-recover psw-req justify-center">
    <loading-indicator v-if="submitPending" />
    <div>
      <form @submit.prevent="handleSubmit" :class="{'disabled': submitPending}">
        <div class="q-pa-md">
          <h1>{{ $t('publicPages.passwordRecover.headline') }}</h1>
          <p>{{ $t('publicPages.passwordRecover.desc') }}</p>
        </div>
        <div class="q-px-md q-pb-md">
          <div class="q-gutter">
            <q-input required
                     type="email"
                     v-model="mail"
                     :label="$t('publicPages.passwordRecover.inputLabel')"
                     :rules="[
                         val => !!val || $t('user.form.rules.mail.empty'),
                         val => !!isValidEmail || $t('user.form.rules.mail.wrongRegex')
                         ]"
                     @keydown.space="(event) => event.preventDefault()"
                     style="max-width: 300px;"
            >
              <template v-slot:prepend>
                <q-icon name="mail" />
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-pa-md">
          <div class="flex">
            <q-btn flat class="app-action-btn"
              :class="{ 'disabled': submitPending}"
              :disabled="submitPending"
              :title="submitPending ? $t('publicPages.passwordRecover.submitSending') : $t('publicPages.passwordRecover.submit')"
              :label="$t('publicPages.passwordRecover.submit')"
              type="submit"
            />
          </div>
          <div class="q-mt-md">
            <p v-if="submitPending"
               class="app-submit-send-info"
               :class="{ 'show': submitPending}"
            >
              {{ $t('publicPages.passwordRecover.submitSending') }}
            </p>
            <p v-if="requestSent && !pwRequestPending && pwRequestSuccess"
               class="app-submit-sent"
               :class="{ 'show': requestSent && !submitPending && pwRequestSuccess}"
            >
              {{ $t('publicPages.passwordReset.requestSuccess') }}
            </p>
            <p
                v-if="requestSent && !pwRequestPending && !pwRequestSuccess"
                class="app-submit-sent app-fail"
                :class="{ 'show': requestSent && !submitPending && !pwRequestSuccess}"
            >
              {{ $t('publicPages.passwordReset.requestFail') }}
            </p>
          </div>
        </div>
      </form>
    </div>
    <logo />
  </q-page>
</template>

<style lang="scss">
  .app-page-password-recover {
    &.disabled {opacity: .3}
    .lds-ring {
      transform: translateX(-50%);
      top: 10rem;
    }
    .q-field__control:before, .q-field__control:after {
      left: 0;
    }
  }
  .app-submit-send-info,
  .app-submit-sent {
    opacity: 0;
    transition: .5s;
    transform: translateY(-10px);

    &.show {
      opacity: 1;
      transform: unset;
    }
  }
  .app-submit-sent {
    color: $positive;
    &.app-fail {
      color: $negative;
    }
  }
  .psw-req {
    .q-field__control:before,
    .q-field__control:after {
      left: 0;
    }
    .app-submit-send-info {
      display: inline-block;
      opacity: 0;
      transition: .5s;
      transform: translateY(-10px);
      &.show {
        opacity: 1;
        transform: unset;
      }
    }
  }
</style>
