<script>
import { defineComponent, ref } from 'vue'
import JsonEditor from 'components/JsonEditor.vue'
import LoadingIndicator from 'components/LoadingIndicator'
import StatisticCard from 'components/StatisticCard'
import { useQuasar } from 'quasar'
import { flowService } from 'src/services'
import DeleteJobDispatcherMappingModal from "./DeleteJobDispatcherMappingModal";

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'JobDispatcherMappingModal',

  components: {
    SqDialog,
    LoadingIndicator,
    StatisticCard,
    JsonEditor
  },
  emits: ['updated'],

  props: ["jobDispatcherMapping", "flow"],

  setup (props) {
    const $q = useQuasar();
    return {
      qInstance: $q,
      originalLocalJobDispatchCondition: ref(JSON.stringify(props.jobDispatcherMapping.dispatchCondition, null, 2)),
      originalLocalJobModuleConfig: ref(JSON.stringify(props.jobDispatcherMapping.configuration, null, 2)),
    }
  },
  data () {
    return {
      currentTab: ref('config'),
      isLoading: false,
      dispatchConditionValid: true,
      localJobDispatchCondition: this.originalLocalJobDispatchCondition,
      localJobModuleConfig: this.originalLocalJobModuleConfig

    }
  },

  computed: {
    localDispatchConditionFormatted: {
      get: function() {
        return this.localJobDispatchCondition;
      },
      set: function(val) {
        this.localJobDispatchCondition = val;
      }
    },
    localModuleConfigFormatted: {
      get: function() {
        return this.localJobModuleConfig;
      },
      set: function(val) {
        this.localJobModuleConfig = val;
      }
    },
    localDispatchConditionObject() {
      return JSON.parse(this.localJobDispatchCondition)
    },
    localConfigurationObject() {
      return JSON.parse(this.localJobModuleConfig);
    },
    isInvalid() {
      return (this.isLoading || !this.dispatchConditionValid || this.localJobModuleConfig === '' || this.localJobDispatchCondition === '');
    }
  },

  methods: {
    saveEntity() {
      if (this.dispatchConditionValid === true) {
        this.isLoading = true;
        try {
          const localConfigurationObject = this.localConfigurationObject;
          const localDispatchConditionObject = this.localDispatchConditionObject;
          flowService.updateJobDispatcherMapping(this.$props.jobDispatcherMapping['@id'], localConfigurationObject, localDispatchConditionObject, (data) => {
            if(data?.['@type'] === "hydra:Error") {
              this.$store.dispatch('alert/error', 'flow.overview.detail.modal.flowEditFailed', { root: true });
            } else {
              this.$store.dispatch('alert/success', 'flow.overview.detail.modal.jdmEditSuccess');
              this.isLoading = false;
            }

            this.$emit('updated', data)
          })
        } catch(e) {
          this.$store.dispatch('alert/error', e);
        }
      } else {
        this.$store.dispatch('alert/error', 'flow.overview.detail.modal.invalidJson');
      }
    },

    invalidJson() {
      this.dispatchConditionValid = false;
    },

    handleValidJson(value) {
      this.dispatchConditionValid = value
    },

    openDeleteJdmModal() {
      this.qInstance.dialog({
        component: DeleteJobDispatcherMappingModal,
        // props forwarded to your custom component
        componentProps: {
          jobDispatcherMapping: this.$props.jobDispatcherMapping,
          flow: this.$props.flow
        }
      }).onOk(() => {
        this.$emit('updated', {deleted: true, jdmId: this.$props.jobDispatcherMapping.id})
      })
    },
    cancel() {
      this.localJobDispatchCondition = this.originalLocalJobDispatchCondition;
      this.localJobModuleConfig = this.originalLocalJobModuleConfig;
    },
    save() {
      this.originalLocalJobDispatchCondition = this.localJobDispatchCondition;
      this.originalLocalJobModuleConfig = this.localJobModuleConfig;
      this.saveEntity();
    }
  }
})
</script>

<template>
  <sq-dialog
    type="edit"
    size="md"
    :loading="isLoading"
    @hide="cancel()"
  >
    <template #title>
      {{ $t('flow.overview.detail.modal.editJdm') }}
    </template>

    <template #content>
    <q-card-section class="context-content">
      <q-tabs v-model="currentTab" align="left" dense active-color="primary" indicator-color="primary" narrow-indicator>
        <q-tab name="config" :label="$t('flow.overview.detail.modal.moduleConfig')" :ripple="false" />
        <q-tab name="condition" :label="$t('flow.overview.detail.modal.dispatchCondition')" :ripple="false" />
      </q-tabs>
      <q-tab-panels v-model="currentTab" animated>
        <q-tab-panel name="config" class="q-pa-md">
          <div class="text-h6">{{ $t('flow.overview.detail.modal.moduleConfig') }}</div>
          <json-editor
              v-model="localJobModuleConfig"
              @is-valid-json="handleValidJson"
              data-cy="jsonEditorFlowConfig"
              :disabled="isLoading"
              allow-empty
          />
        </q-tab-panel>
        <q-tab-panel name="condition" class="q-pa-md">
          <div class="text-h6">{{ $t('flow.overview.detail.modal.dispatchCondition') }}</div>
          <json-editor
              v-model="localJobDispatchCondition"
              @is-valid-json="handleValidJson"
              data-cy="jsonEditorDispatchCondition"
              :disabled="isLoading"
              allow-empty
          />
        </q-tab-panel>
      </q-tab-panels>
    </q-card-section>
    </template>

    <template #actions>
      <q-btn
        v-close-popup
        flat
        :label="$t('general.cancel')"
        color="dark"
        class="bg-secondary text-bold"
      />

      <q-btn
        flat
        color="white"
        class="bg-negative text-bold"
        :label="$t('flow.overview.detail.modal.deleteItem')"
        :disable="isInvalid"
        data-cy="buttonDeleteJdmModal"
        @click.capture.stop='openDeleteJdmModal'
      />

      <q-btn
        flat
        :label="$t('general.save')"
        color="white"
        class="bg-primary text-bold"
        :disable="isInvalid"
        data-cy="buttonSave"
        @click="save()"
      />
    </template>
  </sq-dialog>
</template>

<style scoped lang="scss">
  .context-container {
    max-height: 80%;
  }
</style>
