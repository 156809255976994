import client from 'src/helpers/authenticated-axios'

export default {
  getDiskSpace(query = null) {
    let url = '/filesystems/disk-space';
    return client().get(url, {
      params: query
    })
  },

  listFileSystem(query = null) {
    let url = '/filesystems';

    return client().get(url, {
      params: query
    })
  }
}
