<script>
  import { isEqual } from 'lodash'
  import { required, min, max } from 'pages/JsonToForm/Helpers/validations'
  import { notNullOrUndefined } from 'src/utils'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  /**
   * You can pass Quasar's select props as it will be part of the fall through attributes via the v-bind=$attrs
   */
  export default {
    name: 'SqSelect',

    components: { SqMarkdownDisplay },

    props: {
      modelValue: {
        required: false
      },

      defaultValue: {
        required: false
      },

      options: {
        type: Array,
        required: false,
        default: () => []
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    emits: ['update:model-value'],

    data() {
      return {
        value: this.modelValue
      }
    },

    computed: {
      rules () {
        return this.validations.map(item => {
          if (typeof item === 'string') {
            return this[item](this.$t('common._validationMessages.invalidData'))
          }

          return this[item.name](item.message || 'Invalid data', item.conditional)
        });
      },

      canClear() {
        return this.defaultValue && !isEqual(this.defaultValue, this.value)
      }
    },

    methods: {
      notNullOrUndefined,
      required,
      min,
      max,

      updateModelValue() {
        this.$emit('update:model-value', this.value)
      },

      setDefaultValue() {
        this.value = this.defaultValue

        this.updateModelValue()
      }
    },

    watch: {
      modelValue(value) {
        if (value !== this.value) {
          this.value = value
        }
      }
    }
  }
</script>

<template>
  <div>
    <q-select
      v-model="value"
      v-bind="$attrs"
      dense
      options-dense
      filled
      emit-value
      map-options
      :clearable="canClear"
      :rules="rules"
      :options="options"
      class="q-py-sm"
      @update:model-value="updateModelValue"
    >
      <template #append>
        <q-btn
          v-if="notNullOrUndefined(defaultValue)"
          icon="refresh"
          dense
          flat
          @click="setDefaultValue"
        />
      </template>
    </q-select>

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>
  </div>
</template>
