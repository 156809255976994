const baseNames = {
  INFO: 'info',
  ALERT: 'alert',
  WARNING: 'warning',
  DANGER: 'danger'
}

const severities = {
  ...baseNames,
  colors: {
    [baseNames.INFO]: 'info',
    [baseNames.ALERT]: 'red-4',
    [baseNames.WARNING]: 'warning'/*,
    [baseNames.DANGER]: 'negative'*/ // Removed as per request on PSC-89. Simply uncomment to restore
  },
  // Workaround for apex charts - would need node-sass-json-importer to solve variables
  apexChartStatusColors: {
    [baseNames.INFO]: '#31CCEC',
    [baseNames.ALERT]: '#e57373',
    [baseNames.WARNING]: '#cb9d33'/*,
    [baseNames.DANGER]: '#C10015'*/
  }
}

export default severities
