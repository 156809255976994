export default {
  currentPage: function(state) {
    return state.currentPage;
  },
  itemsPerPage: function(state) {
    return state.itemsPerPage;
  },
  items: function(state) {
    return state.items;
  },
  totalPages(state) {
    return Math.ceil(state.totalItems / state.itemsPerPage);
  },
  isLoading(state) {
    return state.isLoading;
  },
  isRefreshing(state) {
    return state.isRefreshing;
  },
  runningFlow(state) {
    return state.runningFlow;
  },
  getFlowCardDisabled(state, flowId) {
    state?.items.forEach((value, key) => {
      if (value.id === flowId) {
        if ('disableListItem' in value) {
          return value.disableListItem;
        } else {
          return false;
        }
      }
    });
    return false;
  },
  configuration: function(state) {
    return state.configuration;
  },
  exportFlow(state) {
    return state.exportFlow;
  }
}
