import client from '../helpers/authenticated-axios'

export const fileSystemService = {

  fileSystemLoad(callback) {
    client().get('/filesystems').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  addFilesystem(name, config, callback) {
    try {
      client().post('/filesystems', {
        "identifier": name, "config": JSON.parse(config)
      }).then((response) => {
        callback(response.data)
      }).catch((error) => {
        callback(error.response);
      });
    } catch(e) {
      callback(e);
    }
  },

  editFilesystem(name, config, callback) {
    try {
      client().put('/filesystems' + '/' + name, {
        "config": JSON.parse(config)
      }).then((response) => {
        callback(response.data)
      }).catch((error) => {
        callback(error.response);
      });
    } catch(e) {
      callback(e);
    }
  },

  deleteFilesystem(filesystem, callback) {
    client().delete(filesystem).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  }

};
