/**
 * Flag that entities are being loaded
 *
 * @param state
 *
 * @constructor
 */
export function START_ENTITY_LIST_LOADING(state) {
  state.entityListLoading = true;
}

/**
 * Set loaded entities from API to our vuex
 *
 * @param state
 * @param data
 *
 * @constructor
 */
export function SET_ENTITY_LIST(state, data) {
  state.entityList = data;
  state.entityListLoading = false;
}

/**
 * Update current entity name and commit the change to vuex
 *
 * @param state
 * @param entity
 * @constructor
 */
export function UPDATE_CURRENT_ENTITY_NAME(state, entity) {
  state.currentEntityName = entity
}
