export default function () {
  const initialState = {
    isLoading: true,
    totalPages: 0,
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 15,
    items: []
  }

  return initialState
}
