<script>
  import { defineComponent, onBeforeUnmount, ref } from 'vue'
  import { checkRunningFlowLoop, clearLooping } from 'src/helpers/auto-refresh'
  import {flowService} from "src/services";
  import usePaginator from 'src/composables/usePaginator'

  import FlowActionBar from './components/FlowActionBar.vue'
  import FlowItem from './components/FlowItem.vue'
  import LoadingIndicator from 'components/LoadingIndicator.vue'
  import Paginator from 'components/Paginator.vue'

  export default defineComponent({
    name: 'Overview',

    components: {
      FlowActionBar,
      FlowItem,
      LoadingIndicator,
      Paginator
    },

    setup () {
      const { pagination, readFromQuery, updateRouteQuery, clearQuery } = usePaginator()

      const autoRefreshLooping = ref(null)
      const runningFlow = ref(null)
      const items = ref([])
      const isLoading = ref(true)
      const getFlowCardDisabled = ref(null)

      return {
        items,
        isLoading,
        runningFlow,
        autoRefreshLooping,
        getFlowCardDisabled,

        pagination,
        readFromQuery,
        updateRouteQuery,
        clearQuery
      }
    },

    data() {
      onBeforeUnmount(() => {
        clearLooping(this.autoRefreshLooping);
      });
      const cancelToken = this.$axios.CancelToken
      return {
        requestSource: cancelToken.source()
      }
    },

    methods: {
      async pageLoad(softReload = false) {
        if (!softReload) {
          this.isLoading = true
          await this.updateRouteQuery(this.pagination)
        }

        const { data } = await flowService.getFlows(this.pagination.page, this.pagination.itemsPerPage)

        if (data) {
          this.items = data['hydra:member'] ?? []
          this.pagination.totalItems = data['hydra:totalItems'] ?? 0
          this.pagination.pageCount = Math.ceil(this.pagination.totalItems / this.pagination.itemsPerPage)

          // if visited a page that has no item, go to the last page that has item on it
          if (!this.items.length && data['hydra:totalItems'] > 0 && this.pagination.page !== 1) {
            this.pagination.page = this.pagination.pageCount
            return await this.pageLoad()
          }

          this.isLoading = false
        }
      },

      async pageUpdate(payload = null, softReload = false) {
        if (payload?.type === "create") {
          this.pagination.page = this.pagination.pageCount
          if (this.pagination.page === 0) this.pagination.page = 1; // Fix when creating first item
          if (!softReload) {
            if (this.pagination.totalItems > 0 && (this.pagination.totalItems % this.pagination.itemsPerPage === 0)) {
              this.pagination.page = this.pagination.pageCount + 1
              await this.updateRouteQuery(this.pagination)
            }
          }
        }

        try {
          const { data } = await flowService.getFlows(this.pagination.page, this.pagination.itemsPerPage, this.requestSource.token)
          if (data) {
            let foundRunningFlow = false;
            const flows = data['hydra:member'];
            if(flows?.length > 0 ) {
              for (const flow of flows) {
                if(flow.isRunning === true) {
                  foundRunningFlow = true;
                  break;
                }
              }
              this.runningFlow = foundRunningFlow;
              if(!foundRunningFlow) clearLooping(this.autoRefreshLooping);
            }

            this.pagination.totalItems = data['hydra:totalItems'];
            await this.pageLoad(softReload);
            if (this.runningFlow) this.autoRefreshLooping = checkRunningFlowLoop(this.runningFlow, () => this.pageUpdate(null, true));
          }
        } catch (error) {}
      },

      handlePageChange () {
        this.pageLoad();
      },

      resetPageAndReload() {
        this.pagination.page = 1

        this.pageLoad()
      }
    },

    mounted() {
      this.readFromQuery(this.pagination)
      this.pageUpdate()
    },

    unmounted() {
      this.clearQuery();
    },

    beforeRouteLeave() {
      // once we change our route
      if (this.requestSource) {
        this.requestSource.cancel({ message: 'Cancel Flow fetch request!' })
      }

      clearTimeout(this.autoRefreshLooping)
    }
  })
</script>

<template>
  <q-page class="block">
    <div>
      <div class="q-pa-md">
        <h1>{{ $t('flow.overview.headline') }}</h1>
        <p>{{ $t('flow.overview.desc') }}</p>

        <div class="app-flowcard-container q-py-md row items-start q-gutter row relative-position">
          <loading-indicator v-if="isLoading" centered />
          <template v-if="!isLoading || items.length !== 0">
            <flow-action-bar
              v-if="!isLoading || items.length !== 0"
              :items="items"
              :is-loading="isLoading"
              @refresh="pageUpdate"
            />

            <flow-item
              v-for="flowItem in items"
              :flow="flowItem"
              :key="flowItem.id"
              :is-loading="isLoading"
              :rows-per-page-options="[pagination.itemsPerPage]"
              :class="{'disabled': isLoading}"
              @refresh="pageUpdate(null, true)"
            />
          </template>
        </div>

        <paginator
          v-model:page="pagination.page"
          v-model:itemsPerPage="pagination.itemsPerPage"
          :total-pages="pagination.pageCount"
          @update:page="handlePageChange"
          @update:items-per-page="resetPageAndReload"
        />
      </div>
    </div>
  </q-page>
</template>
