export default {
  addNewTabLabelOption(state, value) {
    state.tabLabelOptions.push(value)
    state.labelUseCounter[value] = 1
  },

  incrementUseCounter(state, value) {
    state.labelUseCounter[value]++
  },

  decrementUseCounter(state, value) {
    state.labelUseCounter[value]--
  },

  removeLabelOptionAndCounter(state, value) {
    delete state.labelUseCounter[value]

    const index = state.tabLabelOptions.findIndex(item => item === value)

    if (index > -1) {
      state.tabLabelOptions.splice(index, 1)
    }
  },

  reset(state) {
    state.labelUseCounter = {}
    state.tabLabelOptions = []
  }
}
