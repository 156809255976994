export function fileSystemLoad(state, data) {
  let list_to_tree = function(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i; // init
      list[i].children = []; // init
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      roots.push({
        node
      });
    }
    return roots;
  };
  if(data['hydra:member']) {
    state.filesystems = list_to_tree(data['hydra:member']);
  } else {
    state.filesystems = [];
  }
  state.isLoading = false;
  state.isRefreshing = false;
  state.initialized = true;
}

export function blockFileSystem(state) {
  state.isRefreshing = true;
}

export function freeFileSystem(state) {
  state.isRefreshing = false;
}
