<script>
import { ref, defineComponent } from 'vue'
import { mapState, mapActions } from 'vuex'

export default defineComponent({
  name: 'EntitySelection',

  props: {
    isMenu: {
      type: Boolean,
      default: false
    },

    redirect: {
      type: Boolean,
      default: false
    },

    icon: {
      type: String,
      default: ''
    }
  },

  emits: ['entity-change'],

  setup () {
    return {
      tooltipReloadEntities: ref(false)
    }
  },

  computed: {
    ...mapState('entities', [
      'currentEntityName',
      'entityListLoading',
      'entityList'
    ]),

    isInEntityPage() {
      return this.checkIfToOpen() && !!this.$router.currentRoute._value.params.entitylist
    },

    hasEntities() {
      return this.entityList?.length
    }
  },

  mounted() {
    if (this.$store.state.authentication.user != null) this.updateEntityList();
  },

  methods: {
    ...mapActions('entities', ['updateCurrentEntityName', 'loadEntityList']),

    checkIfToOpen() {
      return this.$route.path.indexOf(`/documents/`) > -1
    },

    loadEntityPage(id) {
      // prevent redirect on same page
      if (this.$route.params?.entitylist === id) return

      this.$router.push('/documents/' + id);
      this.updateCurrentEntityName(id)

      // is this component called from menu? If so then fire a global event if not fire a regular parent-child event communicator
      this.isMenu ? this.$eventBus.fire('ENTITY_CHANGE', id) : this.$emit('entity-change', id)
    },

    updateEntityList() {
      this.tooltipReloadEntities = false;
      this.loadEntityList()
    }
  }
})
</script>

<template>
  <div
    class="relative-position entity-list-container q-ml-sm q-pl-sm q-ml-md-none q-pl-md-none"
  >
    <div class="flex inline justify-between full-width q-mb-sm">
      <h2>{{ $t('entities.entityListHeadline') }}</h2>
      <div class="flex justify-end q-mr-lg">
          <span>
            <q-icon
              size="xs"
              name="img:assets/icons/refresh.svg"
              :title="$t('entities.menu.reload')"
              class="app-entity-list-reload"
              :class="{ 'q-spinner-mat': entityListLoading }"
              @click="updateEntityList"
            >
              <q-tooltip
                anchor="top middle" self="bottom middle"
                v-if='$q.platform.is.mobile && !entityListLoading'
                class="app-tooltip-mobile"
              >
                {{ $t('entities.menu.reload') }}
              </q-tooltip>
            </q-icon>
          </span>
      </div>
    </div>

    <q-inner-loading :showing="entityListLoading">
      <q-spinner
        color="primary"
        size="3em"
        :thickness="3"
      />
    </q-inner-loading>

    <q-list
      v-if="hasEntities"
      flat
      class="rounded-borders"
    >
      <div
        v-if="hasEntities"
        v-for="entity in entityList"
        class="app-entity-link q-mr-lg"
        :class="{ 'active': currentEntityName === entity.collectionName && isInEntityPage }"
        @click="loadEntityPage(entity.collectionName)"
        :data-cy="'entityLink'+ entity.collectionName"
      >
        <q-card-section>
          <div>
            {{ entity.collectionName }}
            <!--<span class="q-mr-xs">{{ entity.collectionName }}</span>
            &lt;!&ndash; TODO: @Backend: Add total entity value to collection API &ndash;&gt;
            <q-badge class="app-entity-badge">x</q-badge>-->
          </div>
        </q-card-section>
      </div>
      <p v-if="entityList?.length === 0" class="q-ma-none text-gray">{{ $t('entities.menu.noEntityLists') }}</p>
    </q-list>

    <span
      v-if="!hasEntities && !entityListLoading"
      class="text-grey-8"
    >
      {{ $t('entities.menu.noEntityLists') }}
    </span>
  </div>
</template>

<style lang="scss">
  .entity-list-container {
    min-height: 100px;
  }

  .q-card__section {
    padding: .25rem;
  }

  .app-entity-link {
    cursor: pointer;
    &.active {
      color: $primary;
    }
    &:hover {
      color: $secondary;
    }
  }

  .app-entity-badge {
    padding: 0 .25rem;
    background-color: $light;
    color: $dark;
  }

  body.body--dark {
    .app-entity-link:hover {
      color: $dark;
      background-color: $secondary;
    }
    .app-entity-badge {
      background-color: $dark;
      color: $light;
    }
  }

  .q-item__label {
    align-items: center;
    display: flex;
  }
  .app-entity-list-reload {
    cursor: pointer;
    &:hover {
      color: $secondary;
    }
  }
</style>
