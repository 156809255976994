<script>
import { defineComponent } from 'vue'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'EntityRefreshModal',
  components: {
    SqDialog
  },
  props: {
    entity: String
  },
  emits: ['success'],
  methods: {
    confirmClear() {
      this.$emit('success');
    }
  }
})
</script>

<template>
  <sq-dialog
      type="delete"
      @save="confirmClear"
      :save-button-label="$t('entities.modal.deleteEntityClearButton')"
  >
    <template #title>
      {{ $t('entities.modal.deleteEntity') }}
    </template>

    <template #content>
      {{ $t('entities.modal.deleteEntityConfirm', { entityList: this.$props.entity }) }}
    </template>
  </sq-dialog>
</template>
