export default {
  isLoading(state) {
    return state.isLoading;
  },
  isRefreshing(state) {
    return state.isRefreshing;
  },
  heartbeat(state) {
    return state.heartbeat;
  }
}
