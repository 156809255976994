<script>
import { defineComponent } from 'vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import StatisticCard from 'components/StatisticCard'
import MessageQueue from './components/MessageQueue'
import Heartbeat from './components/Heartbeat'
import Supervisor from './components/Supervisor'
import DiskSpaceChart from 'components/Charts/DiskSpace/DiskSpaceChart.vue'
import RedisMemoryUsage from 'components/Charts/RedisMemoryUsage/RedisMemoryUsage.vue'

export default defineComponent({
  name: 'Dashboard',

  components: {
    RedisMemoryUsage,
    LoadingIndicator,
    StatisticCard,
    MessageQueue,
    Heartbeat,
    Supervisor,
    DiskSpaceChart
  },

  data () {
    return {}
  },

  computed: {
    mongoStatistics () {
      return this.$store.getters['mongoStatistics/statisticData']
    },

    mongoStatisticsLoading () {
      return this.$store.getters['mongoStatistics/isLoading']
    },

    anyStatisticStillLoading () {
      /* return true only once all stats are loaded */
      return this.mongoStatisticsLoading
    }
  },

  methods: {
    getIconForStat (shortName) {
      let map = {
        'Product': 'inventory_2',
        'Category': 'category',
        'Customer': 'person',
        'Order': 'toc'
      }

      if (shortName in map) {
        return map[shortName]
      }

      return shortName
    },

    getTranslatedHeadline (identifier) {
      return this.$t('dashboard.card.' + identifier.toLowerCase())
    },

    goToEntity(item) {
      if(typeof item.value !== "undefined" && item?.value > 0) {
        this.$router.push('/documents/' + item.identifier);
      }
    }
  },

  mounted () {
    this.$store.dispatch('mongoStatistics/loadStatistics')
    //this.$store.dispatch('timeSeries/loadCollectionNames', 1)
  }
})
</script>

<template>
  <q-page>
    <div class="full-width">
      <div class="q-pa-md">
        <div class="row">
          <div class="col-12 col-md-6">
            <h1>{{ $t('dashboard.headline') }}</h1>
          </div>
          <div class="col-12 col-md-6">
            <heartbeat class="app-heartbeat-card q-pr-none" col="col-12 col-sm" />
          </div>
        </div>

        <div class="app-dashcard-container q-pa-md row items-start">
          <!-- Pseudo loading cards.-->
          <statistic-card v-for="n in 4" v-if="anyStatisticStillLoading" headline="" value="" col="col-6 col-sm-3 col-lg col-xl" />
          <!-- Real stat cards-->
          <statistic-card v-else
                          :headline="getTranslatedHeadline(item.identifier)"
                          :value="item.value.toString()"
                          :icon="getIconForStat(item.identifier)"
                          col="col-6 col-sm-3 col-lg col-xl"
                          class="app-statistic-card"
                          v-for="item in mongoStatistics"
                          v-bind:data-cy="'statisticCard' + item.identifier"
                          @goto="goToEntity(item)"
          />
        </div>

        <div class="app-heartbeat-container app-supervisor-container app-message-queue-container q-gutter-md row q-mt-xs">
          <message-queue class="app-message-queue-card" col="col-12 col-sm" />
          <supervisor class="app-supervisor-card" col="col-12 q-mt-none" />
        </div>

        <div class="app-disk-space-container app-redis-memory-usage-container q-pa-md row items-start">
          <disk-space-chart class="app-disk-space-card" col="col-xs-12 col-md-6" />
          <redis-memory-usage class="app-redis-memory-usage-card" col="col-xs-12 col-md-6" />
        </div>

      </div>
    </div>
  </q-page>
</template>

<style lang="scss">
.app-dashcard-container {
  margin-right: -1rem;
}
.app-time-series-visualization {
  .q-card {
    cursor: pointer;
  }
}

.app-heartbeat-card {
  @media (min-width: $breakpoint-sm) {
    margin-left: .5rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .app-heartbeat-container.app-supervisor-container.app-message-queue-container.app-disk-space-container.app-redis-memory-usage-container {
    margin-right: 1rem;
  }
  .app-message-queue-card {
    padding-top: 0 !important;
  }
}
.app-supervisor {
  margin-right: 1rem !important;
}
@media (min-width: $breakpoint-sm) {
  .app-disk-space {
    margin-right: 1rem !important;
  }
}
.app-message-queue-card, .app-supervisor-card, .app-disk-space-card, .app-redis-memory-usage-card, .app-redis-memory-usage-card {
  min-height: 178.58px;
}
</style>
