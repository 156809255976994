export const DEFAULT_PAGINATION = {
  sortBy: 'desc',
  descending: false,
  page: 1,
  rowsPerPage: 0
};

/**
 * Translates the labels of the given columns using the provided translation function.
 *
 * @param {function} t - The translation function to be used for translating the labels.
 * @param {Array} columns - The array of columns containing the labels to be translated.
 * @returns {Array} - The translated columns array with updated labels.
 */
export function translateColumns(t, columns) {
  return columns.map((col) => {
    return {
      ...col,
      label: col.translationMeta?.path ? t(col.translationMeta.path, col.translationMeta.plural) : col.label
    }
  })
}
