export default function () {
  const initialState = {
    isLoading: true,
    isRefreshing: false,
    heartbeat: [{
      heartbeat_grace: 0
    }],
  }

  return initialState
}
