import client from '../helpers/authenticated-axios'

export const alertService = {
  /**
   *
   * @param report {Object}
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getErrorDetails(report) {
    return await client().get({report});
  },
}
