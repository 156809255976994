export const SEPARATOR = '<++>'

// field types
export const FIELD_TYPE_INPUT = 'input'
export const FIELD_TYPE_INPUT_NUMBER = 'input_number'
export const FIELD_TYPE_TEXT_AREA = 'text_area'
export const FIELD_TYPE_CHECKBOX = 'checkbox'
export const FIELD_TYPE_SWITCH = 'switch'
export const FIELD_TYPE_SELECT = 'select'
export const FIELD_TYPE_DYNAMIC_INPUT = 'dynamic_input'
export const FIELD_TYPE_STRING_ARRAY_VALUE_MAPPING = 'string_to_array_value_mapping'
export const FIELD_TYPE_WRAPPER = 'wrapper'
export const FIELD_TYPE_DATE_PICKER = 'date_picker'
export const FIELD_TYPE_PROPERTY_MAPPING = 'property_mapping'
export const FIELD_TYPE_DYNAMIC_OBJECT_MAPPING = 'dynamic_object_mapping'
export const FIELD_TYPE_OBJECT_ARRAY = 'object_array'

// UI field names
export const FIELD_KEY_NAME = 'keyName'
export const FIELD_LABEL = 'label'
export const FIELD_DATA_TYPE = 'dataType'
export const FIELD_FIELD_TYPE = 'fieldType'
export const FIELD_FIELD_TAB_LABELS = 'tabLabels'
export const FIELD_DEFAULT = 'default'
export const FIELD_DEFAULT_OPTIONS = 'defaultOptions'
export const FIELD_DESCRIPTION = 'description'
export const FIELD_SEARCH_KEYS = 'searchKeys'
export const FIELD_VALIDATIONS = 'validations'
export const FIELD_DEFAULT_PROPERTIES = 'defaultProperties'
export const FIELD_OBJECT_PROPERTY = 'objectProperties'
export const FIELD_IS_SELECT_MULTIPLE = 'isSelectMultiple'
export const FIELD_IS_SELECT_CAN_ADD = 'isSelectCanAdd'
export const FIELD_SHOULD_MASK = 'shouldMask'
export const FIELD_LOCALIZATION = 'localization'

// validators
export const VALIDATION_REQUIRED = 'required'
export const VALIDATION_PATTERN = 'pattern'
export const VALIDATION_MIN = 'min'
export const VALIDATION_MAX = 'max'
export const VALIDATION_MIN_LEN = 'minLen'
export const VALIDATION_MAX_LEN = 'maxLen'

/**
 * Data types
 * @type {string}
 */
export const DATA_TYPE_STR = 'string'
export const DATA_TYPE_INT = 'integer'
export const DATA_TYPE_FLT = 'float'
export const DATA_TYPE_BOOL = 'boolean'
export const DATA_TYPE_ARRAY = 'array'
export const DATA_TYPE_OBJECT = 'object'

/**
 * Data type list
 */
export const DATA_TYPES = Object.freeze([
  { label: 'String', value: DATA_TYPE_STR, localeMap: 'common._dataType.string' },
  { label: 'Integer', value: DATA_TYPE_INT, localeMap: 'common._dataType.integer' },
  { label: 'Float', value: DATA_TYPE_FLT, localeMap: 'common._dataType.float' },
  { label: 'Boolean', value: DATA_TYPE_BOOL, localeMap: 'common._dataType.boolean' },
  { label: 'Array', value: DATA_TYPE_ARRAY, localeMap: 'common._dataType.array' },
  { label: 'Object', value: DATA_TYPE_OBJECT, localeMap: 'common._dataType.object' }
])

/**
 * Field Type List
 *
 * label - display label
 * value - This should be the type distinction. The value that should be emitted or used when doing comparison
 * default - the field's default value. If default is supported
 * validators - list of validations that this field supports
 */
export const FIELD_TYPE_OPTIONS = Object.freeze([
  {
    label: 'Input',
    value: FIELD_TYPE_INPUT,
    default: null,
    validators: [VALIDATION_REQUIRED, VALIDATION_PATTERN, VALIDATION_MIN_LEN, VALIDATION_MAX_LEN],
    forTypes: [DATA_TYPE_STR],
    localeMap: 'jsonToForm.formBuilder.form.input'
  },
  {
    label: 'Input Number',
    value: FIELD_TYPE_INPUT_NUMBER,
    default: null,
    validators: [VALIDATION_REQUIRED, VALIDATION_MIN, VALIDATION_MAX],
    forTypes: [DATA_TYPE_INT, DATA_TYPE_FLT],
    localeMap: 'jsonToForm.formBuilder.form.inputNumber'
  },
  {
    label: 'Text Area',
    value: FIELD_TYPE_TEXT_AREA,
    default: null,
    validators: [VALIDATION_REQUIRED, VALIDATION_MIN_LEN, VALIDATION_MAX_LEN],
    forTypes: [DATA_TYPE_STR],
    localeMap: 'jsonToForm.formBuilder.form.textArea'
  },
  {
    label: 'Checkbox',
    value: FIELD_TYPE_CHECKBOX,
    default: false,
    forTypes: [DATA_TYPE_BOOL],
    localeMap: 'jsonToForm.formBuilder.form.checkbox'
  },
  {
    label: 'Switch',
    value: FIELD_TYPE_SWITCH,
    default: false,
    forTypes: [DATA_TYPE_BOOL],
    localeMap: 'jsonToForm.formBuilder.form.switch'
  },
  {
    label: 'Select',
    value: FIELD_TYPE_SELECT,
    default: null,
    validators: [VALIDATION_REQUIRED, VALIDATION_MIN, VALIDATION_MAX],
    forTypes: [DATA_TYPE_STR, DATA_TYPE_ARRAY],
    localeMap: 'jsonToForm.formBuilder.form.select'
  },
  {
    label: 'Date Picker',
    value: FIELD_TYPE_DATE_PICKER,
    default: null,
    validators: [VALIDATION_REQUIRED],
    forTypes: [DATA_TYPE_STR],
    localeMap: 'jsonToForm.formBuilder.form.datePicker'
  },
  {
    label: 'Dynamic Input',
    value: FIELD_TYPE_DYNAMIC_INPUT,
    default: null,
    validators: [VALIDATION_REQUIRED, VALIDATION_MIN_LEN, VALIDATION_MAX_LEN],
    forTypes: [DATA_TYPE_ARRAY],
    localeMap: 'jsonToForm.formBuilder.form.dynamicInput'
  },
  {
    label: 'String to Array Value Mapping',
    value: FIELD_TYPE_STRING_ARRAY_VALUE_MAPPING,
    default: null,
    validators: [VALIDATION_REQUIRED, VALIDATION_MIN_LEN, VALIDATION_MAX_LEN],
    forTypes: [DATA_TYPE_OBJECT],
    localeMap: 'jsonToForm.formBuilder.form.stringToArrayMap'
  },
  {
    label: 'Property Mapping',
    value: FIELD_TYPE_PROPERTY_MAPPING,
    default: {},
    forTypes: [DATA_TYPE_OBJECT],
    localeMap: 'jsonToForm.formBuilder.form.propertyMapping'
  },
  {
    label: 'Dynamic Object Mapping',
    value: FIELD_TYPE_DYNAMIC_OBJECT_MAPPING,
    default: {},
    forTypes: [DATA_TYPE_OBJECT],
    localeMap: 'jsonToForm.formBuilder.form.dynamicObjectMapping'
  },
  {
    label: 'Object Array Map',
    value: FIELD_TYPE_OBJECT_ARRAY,
    default: {},
    forTypes: [DATA_TYPE_ARRAY],
    localeMap: 'jsonToForm.formBuilder.form.objectArrayMap'
  }
])

// ui field name list
export const CONFIG_FIELDS = Object.freeze([
  { label: 'Key Name', value: FIELD_KEY_NAME, isDefault: true, localeMap: 'jsonToForm.formBuilder.form.keyName' },
  { label: 'Label', value: FIELD_LABEL, isDefault: true, localeMap: 'common.label' },
  { label: 'Data Type', value: FIELD_DATA_TYPE, isDefault: true, localeMap: 'jsonToForm.formBuilder.form.dataType' },
  { label: 'Field Type', value: FIELD_FIELD_TYPE, isDefault: true, localeMap: 'jsonToForm.formBuilder.form.fieldType' },
  { label: 'Tab Labels', value: FIELD_FIELD_TAB_LABELS, isDefault: true, localeMap: 'jsonToForm.formBuilder.form.tabLabel' },
  { label: 'Default', value: FIELD_DEFAULT, isDefault: false, localeMap: 'common.default' },
  { label: 'Default Options', value: FIELD_DEFAULT_OPTIONS, isDefault: false, localeMap: 'common.defaultOptions' },
  { label: 'Description', value: FIELD_DESCRIPTION, isDefault: false, localeMap: 'common.description' },
  { label: 'Search Keys', value: FIELD_SEARCH_KEYS, isDefault: false },
  { label: 'Validations', value: FIELD_VALIDATIONS, isDefault: false },
  { label: 'Default Properties', value: FIELD_DEFAULT_PROPERTIES, isDefault: false },
  { label: 'Object Property', value: FIELD_OBJECT_PROPERTY, isDefault: false },
  { label: 'Localization', value: FIELD_LOCALIZATION, isDefault: false }
])

// validators list
export const VALIDATIONS = Object.freeze([
  {
    label: 'Required',
    value: VALIDATION_REQUIRED
  },
  {
    label: 'Pattern',
    value: VALIDATION_PATTERN
  },
  {
    label: 'Min',
    value: VALIDATION_MIN
  },
  {
    label: 'Max',
    value: VALIDATION_MAX
  },
  {
    label: 'Min Len',
    value: VALIDATION_MIN_LEN
  },
  {
    label: 'Max Len',
    value: VALIDATION_MAX_LEN
  }
])

