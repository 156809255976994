export default {
  isLoading(state) {
    return state.isLoading;
  },
  isRefreshing(state) {
    return state.isRefreshing;
  },
  messageQueue(state) {
    return state.messageQueue;
  },
  queueRows(state) {
    return state.queueRows;
  },
  queueColumns(state) {
    return state.queueColumns;
  }
}
