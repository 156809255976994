export default function () {
  const initialState = {
    isLoading: true,
    pending: false,
    filter: ['EMERGENCY', 'ALERT', 'CRITICAL', 'ERROR', 'WARNING', 'NOTICE', 'INFO', 'DEBUG'],
    loadedPages: [],
    currentPage: 1,
    itemsPerPage: 30,
    messageFilter: "",
    flowExecutionFilter: "",
    sort: "desc",
    logs: {},
    logList: {},
    totalItems: 0,
  }

  return initialState
}
