<script>
  import { defineComponent } from 'vue'
  import { useMeta } from 'quasar'
  import Particles from 'particles.js'

  const metaData = {
    // JS tags
    script: {
      particlesConfig: {
        innerHTML: `particlesJS("particles-js", {"particles":{"number":{"value":40,"density":{"enable":true,"value_area":800}},"color":{"value":"#7940FF"},"shape":{"type":"circle","stroke":{"width":0,"color":"#000000"},"polygon":{"nb_sides":5},"image":{"src":"img/github.svg","width":100,"height":100}},"opacity":{"value":0.5,"random":false,"anim":{"enable":false,"speed":1,"opacity_min":0.1,"sync":false}},"size":{"value":3,"random":true,"anim":{"enable":false,"speed":40,"size_min":0.1,"sync":false}},"line_linked":{"enable":true,"distance":150,"color":"#29D3BF","opacity":0.4,"width":1},"move":{"enable":true,"speed":6,"direction":"none","random":false,"straight":false,"out_mode":"out","bounce":false,"attract":{"enable":false,"rotateX":600,"rotateY":1200}}},"interactivity":{"detect_on":"canvas","events":{"onhover":{"enable":true,"mode":"grab"},
        "onclick": {"enable": false, "mode": "push"},"remove":{"particles_nb":2}}},"retina_detect":true});`
      }
    }
  };

  export default defineComponent({
    name: 'Particles',
    setup () {
      useMeta(metaData)
    }
  })
</script>

<template>
  <div id="particles-js"></div>
</template>

<style lang="scss">
  #particles-js {
    position: absolute;
    bottom: 5vh;
    right: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  /* ---- reset ---- */
  canvas {
    display: block;
    vertical-align: bottom;
  }
</style>
