export default function () {
  return {
    isLoading: true,
    totalItems: 0,
    currentPage: 1,
    itemsPerPage: 10,
    items: [],
    configuration: "{}"
  }
}
