<script>
  import { required, regExp, pattern, minLen, maxLen, noSpace } from 'pages/JsonToForm/Helpers/validations';
  import { notNullOrUndefined } from 'src/utils'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  export default  {
    name: 'SqInputField',
    components: { SqMarkdownDisplay },

    props: {
      modelValue: {
        type: String,
        required: false
      },

      type: {
        type: String,
        required: false,
        default: 'input'
      },

      defaultValue: {
        required: false
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      },

      nullOnEmpty: {
        type: Boolean,
        required: false,
        default: true
      },

      removable: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    emits: ['update:model-value', 'remove'],

    data() {
      return {
        value: this.modelValue,
        showPassword: false
      }
    },

    computed: {
      inputType() {
        if (this.type === 'password') {
          return this.showPassword ? 'input' : 'password'
        }

        return this.type;
      },

      rules () {
        return this.validations.map(item => {
          if (typeof item === 'string') {
            return this[item](this.$t('common._validationMessages.invalidData'))
          }

          return this[item.name](item.message, item.conditional)
        });
      }
    },

    methods: {
      notNullOrUndefined,
      required,
      regExp,
      pattern,
      minLen,
      maxLen,
      noSpace,

      updateModelValue() {
        /**
         * TLDR; racing issue.
         *
         * We wrap it in a delay for when we call the validate method, it will use the most recent data from the model.
         * There are instances where, the model value emit fires first that when the validate method is called it will pass an invalid model
         */
        setTimeout(async () => {
          const isValid = await this.$refs.sqInputFieldRef.validate()

          if (!isValid) return

          const isEmpty = !this.value

          this.$emit('update:model-value', this.nullOnEmpty && isEmpty ? null : this.value)
        }, 50)
      },

      setDefaultValue() {
        this.value = this.defaultValue

        this.updateModelValue()
      }
    },

    watch: {
      modelValue(value) {
        if (value !== this.value) {
          this.value = value
        }
      }
    }
  }
</script>

<template>
  <div>
    <q-input
      ref="sqInputFieldRef"
      v-model="value"
      v-bind="$attrs"
      clearable
      dense
      filled
      :rules="rules"
      :type="inputType"
      :class="$attrs.class || 'q-py-md'"
      @update:model-value="updateModelValue"
    >
      <template #append>
        <q-icon
          v-if="type === 'password'"
          :name="showPassword ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="showPassword = !showPassword"
        />

        <q-btn
          v-if="notNullOrUndefined(defaultValue)"
          icon="refresh"
          dense
          flat
          @click="setDefaultValue"
        />
      </template>

      <template #after>
        <q-btn
          v-if="removable"
          dense
          flat
          color="negative"
          icon="delete"
          @click="$emit('remove')"
        />
      </template>
    </q-input>

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>
  </div>
</template>
