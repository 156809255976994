<script>
import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
import SqSelectWithAdd from 'pages/JsonToForm/Fields/SqSelectWithAdd.vue'
import { v4 as uuid } from 'uuid'
import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
import { debounce } from 'lodash'

export default {
  name: 'LocalizationGenerator',
  components: { SqInputField, SqSelectWithAdd, ConfirmationDialog },

  props: {
    modelValue: {
      type: Object,
      required: false
    }
  },

  emits: ['update:model-value'],

  data() {
    return {
      showRemoveConfirmation: false,
      selectedConfig: null,
      localization: []
    }
  },

  methods: {
    addNewLocalization() {
      this.localization.push({
        id: uuid(),
        key: null,
        path: null
      })
    },

    handleConfirmRemoval(config) {
      this.showRemoveConfirmation = true
      this.selectedConfig = config
    },

    removeLocalization() {
      const index = this.localization.findIndex(l => l.id === this.selectedConfig.id)

      if (index > -1) this.localization.splice(index, 1)

      this.showRemoveConfirmation = false
      this.selectedConfig = null

      this.configChange()
    },

    configChange: debounce(function() {
      const validModels = this.localization.filter(l => l.key && l.path)
        .map(l => [l.key, l.path])

      const objectify = Object.fromEntries(validModels)

      this.$emit('update:model-value', objectify)
    }, 300),

    initialize() {
      if (this.modelValue) {
        Object.keys(this.modelValue).forEach(key => {
          this.localization.push({
            id: uuid(),
            key: key,
            path: this.modelValue[key]
          })
        })
      }
    },
  },

  mounted() {
    this.initialize()
  }
}
</script>

<template>
  <q-card flat class="q-pa-sm">
    <div class="text-weight-bolder text-grey-8">
      {{ $t('common.localization') }}

      <q-btn
        dense unelevated
        color="primary"
        size="sm"
        icon-right="translate"
        :label="$t('jsonToForm.formBuilder.form.addLocalization')"
        class="q-ml-sm"
        @click="addNewLocalization"
      />
    </div>

    <q-card-section>
      <div
        v-for="loc in localization"
        :key="loc.id"
      >
        <div class="row q-col-gutter-md items-center">
          <div class="col-6">
            <sq-input-field
              v-model="loc.key"
              :label="$t('common.key')"
              :validations="[{ name: 'noSpace' }, { name: 'required', message: $t('jsonToForm.formBuilder.validation.isRequired', { field: $t('common.key') }) }]"
              @update:model-value="configChange"
            />
          </div>
          <div class="col-6">
            <sq-input-field
              v-model="loc.path"
              :label="$t('common.path')"
              :validations="[{ name: 'noSpace' }, { name: 'required', message: $t('jsonToForm.formBuilder.validation.isRequired', { field: $t('common.path') }) }]"
              removable
              @update:model-value="configChange"
              @remove="handleConfirmRemoval(loc)"
            />
          </div>
        </div>
      </div>
    </q-card-section>

    <confirmation-dialog
      v-model="showRemoveConfirmation"
      :title="$t('common.deleteConfirmation')"
      type="negative"
      @confirm="removeLocalization"
      @close="showRemoveConfirmation = false"
    >
      <template #content>
          <span>
            {{ $t('jsonToForm.formBuilder.dialog.removeLocalizationMessage') }}
          </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>
