import client from '../helpers/authenticated-axios'

export const messageQueueService = {
  getMessageQueue(callback) {
    client().get('/rabbitmqs').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
};
