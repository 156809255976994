<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { flowService } from 'src/services'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'EndFlowModal',

  components: {
    SqDialog
  },

  props: {
    page: {},
    flowName: {},
    flowId: {},
    payload: {}
  },

  emits: ['success'],

  setup (props, { emit }) {
    const $store = useStore()

    const isLoading = ref(false)

    function endFlow() {
      isLoading.value = true
      flowService.endFlow(props.flowId, (data) => {
        isLoading.value = false
        try {
          $store.dispatch('alert/success', {message: 'flow.overview.modal.endFlowSuccess'}, { root: true });

          emit('success', data)

        } catch (e) {
          console.error(e)
        }
      });
    }

    return {
      isLoading,

      endFlow
    }
  }
})
</script>

<template>
  <sq-dialog
    type="cancel"
    :save-button-label="$t('general.confirm')"
    :loading="isLoading"
    @save="endFlow"
  >
    <template #title>
      {{ $t('flow.overview.modal.endFlow', {flowName: flowName}) }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('flow.overview.modal.endFlowConfirm') }}
      </div>
    </template>
  </sq-dialog>
</template>
