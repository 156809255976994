export function loginRequest(state, user) {
  state.status = { loggingIn: true };
  state.user = user;
}

export function loginSuccess(state, user) {
  state.status = { loggedIn: true };
  state.user = user;
  state.isLoading = false;
}

export function loginFailure(state) {
  state.status = {};
  state.user = null;
  state.isLoading = false;
}

export function logout(state) {
  state.status = {};
  state.user = null;
  state.userInit = null;
}

export function pwRequestPending(state) {
  state.pwRequestPending = true;
}

export function pwRequestFail(state) {
  state.pwRequestSuccess = false;
  state.pwRequestPending = false;
}

export function pwRequestSuccess(state) {
  state.pwRequestSuccess = true;
  state.pwRequestPending = false;
}

export function userInit(state) {
  state.userInit = true;
}
