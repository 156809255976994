<script>
  import { v4 as uuid } from 'uuid'
  import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
  import FieldTypeSelector from 'pages/JsonToForm/Fields/FieldTypeSelector.vue'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import { noSpace, required } from 'pages/JsonToForm/Helpers/validations'
  import {
    FIELD_TYPE_INPUT,
    FIELD_TYPE_INPUT_NUMBER,
    FIELD_TYPE_TEXT_AREA,
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_SWITCH,
    FIELD_TYPE_DATE_PICKER
  } from 'pages/JsonToForm/utils'
  export default {
    name: 'ObjectPropertyGenerator',
    components: { ConfirmationDialog, FieldTypeSelector, SqInputField },

    props: {
      modelValue: {
        type: Array,
        required: false,
        default: []
      }
    },

    emit: ['update:model-value'],

    data() {
      return {
        objectProperties: [],
        showConfirmation: false,
        selectedProperty: null,
        FIELD_TYPE_INPUT,
        FIELD_TYPE_INPUT_NUMBER,
        FIELD_TYPE_TEXT_AREA,
        FIELD_TYPE_CHECKBOX,
        FIELD_TYPE_SWITCH,
        FIELD_TYPE_DATE_PICKER
      }
    },

    methods: {
      noSpace: noSpace(),
      required,

      addNewProperty() {
        this.objectProperties.push({
          id: uuid(),
          key: null,
          type: null
        })
      },

      handleStageDeletion(property) {
        this.selectedProperty = property
        this.showConfirmation = true
      },

      deleteProperty() {
        const index = this.objectProperties.findIndex(prop => prop.id === this.selectedProperty.id)

        if (index > -1) {
          this.objectProperties.splice(index, 1)

          this.configChange()
        }
      },

      initialize() {
        if (this.modelValue?.length) {
          this.objectProperties = this.modelValue.map(prop => {
            return {
              ...prop
            }
          })
        } else {
          this.addNewProperty()
        }
      },

      configChange() {
        const model = this.objectProperties
          .filter(prop => prop.key && prop.type)

        this.$emit('update:model-value', model)
      }
    },

    mounted() {
      this.initialize()
    }
  }
</script>

<template>
  <q-card flat class="q-pa-sm">
    <div class="text-weight-bolder text-grey-8">
      Object Properties

      <q-btn
        color="primary"
        size="sm"
        label="Add New Object Property"
        class="q-ml-sm"
        @click="addNewProperty"
      />
    </div>

    <q-card-section>
      <div
        v-for="property in objectProperties"
        :key="property.id"
      >
        <div class="row q-col-gutter-md items-center">
          <div class="col-6">
            <sq-input-field
              v-model="property.key"
              label="Key"
              :validations="[{ name: 'noSpace' }, { name: 'required', message: 'Key field is required' }]"
              @update:model-value="configChange"
            />
          </div>

          <div class="col-6">
            <field-type-selector
              v-model="property.type"
              removable
              :only="[FIELD_TYPE_INPUT, FIELD_TYPE_INPUT_NUMBER, FIELD_TYPE_TEXT_AREA, FIELD_TYPE_CHECKBOX, FIELD_TYPE_SWITCH, FIELD_TYPE_DATE_PICKER]"
              :rules="[required('Field Type is required.')]"
              @update:model-value="configChange"
              @remove="handleStageDeletion(property)"
            />
          </div>
        </div>
      </div>

      <confirmation-dialog
        v-model="showConfirmation"
        title='Delete Confirmation'
        type="negative"
        @confirm="deleteProperty"
        @close="showConfirmation = false"
      >
        <template #content>
          <span>
            Are you sure you want to remove this row? Once saved, you will never be able to revert the changes.
          </span>
        </template>
      </confirmation-dialog>
    </q-card-section>
  </q-card>
</template>
