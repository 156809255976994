import { resolveUserLocale, setDarkMode } from 'src/utils'
import { i18n } from 'boot/i18n'
import { isEmpty } from 'lodash'

/**
 * Block page until update happened
 *
 * @constructor
 *
 * @param state
 */
export function START_UPDATE(state) {
  state.isRefreshing = true;
}

/**
 * Updates a preference in the state
 *
 * @constructor
 *
 * @param state
 * @param data
 */
export function UPDATE_PREF_IN_STATE(state, data) {
  state.isRefreshing = true;
  if (data.key === 'enableEmailNotification') {
    state.userPreferences[data.key] = !!Number(data.value)
  } else {
    state.userPreferences[data.key] = data.value
  }
  state.isRefreshing = false;
}

export function SET_PREFERENCES(state, preferences) {
  // check if we some preferences for this user
  if (!isEmpty(preferences)) {
    state.userPreferences = { ...state.userPreferences, ...preferences };

    i18n.global.locale = resolveUserLocale()
    setDarkMode(state.userPreferences.theme)
  } else {
    let storage = JSON.parse(localStorage.getItem('user'));
    if(storage !== null) {
      // PREFS ARE EMPTY - INIT THEM
      storage.user_preference = state.userPreferences;
      localStorage.setItem('user', JSON.stringify(storage));
    }
  }
  state.isRefreshing = false;
}
