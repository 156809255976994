<script>
  import { isEqual } from 'lodash'
  import { pattern, required } from 'pages/JsonToForm/Helpers/validations'
  import { VALIDATION_PATTERN } from 'pages/JsonToForm/utils'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  export default {
    name: 'SqSelectWithAdd',

    components: { SqMarkdownDisplay },

    props: {
      modelValue: {
        required: false
      },

      label: {
        type: String,
        required: true
      },

      defaultValue: {
        required: false
      },

      multiple: {
        type: Boolean,
        required: false,
        default: false
      },

      useChips: {
        type: Boolean,
        required: false,
        default: false
      },

      options: {
        type: Array,
        required: false,
        default: () => []
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      },

      hint: {
        type: String,
        required: false,
        default: ''
      }
    },

    emits: ['update:modelValue', 'update:options', 'new-value'],

    data() {
      return {
        value: this.modelValue,
        localOptions: [ ...this.options ]
      }
    },

    computed: {
      rules () {
        return this.validations.map(item => {
          if (typeof item === 'string') {
            return this[item](this.$t('common._validationMessages.invalidData'))
          }

          return this[item.name](item.message || this.$t('common._validationMessages.invalidData'), item.conditional)
        });
      },

      fieldHint() {
        const patternValidator = this.validations.find(validator => validator.name === VALIDATION_PATTERN)

        if (this.hint) return this.hint
        if (patternValidator) return `You can only add and select values with this pattern {${patternValidator.conditional}}`

        return ''
      }
    },

    methods: {
      required,
      pattern,

      updateModelValue() {
        this.$emit('update:modelValue', this.value)
      },

      handleNewValue(value, done) {
        const patternValidation = this.validations.find(validator => validator.name === VALIDATION_PATTERN)

        if (patternValidation && this.pattern(null, patternValidation.conditional)(value)) return

        const index = this.localOptions.findIndex(item => item === value)

        if (index < 0) {
          this.localOptions.push(value)
        }

        done(value, 'add-unique')

        this.$emit('update:options', {
          options: this.localOptions,
          currentValue: this.value
        })

        setTimeout(() => {
          this.$refs.selector.setOptionIndex(-1)
        }, 100)
      },

      handlePopupShow() {
        this.$refs.selector.setOptionIndex(-1)
      }
    },

    watch: {
      modelValue(value) {
        if (value !== this.value) {
          this.value = value
        }
      }
    }
  }
</script>

<template>
  <div>
    <q-select
      ref="selector"
      v-model="value"
      v-bind="$attrs"
      dense
      options-dense
      filled
      emit-value
      map-options
      use-input
      clearable
      :label="label"
      :hint="fieldHint"
      :rules="rules"
      :multiple="multiple"
      :use-chips="useChips"
      new-value-mode="add-unique"
      :options="localOptions"
      class="q-py-sm"
      @update:model-value="updateModelValue"
      @new-value="handleNewValue"
      @popup-show="handlePopupShow"
    />

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>
  </div>
</template>
