export default {
  isLoading(state) {
    return state.isLoading;
  },
  isRefreshing(state) {
    return state.isRefreshing;
  },
  supervisor(state) {
    return state.supervisor;
  },
  workerColumns(state) {
    return state.workerColumns;
  },
  workerRows(state) {
    return state.workerRows;
  }
}
