<script>
  import { v4 as uuid } from 'uuid'
  import { debounce } from 'lodash'
  import { date } from 'quasar'
  import { DATE_PICKER_OPTIONS } from 'src/meta'
  import { valueMapTransformer } from 'src/utils/transformer'
  import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
  import SingleFieldConfig from 'pages/JsonToForm/Fields/DynamicBuilder/SingleFieldConfig.vue'
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog.vue'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'
  import { notNullOrUndefined } from 'src/utils'
  import StandAloneFieldConfig from 'pages/JsonToForm/Fields/DynamicBuilder/StandAloneFieldConfig.vue'

  const datePickerValues = valueMapTransformer(DATE_PICKER_OPTIONS, 'value', 'DATE_PICKER')
  export default {
    name: 'SqDynamicObjectMapping',

    components: {
      StandAloneFieldConfig,
      SqMarkdownDisplay,
      ConfirmationDialog,
      SqInputField,
      SingleFieldConfig
    },

    props: {
      modelValue: {
        type: Object
      },

      label: {
        type: String,
        required: false,
        default: ''
      }
    },

    data() {
      return {
        showConfirmation: false,
        selectedConfig: null,
        mappings: [
          {
            id: 'root',
            label: this.label,
            header: 'root',
            body: 'root',
            children: []
          }
        ],
        expanded: ['root'],
        selectableFields: [
          { label: 'String Config', value: 'stringConfig', type: 'string' },
          { label: 'Numeric Config', value: 'numericConfig', type: 'numeric' },
          { label: 'Boolean Config', value: 'booleanConfig', type: 'boolean' },
          { label: 'Date Time Config', value: 'dateTimeConfig', type: 'date' },
          { label: 'Array Config', value: 'arrayConfig', type: 'array' },
        ],

        arraySelectableFields: [
          { label: 'Numeric Config', value: 'numericConfig', type: 'numeric' },
          { label: 'Boolean Config', value: 'booleanConfig', type: 'boolean' },
          { label: 'Date Time Config', value: 'dateTimeConfig', type: 'date' },
          { label: 'Object Config', value: 'objectConfig', type: 'object' },
          { label: 'Array Config', value: 'arrayConfig', type: 'array' },
        ]
      }
    },

    methods: {
      handleNewConfig(parent, field, key = null, value = null) {
        const newFieldConfig = {
          id: uuid(),
          value: {
            key: key,
            value: value || this.generateDefault(field.type)
          },
          fieldType: field.value,
          type: field.type,
          label: field.label,
          header: field.value,
          body: field.value,
          single: parent.fieldType === 'arrayConfig'
        }

        if (newFieldConfig.single) {
          newFieldConfig.value = value || this.generateDefault(field.type)
        }

        if (['objectConfig', 'arrayConfig'].includes(field.value)) {
          newFieldConfig.children = []
        }

        this.expanded.push(newFieldConfig.id)
        parent.children.push(newFieldConfig)
      },

      generateDefault(type) {
        if (type === 'boolean') return false
        if (type === 'object') return {}
        if (type === 'array') return []
        return null
      },

      confirmDeleteConfig(config) {
        this.selectedConfig = config
        this.showConfirmation = true
      },

      deleteConfig(mappings) {
        // stage the items that we're going to search for the match to delete
        mappings = mappings ?? this.mappings[0].children

        // see if the current list have the item that we want to delete
        const index = mappings.findIndex(map => map.id === this.selectedConfig.id)

        // if match delete it from the current list
        if (index > -1) {
          mappings.splice(index, 1)
          this.selectedConfig = null
          this.updateValue()

          return true
        }

        // if not, loop for the current list to traverse down the nested object
        for (const map of mappings) {
          if (map.children?.length) {
            if (this.deleteConfig(map.children)) return true
          }
        }

        // return false if no item found
        return false
      },

      buildObject(mappings, parent) {
        const config = parent?.type === 'array' ? [] : {}

        for (const map of mappings) {
          if (parent?.type === 'array') {
            config.push(map.children?.length ? this.buildObject(map.children, map) : map.value)
          } else {
            if (!notNullOrUndefined(map.value.key)) continue

            if (map.children?.length) {
              const constructed = this.buildObject(map.children, map)
              if (map.type === 'array') config[map.value.key] = constructed
              else config[map.value.key] = constructed
            } else {
              config[map.value.key] = map.value.value
            }
          }
        }

        return config
      },

      updateValue: debounce(function() {
        const config = this.buildObject(this.mappings[0].children)

        console.log('time google', config)

        this.$emit('update:model-value', config)
      }, 300),

      initialize(models) {
        if (models) {
          this.buildMappings(models, this.mappings[0])
        }
      },

      buildMappings(models, parent) {
        const keys = Object.keys(models)

        for (const key of keys) {
          const keyValue = models[key]

          if (Array.isArray(keyValue)) {
            this.handleNewConfig(parent, this.selectableFields[4], key, keyValue)

            this.buildMappings(keyValue, parent.children.at(-1))
          } else if (keyValue && typeof keyValue === 'object') {
            this.handleNewConfig(parent, { label: 'Object Config', value: 'objectConfig', type: 'object' }, key, keyValue)

            this.buildMappings(keyValue, parent.children.at(-1))
          } else {
            this.handleNewConfig(parent, this.getTypeByValue(keyValue) || this.selectableFields[0], key, keyValue)
          }
        }
      },

      getTypeByValue(value) {
        const dateTester = /\d{4}-\d{2}-\d{2}/

        if (value && dateTester.test(value) && (datePickerValues.includes(value) || date.isValid(value))) return this.selectableFields[3]
        if (typeof value === 'string') return this.selectableFields[0]
        if (typeof value === 'number') return this.selectableFields[1]
        if (typeof value === 'boolean') return this.selectableFields[2]
      }
    },

    mounted() {
      this.initialize(this.modelValue)
    }
  }
</script>

<template>
  <q-card flat class="q-pa-sm">
    <q-card-section>
      <div class="text-grey-8">
        <span class="text-weight-bolder">
          {{ label }}
        </span>

        <div v-if="$attrs.description" class="text-caption">
          <sq-markdown-display :markdown="$attrs.description" />
        </div>
      </div>
    </q-card-section>

    <q-card-section>
      <q-tree
        v-model:expanded="expanded"
        :nodes="mappings"
        node-key="id"
        dense
        default-expand-all
      >
        <template #default-header="prop">
          {{ prop.node.label }}
          <q-btn
            v-if="prop.node.id !== 'root'"
            dense
            flat
            padding="none"
            color="negative"
            icon="delete"
            class="q-ml-sm"
            style="z-index: 10"
            @click.stop="confirmDeleteConfig(prop.node)"
          />
        </template>

        <template #default-body="prop">
          <single-field-config
            v-if="!prop.node.single"
            v-model="prop.node.value"
            :type="prop.node.type"
            @update:model-value="updateValue"
          />

          <stand-alone-field-config
            v-else
            v-model="prop.node.value"
            :type="prop.node.type"
            @update:model-value="updateValue"
          />
        </template>

        <template #body-root="prop">
          <q-btn-dropdown
            dense
            split
            size="sm"
            icon="add"
            color="primary"
            label="Object Config"
            @click.stop="handleNewConfig(prop.node, { label: 'Object Config', value: 'objectConfig', type: 'object' })"
          >
            <q-list>
              <q-item
                v-for="field in selectableFields"
                :key="field.value"
                v-close-popup
                dense
                clickable
                @click.stop="handleNewConfig(prop.node, field)">
                <q-item-section>
                  <q-item-label>{{ field.label }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </template>

        <template #body-objectConfig="prop">
          <div class="flex items-center">
            <sq-input-field
              v-if="!prop.node.single"
              v-model="prop.node.value.key"
              class="q-py-none"
              :validations="[{name: 'noSpace'}]"
              @update:model-value="updateValue"
            />

            <div>
              <q-btn-dropdown
                dense
                split
                size="sm"
                icon="add"
                color="primary"
                label="Object Config"
                class="q-ml-md"
                @click="handleNewConfig(prop.node, { label: 'Object Config', value: 'objectConfig', type: 'object' })"
              >
                <q-list>
                  <q-item
                    v-for="field in selectableFields"
                    :key="field.value"
                    v-close-popup
                    dense
                    clickable
                    @click.stop="handleNewConfig(prop.node, field)">
                    <q-item-section>
                      <q-item-label>{{ field.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </div>
        </template>

        <template #body-arrayConfig="prop">
          <div class="flex items-center">
            <sq-input-field
              v-model="prop.node.value.key"
              class="q-py-none"
              :validations="[{name: 'noSpace'}]"
              @update:model-value="updateValue"
            />

            <div class="q-ml-sm">
              <q-btn-dropdown
                dense
                split
                size="sm"
                icon="add"
                color="primary"
                label="Value"
                @click="handleNewConfig(prop.node, { label: 'String Config', value: 'stringConfig', type: 'string' })"
              >
                <q-list>
                  <q-item
                    v-for="field in arraySelectableFields"
                    :key="field.value"
                    v-close-popup
                    dense
                    clickable
                    @click="handleNewConfig(prop.node, field)">
                    <q-item-section>
                      <q-item-label>{{ field.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </div>
        </template>
      </q-tree>
    </q-card-section>

    <confirmation-dialog
      v-model="showConfirmation"
      title='Delete Config'
      type="negative"
      @confirm="deleteConfig"
      @close="showConfirmation = false"
    >
      <template #content>
        <span>
          Are you sure you want to remove this config?
        </span>
      </template>
    </confirmation-dialog>
  </q-card>
</template>
