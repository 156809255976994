import axios from 'axios';
import apiUrl from './api-url';
import authHeader from './auth-header';
import { responseSuccess, responseError } from './interceptors/responseInterceptors'

export default function client() {
  let clientObj = null;
  /* @TODO move restore to higher order component, auth action for restoring including API request to validate token */
  let storageItem = JSON.parse(localStorage.getItem('user'));
  if (storageItem) {
    if (storageItem.token !== undefined) {
      clientObj = axios.create({
        baseURL: apiUrl(),
        timeout: 40000,
        headers: authHeader()
      })
    } else {
      // ???
      console.warn('Authenticated Axios requested but no token present!');
      console.warn(storageItem)
    }
  }

  // do we have an Axios instance
  if (clientObj) {
    clientObj.interceptors.response.use(responseSuccess, responseError)
  }

  return clientObj;
}
