<script>
import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

export default {
  name: 'Wrapper',

  components: { SqMarkdownDisplay },

  props: {
    hint: {
      type: String,
      required: false,
      default: ''
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    defaultCollapsed: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<template>
  <q-card flat class="q-mb-md">
    <q-expansion-item
      :default-opened="!defaultCollapsed"
      dense
      dense-toggle
      switch-toggle-side
      header-class="expansion-header"
    >
      <template #header>
        <div class="flex items-center full-width">
          <div class="text-weight-bolder">
            {{ label }}
          </div>
        </div>
      </template>

      <q-card-section>
        <div v-if="$attrs.description" class="text-caption">
          <sq-markdown-display :markdown="$attrs.description" />
        </div>
      </q-card-section>

      <q-card-section>
        <div class="text-grey-8">
          <div v-if="hint" class="hint q-field__messages">
            {{ hint }}
          </div>
        </div>
      </q-card-section>

      <q-card-section>
        <slot></slot>
      </q-card-section>
    </q-expansion-item>
  </q-card>
</template>
