<script>
  import { date } from 'quasar'
  import { DATE_PICKER_OPTIONS } from 'src/meta'
  import { required } from 'pages/JsonToForm/Helpers/validations'
  import SqMarkdownDisplay from 'components/Common/SqMarkdownDisplay.vue'

  export default {
    name: 'SqDatePicker',
    components: { SqMarkdownDisplay },

    props: {
      modelValue: {
        type: String,
        required: false
      },

      validations: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    data() {
      return {
        value: null,
        date: null,
        DATE_PICKER_OPTIONS
      }
    },

    computed: {
      rules () {
        return this.validations.map(item => {
          return this[item](this.$t('common._validationMessages.required'))
        });
      }
    },

    methods: {
      required,

      initialize(value) {
        const inSelection = DATE_PICKER_OPTIONS.some(item => item.value === value);

        if (inSelection) {
          this.value = value;
        } else {
          if (!isNaN(Date.parse(value))) {
            this.value = 'DATE_PICKER'
            this.date = date.formatDate(Date.parse(value), 'YYYY-MM-DD HH:mm:ss')

            return
          }

          this.value = 'DATE_PICKER'
          this.date = null
        }
      },

      validateDate(value) {
        if (!date.isValid(value) || !/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/.test(value)) {
          return 'Invalid date selected.'
        }

        return null
      },

      handleOptionChange() {
        if (this.value !== 'DATE_PICKER') {
          this.date = null

          this.updateValue(this.value)
        } else {
          this.updateValue(this.date)
        }
      },

      updateValue(value) {
        this.$emit('update:model-value', value)
      },

      async datePickerInputChange(value) {
        const isValid = await this.$refs.datePickerInputRef.validate(value)

        if (isValid) this.updateValue(value)
      }
    },

    watch: {
      modelValue: {
        handler(value) {
          if (value !== this.value) {
            this.initialize(value)
          }
        },

        immediate: true
      }
    }
  }
</script>

<template>
  <div class="date-picker">
    <q-select
      v-model="value"
      clearable
      dense
      options-dense
      filled
      emit-value
      map-options
      :rules="rules"
      :options="DATE_PICKER_OPTIONS"
      new-value-mode="add-unique"
      label="Date Options"
      class="q-py-sm"
      @update:model-value="handleOptionChange"
    >
      <template #after>
        <q-input
          v-if="value === 'DATE_PICKER'"
          ref="datePickerInputRef"
          v-model="date"
          filled
          dense
          hide-bottom-space
          :rules="[...rules, validateDate]"
          mask="####-##-## ##:##:##"
          class="full-width date-picker-input"
          @update:model-value="datePickerInputChange"
          data-cy="J2FDatePickerInput"
        >
          <template #prepend>
            <q-icon
              name="event"
              class="cursor-pointer"
            >
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-date
                  v-model="date"
                  mask="YYYY-MM-DD HH:mm:ss"
                  minimal
                  @update:model-value="datePickerInputChange"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      flat
                      label="Close"
                      color="primary"
                    />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>

          <template #append>
            <q-icon
              name="access_time"
              class="cursor-pointer"
            >
              <q-popup-proxy
                cover
                transition-show="scale"
                transition-hide="scale"
              >
                <q-time
                  v-model="date"
                  mask="YYYY-MM-DD HH:mm:ss"
                  format24h
                  @update:model-value="datePickerInputChange"
                >
                  <div class="row items-center justify-end">
                    <q-btn
                      v-close-popup
                      label="Close"
                      color="primary"
                      flat
                    />
                  </div>
                </q-time>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </template>
    </q-select>

    <div v-if="$attrs.description" class="text-caption">
      <sq-markdown-display :markdown="$attrs.description" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .date-picker {
    :deep(.q-field__after) {
      width: 60%;
    }

    :deep(.q-field__bottom) {
      position: absolute;
    }

    .date-picker-input {
      :deep(.q-field__control-container) {
        padding-top: 0 !important;
      }
    }
  }
</style>
