/**
 * Capitalized all first letter of every word in a string
 *
 * @param str <string>
 * @return <string>
 */
export function ucwords (str) {
  if (!str) return '';

  if (!(typeof str === 'string')) return '';

  return (`${str.toLowerCase()}`).
    replace(/^(.)|\s+(.)/g, (char) => {
      return char.toUpperCase();
    });
}

/**
 * Check if the given string is likely JSON or a regular string.
 * To distinguish between a regular string and a JSON string, you can leverage the fact that a JSON string typically starts with { or [.
 * If a string starts with either of these characters, it's likely JSON; otherwise, it's a regular string.
 *
 * @param str {string}
 * @returns {boolean}
 */
export function isJsonString(str) {
  // Trim leading and trailing whitespace
  str = str.trim();

  // Check if the string starts with '{' (object)
  if (str.startsWith('{')) {
    return true;
  }

  // Check if the string starts with '[' (array)
  if (str.startsWith('[')) {
    // Remove '[' and ']' characters
    const content = str.slice(1, -1).trim();
    // Check if the content is a single word without quotes, colons, commas, etc.
    // If it is, then it's a placeholder and can always pass. Otherwise, it's an array that needs to be validated.
    return (
      content.includes('"') || content.includes('\'') || content.includes(':') || content.includes(',') ||
      content.includes('{') || content.includes('}') || content.includes(' ')
    );
  }

  // Neither object nor array
  return false;
}
