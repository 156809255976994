<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import { useQuasar } from 'quasar'
  import LoadingIndicator from 'components/LoadingIndicator'
  import RestartWorkerModal from './modal/RestartWorkerModal'

  export default defineComponent({
    name: 'Supervisor',
    components: {
      LoadingIndicator
    },
    props: {
      col: {
        type: String,
        required: false,
        default: ''
      }
    },
    setup () {
      const $q = useQuasar();
      return {
        pagination: {
          rowsPerPage: 0 // 0 = show all
        },
        qInstance: $q
      }
    },
    computed: {
      ...mapGetters('supervisor', [
        'isLoading',
        'isRefreshing',
        'supervisor',
        'workerColumns',
        'workerRows'
      ])
    },
    mounted () {
      this.$store.dispatch('supervisor/getSupervisor')
    },
    methods: {
      refreshSupervisor() {
        this.$store.dispatch('supervisor/getSupervisor');
      },
      restartSupervisor() {
        this.$store.dispatch('supervisor/restartSupervisor');
      },
      openRestartWorkerModal() {
        this.qInstance.dialog({
          component: RestartWorkerModal,
          // props forwarded to your custom component
          componentProps: {
            supervisor: this.supervisor
          }
        })
      },
    }
  });
</script>
<template>
  <div class="flex justify-between q-pt-md relative-position" :class="col">
    <q-card class="app-supervisor full-width"  flat :disabled="isRefreshing ? true : null">
      <loading-indicator v-if="isLoading" />
      <q-icon size="3rem" name="assessment" class="app-card-bg-icon"></q-icon>
      <q-item>
        <q-item-section>
          <q-item-label class="app-supervisor-label app-text-weight-semibold">{{ $t('dashboard.supervisor.headline') }}</q-item-label>
        </q-item-section>
      </q-item>
      <template v-if="!isLoading && typeof supervisor !== 'undefined' && supervisor !== null && workerRows">
        <q-card-section class="q-py-none" horizontal>
          <q-card-section class="app-supvervisor-content col q-py-none q-ml-sm" data-cy="supervisorContent">
            <div class="flex full-width">
              <div class="q-mr-xl full-width">
                <q-table dense flat
                        :rows="workerRows"
                        :columns="workerColumns"
                        :row-key="workerRows.rowId"
                        :pagination.sync="pagination"
                        class="full-width"
                        hide-bottom
                        data-cy="supervisorTable"
                >
                  <template #body-cell-status="scope">
                    <td class="text-left">
                      <q-badge
                          :color="scope.value.toLowerCase() === 'running' ? 'positive' : scope.value.toLowerCase() === 'fatal' ? 'negative' : null"
                          style="text-transform: capitalize;"
                      >
                        {{ scope.value.toLowerCase() }}
                      </q-badge>
                    </td>
                  </template>
                </q-table>
              </div>
            </div>
          </q-card-section>
        </q-card-section>
        <q-card-section class="app-supervisor-action col-auto q-pl-none q-mb-sm q-mx-sm">
          <div class="flex justify-end">
            <q-btn flat dense no-caps icon-right="restart_alt" class="q-pr-sm justify-end app-action-btn q-mr-sm"
                   :label="$t('dashboard.supervisor.restart')"
                   @click.capture.stop="openRestartWorkerModal()"
                   :disabled="isRefreshing"
                   data-cy="supervisorRestartButton"
            />
            <q-btn flat dense no-caps icon-right="refresh" class="q-pr-sm justify-end app-action-btn"
                   :label="$t('dashboard.supervisor.refresh')"
                   @click.capture.stop="refreshSupervisor()"
                   :disabled="isRefreshing"
                   data-cy="supervisorRefreshButton"
            />
          </div>
        </q-card-section>
      </template>
      <q-card-section v-else-if="!isLoading" class="app-supvervisor-content col q-py-none q-ml-sm" data-cy="supervisorContentNoData">
        {{ $t('dashboard.supervisor.noData') }}
      </q-card-section>
    </q-card>
  </div>
</template>
