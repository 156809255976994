export default function () {
  return {
    isRefreshing: false,
    userPreferences: {
      locale: "en-US",
      theme: "light",
      diskFullEmailNotification: "",
      enableEmailNotification: "",
      flowFailedEmailNotification: "",
      messageFailedEmailNotification: ""
    }
  }
}
