<script>
  import { defineComponent } from 'vue'
  import FlowModal from './FlowModal/FlowModal.vue'
  import ImportFlowModal from './FlowModal/ImportFlowModal.vue'
  import ExportFlowModal from './FlowModal/ExportFlowModal.vue'

  export default defineComponent({
    name: 'FlowActionBar',

    props: ['items', 'isLoading'],

    emits: ['refresh'],

    components: {
      FlowModal,
      ExportFlowModal,
      ImportFlowModal
    },

    data () {
      return {
        showImportFlowModal: false,
        showExportFlowModal: false,
        showFlowModal: false
      }
    },
    methods: {
      handleCreated() {
        this.showFlowModal = false
        this.$emit('refresh', {type: 'create'})
      },

      handleImported(data) {
        if (data.imported) {
          this.showImportFlowModal = false
          this.$emit('refresh')
        }
      }
    }
  })
</script>

<template>
  <div v-if="items.length > 0" class="full-width flex justify-end q-mb-sm">
    <q-btn
      flat
      dense
      icon-right="file_download"
      :label="$t('flow.overview.importFlow')"
      :title="$t('flow.overview.importFlow')"
      :disable="isLoading"
      class="app-action-btn q-pa-sm q-ml-sm q-mt-sm"
      @click.capture.stop='showImportFlowModal = true'
    />

    <q-btn
      flat
      dense
      icon-right="file_upload"
      :label="$t('flow.overview.exportFlow')"
      :title="$t('flow.overview.exportFlow')"
      :disable="isLoading"
      class="app-action-btn q-pa-sm q-ml-sm q-mt-sm"
      @click.capture.stop="showExportFlowModal = true"
    />

    <q-btn
      flat
      dense
      icon-right="add"
      :label="$t('flow.overview.addFlow')"
      :title="$t('flow.overview.addFlow')"
      :disable="isLoading"
      data-cy="buttonAddFlow"
      class="app-action-btn q-pa-sm q-ml-sm q-mt-sm app-highlighted-btn"
      @click.capture.stop="showFlowModal = true"
    />
  </div>

  <div v-if="items.length <= 0" class="column col-12">
    <p class="text-center text-light">{{ $t('flow.overview.noFlows') }}</p>
    <div class="q-mx-auto">
      <q-btn
        flat
        icon-right="add"
        :label="$t('flow.overview.addFlow')"
        :title="$t('flow.overview.addFlow')"
        data-cy="buttonAddFlow"
        class="app-action-btn q-ml-sm q-mt-sm"
        @click.capture.stop="showFlowModal = true"
      />

      <q-btn
        flat
        icon-right="arrow_upward"
        :label="$t('flow.overview.importFlow')"
        :title="$t('flow.overview.importFlow')"
        class="app-action-btn q-ml-sm q-mt-sm"
        @click.capture.stop='showImportFlowModal = true'
      />
    </div>
  </div>

  <flow-modal
    v-model="showFlowModal"
    @created="handleCreated"
  />

  <import-flow-modal
    v-model="showImportFlowModal"
    @imported="handleImported"
  />

  <export-flow-modal v-model="showExportFlowModal"/>
</template>
