export const SNIPPET_TYPE_CONFIG = 1;
export const SNIPPET_TYPE_TEMPLATE = 2;

import { i18n } from 'src/boot/i18n';

export const SNIPPET_TYPES = Object.freeze([
  { label: i18n.global.t('configSnippets.type.config'), value: SNIPPET_TYPE_CONFIG },
  { label: i18n.global.t('configSnippets.type.template'), value: SNIPPET_TYPE_TEMPLATE }
])

  export const CONFIG_SNIPPET_COLUMNS = Object.freeze([
  {
    name: 'key',
    field: 'key',
    label: i18n.global.t('configSnippets.snippetKey'),
    sortable: true,
    align: 'left',
    translationMeta: {
      path: null
    }
  },
  {
    name: 'type',
    field: 'type',
    label: i18n.global.t('configSnippets.snippetType'),
    format: (value) => SNIPPET_TYPES.find((st) => st.value === value)?.label,
    sortable: true,
    align: 'left',
    translationMeta: {
      path: null
    }
  },
  {
    name: 'description',
    field: 'description',
    label: i18n.global.t('configSnippets.description'),
    sortable: true,
    align: 'left',
    translationMeta: {
      path: null
    }
  },
  {
    name: 'actions',
    field: 'actions',
    label: i18n.global.t('configSnippets.actions'),
    align: 'left',
    translationMeta: {
      path: 'common.action',
      plural: 2
    }
  }
]);
