<script>
  import {
    FIELD_TYPE_INPUT,
    FIELD_TYPE_TEXT_AREA,
    FIELD_TYPE_INPUT_NUMBER,
    FIELD_TYPE_SWITCH,
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_SELECT
  } from 'pages/JsonToForm/utils'
  import { number } from 'pages/JsonToForm/Helpers/validations'

  export default {
    name: 'DefaultValueEvaluator',

    props: {
      modelValue: {
        required: false
      },

      fieldConfig: {
        type: Object,
        required: true
      },

      fieldType: {
        type: String,
        required: true
      },

      selectOptions: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    emits: ['update:model-value', 'delete'],

    data() {
      return {
        value: null,
        FIELD_TYPE_TEXT_AREA
      }
    },

    computed: {
      selectableOptions() {
        if ([FIELD_TYPE_CHECKBOX, FIELD_TYPE_SWITCH].includes(this.fieldType)) {
          return [
            { label: 'True', value: true },
            { label: 'False', value: false }
          ]
        } else if (this.showSelectField) {
          return this.selectOptions
        }

        return []
      },

      showSelectField() {
        return [
          FIELD_TYPE_CHECKBOX,
          FIELD_TYPE_SWITCH,
          FIELD_TYPE_SELECT
        ].includes(this.fieldType)
      },

      showInputField() {
        return [
          FIELD_TYPE_INPUT,
          FIELD_TYPE_TEXT_AREA
        ].includes(this.fieldType)
      },

      showNumberField() {
        return [
          FIELD_TYPE_INPUT_NUMBER
        ].includes(this.fieldType)
      }
    },

    methods: {
      number,

      initialize() {
        let defaultValue = null

        if (this.modelValue !== undefined) defaultValue = this.modelValue

        this.value = defaultValue
      },

      updateValue() {
        this.$emit('update:model-value', this.value)
      },

      remove() {
        this.$emit('delete')
      }
    },

    watch: {
      modelValue: {
        handler() {
          this.initialize()
        },

        deep: true,
        immediate: true
      },

      selectOptions(newValue) {
        if (!newValue.includes(this.value)) {
          this.value = null
        }
      }
    }
  }
</script>

<template>
  <div class="row no-wrap items-start items-center">
    <div class="full-width">
      <q-input
        v-if="showInputField"
        v-model="value"
        clearable
        dense
        filled
        :type="FIELD_TYPE_TEXT_AREA === fieldType ? 'textarea' : 'input'"
        label="Default"
        class="q-py-sm"
        @update:model-value="updateValue"
      />

      <q-input
        v-else-if="showNumberField"
        v-model.number="value"
        clearable
        dense
        filled
        :rules="[number()]"
        type="number"
        label="Default"
        class="q-py-sm"
        @update:model-value="updateValue"
      />

      <q-select
        v-else-if="showSelectField"
        v-model="value"
        clearable
        dense
        options-dense
        filled
        emit-value
        map-options
        :multiple="fieldConfig.isSelectMultiple"
        :use-chips="fieldConfig.isSelectMultiple"
        :options="selectableOptions"
        new-value-mode="add-unique"
        label="Default Options"
        class="q-py-sm"
        @update:model-value="updateValue"
      />
    </div>

    <div class="remove-button-container">
      <q-btn
        color="negative"
        icon="delete"
        @click="remove"
      />
    </div>
  </div>
</template>

<style scoped>
  .remove-button-container {
    margin-left: 6px;
  }
</style>
