<script>
  import { defineComponent, defineAsyncComponent } from 'vue'

  export default defineComponent({
    name: 'LogPage',
    components: {
      Logs: defineAsyncComponent(() => import('../../components/Logs/Logs.vue'))
    }
  })
</script>

<template>
  <q-page class="block">
    <div>
      <div class="q-pa-md">
        <logs />
      </div>
    </div>
  </q-page>
</template>
