import client from '../helpers/authenticated-axios'

export const userManagementService = {
  getUsers(query, callback) {
    client().get('/users', {
      params: query
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  addUser(formData, callback) {
    client().post('/users', {
      "displayName": formData.name,
      "email": formData.email,
      "roles": formData.roles,
      "password": formData.password,
      shouldEmail: formData.shouldEmail
    }).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  updateUser(user, formData, callback) {
    let fD = {
      "displayName": formData.name,
      "email": formData.email,
      "roles": formData.roles,
      "apiToken": formData.apiToken
    };
    if(formData.password !== "") fD.password = formData.password;
    client().put('/users' + '/' + user.id, fD).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },

  deleteUser(user, callback) {
    client().delete('/users' + '/' + user.id).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  }
}
