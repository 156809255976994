export default {
  jsonToForm: {
    jsonToForm: 'Json to Form',
    jdmScreen: {
      flowConfig: 'Flow Config',
      jdmForm: 'JDM Form',
      dialog: {
        confirmSwitch: 'Confirm Switch',
        confirmSwitchMessage: 'Invalid config, are you sure you want to switch to Form Mode? You will lose some information by doing so.',
        confirmLeave: 'Confirm Leave',
        confirmLeaveMessage: 'Warning: Unsaved changes. Leave without saving? Any unsaved changes will be lost!'
      },
      viewMode: {
        fullView: 'Full View',
        splitView: 'Split View',
        expertMode: 'Expert Mode'
      },
      notification: {
        failedToLoadFlow: 'Failed to load Flow info. Please refresh the page and try again.',
        failedToLoadSchema: 'Failed to load form schema.',
        invalidForm: 'Invalid form. Please check for any validation errors, fix them and try saving again.',
        jdmSaved: 'JDM config saved',
        jdmSaveFailed: 'Failed to save JDM. Please report this to your administrator.',
        invalidConfigurationCheckConfig: 'Invalid configuration. Please check your config and make sure they are valid.',
        invalidConfigurationCheckConfigCont1: 'If you think that this is a problem, kindly create a report.',
        invalidConfigurationCheckConfigCont2: 'You can turn Form Mode off by toggling the mode switch above.'
      }
    },
    formBuilder: {
      formBuilder: 'Form Builder',
      formConfigList: 'Form Config List',
      createNewConfig: 'Create New Config',
      loadSchema: 'Load Schema',
      loadedSchema: 'Loaded Schema',
      formBuilderListNote: 'The forms you create here is not persisted in the cloud. All are saved locally, if the browser cache is cleared so do the list here.',
      formBuilderEmptyList: 'No configuration has been created yet.',
      closeBuilder: 'Close Builder',
      form: {
        wrapperConfig: 'Wrapper Config',
        fieldConfig: 'Field Config',
        objectConfig: 'Object Config',
        copySingleNode: 'Copy single node',
        copyNodeWithChildren: 'Copy node with children',
        keyName: 'Key Name',
        fieldLabel: 'Field Label',
        valueType: 'Value Type',
        fieldType: 'Field Type',
        dataType: 'Data Type',
        tabLabel: 'Tab Label | Tab Labels',
        wrapperType: 'Wrapper Type | Wrapper Types',
        defaultCollapsed: 'Default Collapsed',
        searchKey: 'Search Key | Search Keys',
        addLocalization: 'Add Localization',
        input: 'Input',
        inputNumber: 'Input Number',
        textArea: 'Text Area',
        checkbox: 'Checkbox',
        switch: 'Switch',
        select: 'Select',
        datePicker: 'Date Picker',
        dynamicInput: 'Dynamic Input',
        stringToArrayMap: 'String to Array Value Mapping',
        propertyMapping: 'Property Mapping',
        dynamicObjectMapping: 'Dynamic Object Mapping',
        objectArrayMap: 'Object Array Map'
      },
      table: {
        configName: 'Config Name',
        editForm: 'Edit Form',
        previewForm: 'Preview Form',
        exportSchema: 'Export Schema',
        deleteForm: 'Delete Form'
      },
      dialog: {
        deleteSchemaConfirmation: 'Are you sure you want to remove this config?',
        deleteConfig: 'Delete Config',
        deleteConfigMessage: 'Are you sure you want to remove this object config? It will also delete all of its child configurations and once saved you will never be able to revert it.',
        removeLocalizationMessage: 'Are you sure you want to remove this config? Once saved, you will never be able to revert the changes.',
      },
      notification: {
        schemaDeleted: '"{name}" has been deleted',
        formUpdated: 'Form Updated',
        formCreated: 'Form Created',
        saveFormError: 'Form configuration error. Please verify the form and try again.'
      },
      validation: {
        isRequired: '{field} is required | {field} are required',
        invalidObjectConfig: 'Object configuration is invalid. Fields with * are required.',
        invalidFieldConfig: 'Field configuration is invalid. Fields with * are required.',
      },
      fieldConfigGenerator: {
        shouldMask: 'Should Mask',
        isMultiple: 'Is Multiple',
        isMultipleNotApplicable: 'Not applicable for single value select',
        canAddNewValue: 'Can Add New Value',
        canAddNewValueNotApplicable: 'Not applicable for single value select',
        searchKeys: 'Search Keys',
        description: 'Description',
        options: 'Options',
        selectAdditionalOption: 'Select Additional Option',
        confirmRemove: 'Confirm Remove',
        confirmRemoveWarning: 'Are you sure you want to remove this field configuration? Once confirmed, you will not be able to revert the changes anymore.'
      }
    }
  }
}
