import { Notify } from 'quasar';

/**
 * Shows error notification
 *
 * @param message
 */
export const notifyError = (message) => {
  Notify.create({
    type: 'negative',
    message,
    timeout: 5000
  });
};

/**
 * Shows success notification
 *
 * @param message
 */
export const notifySuccess = (message) => {
  Notify.create({
    type: 'success',
    color: 'positive',
    message,
    timeout: 5000
  });
};
