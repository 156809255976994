<script>
  import { defineComponent } from 'vue'
  import { mapState } from 'vuex'
  import { userManagementService } from 'src/services'
  import usePaginator from 'src/composables/usePaginator'
  import UserModal from './components/UserModal'
  import DeleteUserModal from './components/DeleteUserModal'
  import SqDialog from 'components/Common/SqDialog.vue'
  import Paginator from 'components/Paginator.vue'

  export default defineComponent({
    name: 'UserManagement',

    components: {
      Paginator,
      SqDialog,
      UserModal,
      DeleteUserModal
    },

    setup () {
      const { pagination, readFromQuery, updateRouteQuery, clearQuery, buildQueryData } = usePaginator()
      return {
        pagination,
        readFromQuery,
        updateRouteQuery,
        clearQuery,
        buildQueryData
      }
    },
    data () {
      return {
        isLoading: false,
        showUserModal: false,
        showDeleteConfirmation: false,
        selectedUser: null,
        showActiveUsers: true,
        userList: [],
        columns: [
          {
            name: 'id',
            field: 'id',
            required: true,
            label: this.$t('user.form.id'),
            align: 'left'
          },
          {
            name: 'name',
            required: true,
            label: this.$t('user.form.name'),
            align: 'left',
            field: row => row.display_name,
            format: val => `${val}`
          },
          {
            name: 'email',
            required: true,
            label: this.$t('user.form.mail'),
            align: 'left',
            field: row => row.email,
            format: val => `${val}`
          },
          {
            name: 'action',
            required: true,
            label: this.$t('user.form.action'),
            align: 'left',
            field: 'action'
          }
        ]
      }
    },

    computed: {
      ...mapState('authentication', ['user'])
    },

    methods: {
      handleSaved() {
        this.showUserModal = false
        const totalItems = this.pagination.totalItems

        if (!this.selectedUser) {
          if (totalItems > 0 && (totalItems % this.pagination.itemsPerPage === 0)) {
            //  Creating first item or new page
            this.pagination.page = this.pagination.pageCount + 1
          }
          else {
            if (!(totalItems === 0 || (this.pagination.page === this.pagination.pageCount))) {
              // Currently on any previous page, jump to last page, since new created item is there
              this.pagination.page = this.pagination.pageCount
            }
          }
        }
        this.pageLoad()
        this.selectedUser = null
      },
      updateUser(evt, user) {
        this.selectedUser = user
        this.showUserModal = true
      },
      deleteUser(user) {
        this.showDeleteConfirmation = true
        this.selectedUser = user
      },
      handleDeleted(data) {
        this.showDeleteConfirmation = false
        this.selectedUser = null
        if (data?.user_id) {
          const userIndex = this.userList.findIndex(user => Number(user.id) === Number(data.user_id))
          if (userIndex > -1) {
            this.userList.splice(userIndex, 1)
            if (this.pagination.page > 1 && !this.userList.length) {
              this.pagination.page--
              this.pageLoad()
            }
          }
        }
      },
      resetPageAndReload() {
        this.pagination.page = 1
        this.pageLoad()
      },
      pageLoad() {
        this.isLoading = true
        this.updateRouteQuery(this.pagination)
        const query = {
          ...this.buildQueryData(this.pagination),
          isActive: this.showActiveUsers
        }
        userManagementService.getUsers(query, (data) => {
          if (data.users) {
            this.userList = data.users
            data.pagination.itemsPerPage = data.pagination.size
            delete data.pagination.size
            this.pagination = data.pagination

            if (!data.users?.length && this.pagination.page > 1) {
              this.pagination.page--
              this.pageLoad()
            }
          }
          this.isLoading = false
        })
      }
    },

    mounted() {
      this.readFromQuery(this.pagination)
      this.pageLoad()
    },

    unmounted() {
      this.clearQuery();
    }
  })

</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <div class="flex">
        <h1>{{ $t('user.headline') }}</h1>
        <p>{{ $t('user.desc') }}</p>

        <div class="q-ml-auto q-mb-lg">
          <q-btn
            v-if="!isLoading"
            flat
            dense
            icon-right="add"
            :label="$t('user.addUser')"
            :title="$t('user.addUser')"
            class="app-action-btn q-pa-sm"
            @click.capture.stop='showUserModal = true'
          />
        </div>
      </div>

      <q-card flat>
        <q-card-section>
          <div class="row q-pa-sm">
            <q-table
              flat
              :rows="userList"
              :row-key="userList.id"
              :columns="columns"
              color="primary"
              :hide-bottom=true
              :loading="isLoading"
              hide-pagination
              :pagination="{
                page: 1,
                rowsPerPage: 0
              }"
              virtual-scroll
              class="app-user-management-table col-12 transparent"
              @row-click="updateUser"
            >
              <template #top>
                <div class="q-ml-auto">
                  <q-btn-toggle
                    unelevated
                    size="sm"
                    v-model="showActiveUsers"
                    :options="[
                      {label: this.$t('user.activeUsers'), value: true},
                      {label: this.$t('user.inactiveUsers'), value: false}
                    ]"
                    @update:model-value="resetPageAndReload"
                  />
                </div>
              </template>

              <template v-slot:body-cell-action="props">
                <q-td :props="props">
                  <div class="flex no-wrap">
                    <q-btn
                      icon-right="edit"
                      size="12px" flat dense
                      class="q-mr-sm q-mr-md-none"
                      :title="$t('user.updateUser')"
                      :disable="isLoading"
                      @click.capture.stop="updateUser(null, props.row)"
                    />

                    <q-btn
                      v-if="!props.row.deleted_at && props.row.email !== user.username"
                      icon-right="delete"
                      size="12px" no-caps flat dense
                      class="q-ml-sm"
                      :title="$t('user.deleteUser')"
                      :class="isLoading ? 'disabled' : 'negative-hover'"
                      @click.capture.stop="deleteUser(props.row)"
                    />
                  </div>
                </q-td>
              </template>
            </q-table>
          </div>
        </q-card-section>
      </q-card>

      <paginator
        v-model:page="pagination.page"
        v-model:itemsPerPage="pagination.itemsPerPage"
        :total-pages="pagination.pageCount"
        class="q-mt-lg"
        @update:page="pageLoad"
        @update:items-per-page="resetPageAndReload"
      />

      <q-inner-loading
        :showing="isLoading"
        color="primary"
      >
        <q-spinner
          color="primary"
          :thickness="3"
          size="38px"
        />
      </q-inner-loading>
    </div>

    <user-modal
      v-model="showUserModal"
      :user="selectedUser"
      :total-pages="pagination.pageCount"
      :items-per-page="pagination.itemsPerPage"
      @saved="handleSaved"
      @hide="selectedUser = null"
    />

    <delete-user-modal
      v-model="showDeleteConfirmation"
      :user="selectedUser"
      @deleted="handleDeleted"
    />
  </q-page>
</template>

<style lang="scss">
.app-user-management-table .q-table__top {
  padding: 0 0 1rem 0;
}
</style>
