export function checkRunningFlowLoop(runningFlow, pageLoad) {
  if(runningFlow) {
    return setTimeout(() => {
      pageLoad(true);
    }, 2500);
  }
  return null;
}

export function clearLooping(autoRefreshLooping) {
  clearTimeout(autoRefreshLooping);
  autoRefreshLooping = null;
  return autoRefreshLooping;
}
