<script>
import SqInputNumberField from 'pages/JsonToForm/Fields/SqInputNumberField.vue'
import SqDatePicker from 'pages/JsonToForm/Fields/SqDatePicker.vue'
import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
import SqCheckbox from 'pages/JsonToForm/Fields/SqCheckbox.vue'

export default {
  name: 'StandAloneFieldConfig',
  components: {
    SqCheckbox,
    SqInputField,
    SqDatePicker,
    SqInputNumberField
  },

  props: {
    modelValue: {
      required: false
    },

    type: {
      type: String,
      required: true
    },

    removable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['update:model-value', 'remove'],

  data() {
    return {
      value: this.modelValue
    }
  },

  methods: {
    updateValue() {
      this.$emit('update:model-value', this.value)
    }
  },

  watch: {
    modelValue(newValue) {
      if (this.value !== newValue) this.value = newValue
    }
  }
}
</script>

<template>
  <div class="row q-gutter-md flex no-wrap items-center">
    <sq-input-field
      v-if="type === 'string'"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-input-number-field
      v-else-if="type === 'numeric'"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-checkbox
      v-else-if="type === 'boolean'"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-date-picker
      v-else-if="type === 'date'"
      v-model="value"
        class="full-width BESCHREIBUNGTYP1"
      @update:model-value="updateValue"
    />

    <q-btn
      v-if="removable"
      dense
      flat
      padding="none"
      color="negative"
      icon="delete"
      class="q-ml-sm"
      @click="$emit('remove')"
    />
  </div>
</template>
