/**
 * Checks if the given value is not null or undefined. The condition below not only checks for null
 * but also for undefined. Make sure to not change it to strict comparison as it will fail the method's purpose
 *
 * @param value
 * @returns {boolean}
 */
export function notNullOrUndefined(value) {
  return value != null;
}
