import { fileSystemService } from 'src/services'

export function fileSystemLoad({ dispatch, commit, state }) {
  fileSystemService.fileSystemLoad(function(data) {
    if(typeof data !== "undefined") {
      commit('fileSystemLoad', data)
    }
  })
}

export function addFilesystem({ dispatch, commit, state }, {name, config}) {
  commit('blockFileSystem');
  fileSystemService.addFilesystem(name, config, function (data) {
    if(typeof data !== "undefined") {
      if (typeof data['@id'] !== "undefined") {
        dispatch('alert/success', 'browser.modal.createFilesystemSuccess', {root: true});
        dispatch('fileSystemLoad');
      } else {
        if (typeof data.statusText !== "undefined") {
          dispatch('alert/error', data.data['hydra:description'], {root: true});
          commit('freeFileSystem');
        } else {
          dispatch('alert/error', data, {root: true});
          commit('freeFileSystem');
        }
      }
    }
  });
}

export function editFilesystem({ dispatch, commit, state }, {name, config}) {
  commit('blockFileSystem');
  fileSystemService.editFilesystem(name, config, function (data) {
    if(typeof data !== "undefined") {
      if (typeof data['@id'] !== "undefined") {
        dispatch('alert/success', 'browser.modal.editFilesystemSuccess', {root: true});
        dispatch('fileSystemLoad');
      } else {
        if (typeof data.statusText !== "undefined") {
          dispatch('alert/error', data.data['hydra:description'], {root: true});
          commit('freeFileSystem');
        } else {
          dispatch('alert/error', data, {root: true});
          commit('freeFileSystem');
        }
      }
    }
  });
}

export function deleteFilesystem({ dispatch, commit, state }, filesystem) {
  commit('blockFileSystem');
  fileSystemService.deleteFilesystem(filesystem.replace("/api", ""), function (data) {
    if(typeof data !== "undefined" && typeof data.data.detail !== "undefined") {
      dispatch('alert/error', data.data.detail, {root: true});
    } else {
      dispatch('alert/success', 'browser.modal.deleteFilesystemSuccess', {root: true});
      dispatch('fileSystemLoad');
    }
  });
}
