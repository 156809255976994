<script>
import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Logo',
    props: {
      mobile: {
        type: Boolean,
        default: false
      }
    },
    setup () {
      return {
        src: "/assets/app-teaser.svg"
      }
    },
  })
</script>

<template>
  <div v-if="!$props.mobile" class="app-teaser-col q-pa-md gt-md q-mx-md">
    <img alt="" :src="src">
  </div>
  <img v-if="$props.mobile" class="lt-lg" alt="" :src="src" style="height: 4rem;">
</template>
