<script>
import { ref } from 'vue'
import { ucwords } from 'src/utils/stringUtils'
import usePaginator from 'src/composables/usePaginator'
import severityLevels from 'src/meta/severityLevels'
import SelectInputFilter from "components/Charts/Filter/SelectInputFilter";
import Paginator from 'components/Paginator.vue'

export default {
  name: "ValidationInfoIndex",

  components: {
    Paginator,
    SelectInputFilter
  },

  setup() {
    const { pagination, readFromQuery, updateRouteQuery, buildQueryData } = usePaginator()

    return {
      pagination,
      severityLevels,

      readFromQuery,
      updateRouteQuery,
      buildQueryData
    }
  },

  data() {
    const columns = [
      {
        name: 'index',
        field: 'index',
        label: this.getTranslation("index"),
        align: 'left',
        style: 'width: 20px',
        classes: 'ellipsis'
      },
      {
        name: 'severityLevel',
        field: (row) => ucwords(row.severityLevel),
        label: this.getTranslation("severityLevel"),
        align: 'left',
        classes: 'ellipsis'
      },
      {
        name: 'document',
        label: this.getTranslation("document"),
        align: 'left',
        style: 'white-space: pre',
        classes: 'ellipsis'
      },
      {
        name: 'translatedTechnicalIdentifier',
        field: (row) => {
          return row.translatedTechnicalIdentifier + '\n' + `Field: ${row.fieldPath}`
        },
        label: this.getTranslation("message"),
        align: 'left',
        style: 'white-space: pre',
        classes: 'ellipsis'
      }
    ];
    return {
      columns,
      filterToggle: {
        severityLevel: [], // Computed on created
        documentClass: this.$route.query.class ? this.$route.query.class : [],
        documentId: this.$route.query.id ? this.$route.query.id : '',
        technicalIdentifier: this.$route.query.message ? this.$route.query.message : ''
      },
      loading: false,
      validationInfo: [],
      entityTypes: [],
    }
  },

  computed: {
    filter() {
      return {
        severityLevel: this.filterToggle.severityLevel,
        documentId: this.filterToggle.documentId,
        documentClass: this.filterToggle.documentClass,
        technicalIdentifier: this.filterToggle.technicalIdentifier,
      }
    }
  },

  methods: {
    getTranslation(key) {
      return this.$t('validation.table.' + key);
    },

    setSeverityLevels() {
      if(this.$route.query.severity) {
        if(Array.isArray(this.$route.query.severity)) {
          this.filterToggle.severityLevel = this.$route.query.severity;
        } else {
          this.filterToggle.severityLevel = [this.$route.query.severity];
        }
      }
    },

    getEntityListFilter() {
      this.loading = true;

      this.$api.entityAPI.list()
        .then(res => {
          const entityListData = res.data['hydra:member']
          entityListData.forEach(data => {
            this.entityTypes.push(data.collectionName)
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false;
        })
    },

    viewDocumentInfo(row) {
      let urlFragment = row.documentClass.split('\\').slice(-1) + '?page=1&filter={"_id":"' + row.documentId + '"}';
      this.$router.push('/documents/' + urlFragment)
    },

    getValidationList() {
      this.loading = true;
      let params = this.filter;

      this.updateRouteQuery(this.pagination)
      params = {
        ...this.buildQueryData(this.pagination),
        ...params
      }

      this.$api.validationInfo.list(params)
        .then(res => {
          if (res.data) {
            this.validationInfo = res.data['hydra:member']
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(_ => {
          this.loading = false
        })
    },

    handleTableRequest() {
      this.getValidationList();
    },

    resetPageAndReload() {
      this.pagination.page = 1
      this.getValidationList()
    }
  },

  created() {
    this.setSeverityLevels();
    this.readFromQuery(this.pagination)

    this.getEntityListFilter()
    this.getValidationList()
  },
}
</script>

<template>
  <q-page class="block q-pa-md validation-list">
    <div class="q-mb-md">
      <h1>{{ $t('validation.headline') }}</h1>
      <p>{{ $t('validation.desc') }}</p>
    </div>

    <q-card flat>
      <q-card-section>
        <q-table
          flat
          dense
          virtual-scroll
          hide-pagination
          row-key="id"
          color="primary"
          :rows="validationInfo"
          :columns="columns"
          :pagination="pagination"
          :rows-per-page-options="[pagination.itemsPerPage]"
          :loading="loading"
          :no-data-label="$t('validation.noDataOrAllFiltered')"
          :loading-label="$t('general.loading')"
          class="col-12 validation-table q-pa-sm"
        >
          <template v-slot:top>
            <div class="row flex justify-start items-center full-width">
              <div class="severity-filter row justify-between q-pb-sm q-mr-lg">
                <q-toggle
                  v-for="(severityColor, index) of severityLevels.colors"
                  v-model="filterToggle.severityLevel"
                  :val="index"
                  @update:model-value="handleTableRequest"
                >
                  <q-badge
                    outline
                    :color="severityColor"
                    :label="$t('validation.severityLevelTypes.' + index)"
                  />
                </q-toggle>
              </div>
              <div class="row">
                <div class="entity-filter q-pb-sm col-12 col-sm-auto q-mr-md">
                  <select-input-filter
                    type="multiple"
                    :options="entityTypes"
                    :label="$t('validation.documentType')"
                    class="flex justify-center items-center validation-table-filter"
                    @update-model="handleTableRequest"
                  />
                </div>
                <div class="search-filter col-12 col-sm-auto q-pb-sm q-mr-md">
                  <q-input
                    v-model="filterToggle.documentId"
                    dense
                    debounce="400"
                    color="primary"
                    :placeholder="$t('validation.search')"
                    style="min-width: 150px !important;"
                    @update:model-value="handleTableRequest"
                  >
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </div>

                <!-- @TODO @API: Provide technical identifier filter -->
                <!--
                <div class="search-filter col-12 col-sm-auto q-pb-sm">
                  <q-input
                      v-model="filterToggle.technicalIdentifier"
                      dense
                      debounce="400"
                      color="primary"
                      :placeholder="$t('validation.messageSearch')"
                      style="min-width: 150px !important;"
                      @update:model-value="handleTableRequest"
                  >
                    <template v-slot:append>
                      <q-icon name="search" />
                    </template>
                  </q-input>
                </div>
                -->
              </div>
            </div>
          </template>

          <template #body-cell-index="scope">
            <q-td
              :props="scope"
              class="font-500 ellipsis"
            >
              {{ scope.rowIndex + 1 }}
            </q-td>
          </template>

          <template #body-cell-severityLevel="scope">
            <q-td class="font-500 ellipsis"
                  :props="scope"
            >
              <q-badge
                outline
                :color="severityLevels.colors[scope.row.severityLevel]"
                :label="$t('validation.severityLevelTypes.' + scope.row.severityLevel)"
              />
            </q-td>
          </template>

          <template #body-cell-document="scope">
            <q-td class="font-500 ellipsis cursor-pointer"
                  :props="scope"
                  @click="viewDocumentInfo(scope.row)"
            >
              {{ scope.row.documentClass.split('\\').slice(-1) + '\n' + scope.row.documentId }}
            </q-td>
          </template>

          <template #loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <paginator
      v-model:page="pagination.page"
      v-model:itemsPerPage="pagination.itemsPerPage"
      :total-pages="pagination.pageCount"
      class="q-mt-lg"
      @update:page="getValidationList"
      @update:items-per-page="resetPageAndReload"
    />
  </q-page>
</template>

<style lang="scss">
.validation-table {
  .severity-filter {
    height: auto;
    .q-toggle {
      min-width: 7rem;
    }
    body.screen--xs & {
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap;
      width: auto;
    }
    .entity-filter {
      height: auto;
      body.screen--xs & {
        width: 100%;
        margin-left: 0;
      }
    }
    .search-filter {
      height: auto;
      body.screen--xs & {
        width: 100%;
        margin-left: 0;
      }
    }
    .validation-table-filter {
      background: none !important;
    }
  }
}
</style>
