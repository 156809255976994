import client from '../helpers/authenticated-axios'

export const statisticsService = {
  getMongoStatistics(callback) {
    client().get('/mongo_statistics').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  }
};
