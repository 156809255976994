import entityAPI from 'src/services/Api/entityAPI'

/**
 * Load entities from the API
 *
 * @param commit
 * @param state
 */
export function loadEntityList({ commit, state }) {
  // still loading? we should not call the API again
  if (state.entityListLoading) return;

  commit('START_ENTITY_LIST_LOADING');

  entityAPI.list()
    .then(res => {
      commit('SET_ENTITY_LIST', res.data["hydra:member"])
    })
}

/**
 * Update the current active entity name
 *
 * @param commit
 * @param entity
 */
export function updateCurrentEntityName({ commit }, entity) {
  commit('UPDATE_CURRENT_ENTITY_NAME', entity)
}
