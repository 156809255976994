export default {
  updateTabLabelOptions({ commit, state }, values) {
    values.forEach(item => {
      if (state.labelUseCounter[item]) {
        commit('incrementUseCounter', item)
      } else {
        commit('addNewTabLabelOption', item)
      }
    })
  },

  checkToRemoveTabLabel({ commit, state }, label) {
    if (state.labelUseCounter[label] === 1) {
      commit('removeLabelOptionAndCounter', label)
    } else {
      commit('decrementUseCounter', label)
    }
  },

  reset({ commit }) {
    commit('reset')
  }
}
