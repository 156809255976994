import { date } from 'quasar'

export function receivedData(state, data) {
  state.supervisor = data;
  let workerColumns;
  let workerRows = [];

  workerColumns = [
    {
      name: 'name', // must be 'name' - quasar default
      required: true,
      label: 'Identifier',
      align: 'left',
      field: row => row.name,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'uptime',
      required: true,
      label: 'Uptime',
      align: 'left',
      field: row => row.uptime,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'status',
      required: true,
      label: 'Status',
      align: 'left',
      field: row => row.status,
      format: val => `${val}`,
      sortable: false
    }
  ];
  state.workerColumns = workerColumns;

  if(typeof data !== "undefined") {
    data.forEach((worker, index) => {
      workerRows.push({
        rowId: index + 1,
        name: worker.identifier,
        uptime: worker.description,
        status: worker.status
      })
    });
    state.workerRows = workerRows;
    freeComponent(state)
  }
}

export function startLoading(state) {
  //state.supervisor = null;
  state.isLoading = true;
}

export function startReloading(state) {
  state.isRefreshing = true;
}

export function freeComponent(state) {
  state.isRefreshing = false;
  state.isLoading = false;
}
