import client from '../helpers/authenticated-axios'

export const modulesService = {
  getModules(callback) {
    client().get('/modules').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
}
