<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'DeleteFilesystemModal',

  components: {
    SqDialog
  },

  emits: ['deleted'],

  props: {
    filesystem: {
      type: Object
    }
  },

  setup (props, { emit }) {
    const $store = useStore()

    const isLoading = ref(false)

    function deleteFilesystem() {
      isLoading.value = true

      $store.dispatch('fileSystem/deleteFilesystem', props.filesystem['@id'])

      // we don't currently have the ability to track the status of the API request. So to mitigate the
      // problem and make sure we display something to our users are running something in the background
      // we set a valid timeout time for the UI to act something
      setTimeout(() => {
        isLoading.value = false

        emit('deleted', props.filesystem['@id'])
      }, 1000)
    }

    return {
      isLoading,

      deleteFilesystem
    }
  }
})
</script>

<template>
  <sq-dialog
    type="delete"
    :save-button-label="$t('general.delete')"
    :loading="isLoading"
    @save="deleteFilesystem"
  >
    <template #title>
      {{ $t('browser.deleteFilesystem') }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('browser.deleteFilesystemDesc', {filesystem: this.filesystem['@id'].replace("/api/filesystems", "")}) }}
      </div>
    </template>
  </sq-dialog>
</template>
