export default {
  currentPage: function(state) {
    return state.currentPage;
  },
  filter: function(state) {
    return state.filter;
  },
  getLogs: function(state) {
    return state.logs;
  },
  totalItems: function(state) {
    return state.totalItems;
  },
  getLogList: function(state) {
    return state.logList;
  },
  sort(state) {
    return state.sort;
  },
  isLoading(state) {
    return state.isLoading;
  },
  initialized(state) {
    return state.initialized;
  },
  pending(state) {
    return state.pending;
  },
  prepended(state) {
    return state.prepended;
  },
  getItemsPerPage(state) {
    return state.itemsPerPage;
  },
  getPosition(state) {
    return state.position;
  },
  getTotalDiff(state) {
    return state.totalDiff;
  }
}
