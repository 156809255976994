export default {
  pwRequestPending: function(state) {
    return state.pwRequestPending;
  },
  pwRequestSuccess: function(state) {
    return state.pwRequestSuccess;
  },
  userInit: function(state) {
    return state.userInit;
  }
}
