import { useClipboard } from '@vueuse/core'
import { isNullOrUndefined } from 'src/utils'
import { Notify } from 'quasar'

const { copy, text, isSupported } = useClipboard()

/**
 * Use click-to-copy functionality
 *
 * @todo Add fallback opt
 * @todo Add clipboard permissions check
 */
export function useClickToCopy() {
  return function onClickToCopy(event, { value, overrideLabel }) {
    event.preventDefault();
    event.stopPropagation();

    if (isNullOrUndefined(value)) {
      return;
    }

    copy(`${value}`).then(() => {
      if (text.value) {
        Notify.create({
          type: 'success',
          color: 'positive',
          position: 'top-right',
          message: overrideLabel ? overrideLabel : 'Copied to clipboard.'
        })
      }
    });
  };
}

