import client from '../helpers/authenticated-axios'

export const cronjobService = {
  getCronjobs(page, itemsPerPage, callback) {
    client().get('/cron_entries?page=' + page + '&itemsPerPage=' + itemsPerPage).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error);
    });
  },
  createCronjob(formData, callback) {
    client().post('/cron_entries', {"flow": "/api/flows/" + formData.flow, "cronExpression": formData.cronExpression, "active": formData.active}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },
  updateCronjob(updatedCronjob, formData, callback) {
    client().put('/cron_entries' + '/' + updatedCronjob.id, {"flow": "/api/flows/" + formData.flow, "cronExpression": formData.cronExpression, "active": formData.active}).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  },
  deleteCronjob(deletedCronjob, callback) {
    client().delete('/cron_entries' + '/' + deletedCronjob.id).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  }
}
