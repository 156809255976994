<script>
export default {
  name: 'SqMarkdownDisplay',

  props: {
    markdown: {
      type: String,
      required: false
    }
  }
}
</script>

<template>
  <div>
    <base target="_blank" />
    <div class="markdown" v-html="markdown" />
  </div>
</template>

<style lang="scss">
  .markdown {
    a {
      color: #7940FF;
    }
  }
</style>
