export default {
  isLoading(state) {
    return state.isLoading;
  },
  isRefreshing(state) {
    return state.isRefreshing;
  },
  filemanager(state) {
    return state.filemanager;
  },
  fileContent(state) {
    return state.fileContent;
  }
}
