<script>
  import { defineComponent, ref } from 'vue'
  import { useStore } from 'vuex'

  import JsonEditor from 'components/JsonEditor.vue'
  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'CreateFilesystemModal',

    components: {
      SqDialog,
      JsonEditor
    },

    emits: ['created'],

    setup (props, { emit }) {
      const $store = useStore()

      const filesystemName = ref('')
      const createFilesystemJsonConfig = ref("{\"adapter\":\"local\",\"adapterConfig\":{\"path\":\"\"},\"publicUrlPrefix\":null}")
      const isLoading = ref(false)

      function addFilesystem() {
        isLoading.value = true
        $store.dispatch('fileSystem/addFilesystem', {name: filesystemName.value, config: createFilesystemJsonConfig.value});

        // we don't currently have the ability to track the status of the API request. So to mitigate the
        // problem and make sure we display something to our users are running something in the background
        // we set a valid timeout time for the UI to act something
        setTimeout(() => {
          isLoading.value = false
          emit('created')
        } ,1000)

      }

      function resetState() {
        isLoading.value = false
        filesystemName.value = ''
        createFilesystemJsonConfig.value = "{\"adapter\":\"local\",\"adapterConfig\":{\"path\":\"\"},\"publicUrlPrefix\":null}"
      }

      return {
        filesystemName,
        createFilesystemJsonConfig,
        isLoading,

        resetState,
        addFilesystem
      }
    },
    methods: {
      /* TODO: outsource this to helper and also add this check to new file + new folder function */
      isEmpty (val, castAsBool) {
        const CONDITION = val.length <= 0;
        if(castAsBool) return !!CONDITION;
        if (CONDITION) {
          return this.$t('browser.modal.nameRequired');
        }
      },
      hasForbiddenChar (val, castAsBool) {
        const REGEX = new RegExp('^[a-zA-Z0-9_.-]+$')
        const CONDITION = !REGEX.test(val);
        if(castAsBool) return !!CONDITION;
        if(CONDITION) {
          return this.$t('browser.modal.forbiddenChar');
        }
      }
    }
  })
</script>

<template>
  <sq-dialog
    type="create"
    :save-button-label="$t('browser.modal.createFilesystem')"
    :disabled="isEmpty(filesystemName, true) || hasForbiddenChar(filesystemName, true)"
    :loading="isLoading"
    size="md"
    @save="addFilesystem"
    @hide="resetState"
  >
    <template #title>
      <div class="text-h6 dialog-title">
        {{ $t('browser.addFilesystem') }}
        <div class="text-subtitle1">{{ $t('browser.addFilesystemDesc') }}</div>
      </div>
    </template>

    <template #content>
      <q-input
        v-model="filesystemName"
        type="text"
        :label="$t('browser.modal.namePlaceholder')"
        :rules="[isEmpty, hasForbiddenChar]"
        data-cy="filesystemNameInput"
        class="app-create-filesystem-name-input q-ml-xs q-mt-sm"
      />

      <div class="text-h6 q-mt-md">{{ $t('browser.modal.jsonConfigHeadline') }}</div>

      <json-editor
        v-model="createFilesystemJsonConfig"
        wrapped
        data-cy="filesystemConfigInput"
      />
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  @media (min-width: $breakpoint-xs) {
    .app-create-filesystem-name-input {
      width: 50%;
    }
  }

  .dialog-title {
    line-height: 1rem;
  }
</style>
