export default {
  modules: function(state) {
    return state.data;
  },
  processSteps: function(state) {
    return state.processSteps;
  },
  isLoading(state) {
    return state.isLoading;
  }
}
