import { fileManagerService } from 'src/services'

export function filemanagerLoad({ dispatch, commit, state }, filesystem) {
  commit('blockManager');
  fileManagerService.filemanagerLoad(filesystem, function(data) {
    if(!(typeof data === "undefined" || data.status === 500)) {
      commit('filemanagerLoad', data)
    } else {
      dispatch('alert/error', (data?.msg ?? data.data['hydra:title'] + ": " + data.data['hydra:description'] ?? 'browser.loadFilesystemError'), { root: true });
      console.error('Error: ' + data.data['hydra:description'])
      commit('fallback');
    }
  })
}

export function addFile({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.addFile(path, file, function (data) {
    if (data?.success) {
      dispatch('alert/success', 'browser.modal.addFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.addFileError', {root: true});
    commit('freePage');
  });
}

export function addFileFromUrl({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.addFileFromUrl(path, file, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.addFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.addFileError', {root: true});
    commit('freePage');
  });
}

export function addFolder({ dispatch, commit, state }, {path, folder}) {
  commit('blockManager');
  fileManagerService.addFolder(path, folder, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.addFolderSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.addFolderError', {root: true});
    commit('freePage');
  });
}

export function renameFile({ dispatch, commit, state }, {path, oldName, newName}) {
  commit('blockManager');
  fileManagerService.renameFile(path, oldName, newName, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.renameFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.renameError', {root: true});
    commit('freePage');
  });
}

export function copyFile({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.copyFile(path, file, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.copyFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.copyError', {root: true});
    commit('freePage');
  });
}

export function moveFile({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.moveFile(path, file, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.moveFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.moveError', {root: true});
    commit('freePage');
  });
}

export function deleteFile({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.deleteFile(path, file, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.deleteFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);

      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.deleteError', {root: true});
    commit('freePage');
  });
}

export function readFile({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.readFile(path, file, function (data) {
    if(data && typeof data.meta_data !== "undefined") {
      commit('fileLoad', data);
    } else {
      dispatch('alert/error', data, {root: true});
      commit('freePage');
    }
  });
}

export function editFile({ dispatch, commit, state }, {path, file}) {
  commit('blockManager');
  fileManagerService.addFile(path, file, function (data) {
    if(data?.success) {
      dispatch('alert/success', 'browser.modal.editFileSuccess', {root: true});
      dispatch('filemanagerLoad', path);
      return;
    }
    dispatch('alert/error', (data?.msg ?? data?.data['hydra:description']) ?? 'browser.modal.editError', {root: true});
    commit('freePage');
  });
}

export function clearFile({ dispatch, commit, state }) {
  commit('fileClear');
}
