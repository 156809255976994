<script>
import { defineComponent, ref } from 'vue'
import { cronjobService } from 'src/services'
import { useStore } from 'vuex'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'DeleteCronjobModal',

  components: {
    SqDialog
  },

  props: {
    cronjob: {
      type: Object
    }
  },

  emits: ['deleted'],

  setup (props, { emit }) {
    const $store = useStore();

    const isLoading = ref(false)

    function deleteCronjob() {
      isLoading.value = true

      cronjobService.deleteCronjob(props.cronjob, (data) => {
        isLoading.value = false

        if (data?.data?.['@type'] === "hydra:Error") {
          $store.dispatch('alert/error', "cronjob.deleteCronjobFail", { root: true });
        } else {
          $store.dispatch('alert/success', "cronjob.deleteCronjobSuccess", {root: true});
        }

        emit('deleted', data)
      });
    }

    return {
      isLoading,

      deleteCronjob

    }
  }
})
</script>

<template>
  <sq-dialog
    type="delete"
    :save-button-label="$t('general.delete')"
    :loading="isLoading"
    @save="deleteCronjob"
  >
    <template #title>
      {{ $t('cronjob.deleteCronjob') }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('cronjob.deleteCronjobConfirm') }}
        <b class="app-text-weight-semibold">#{{ cronjob.id }}</b>?
      </div>
    </template>
  </sq-dialog>
</template>
