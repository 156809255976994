<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'DeleteFileModal',

  components: {
    SqDialog
  },

  props: {
    path: {},
    file: {}
  },

  emits: ['deleted'],

  setup (props, { emit }) {
    const $store = useStore()

    const isLoading = ref(false)

    function deleteFile() {
      isLoading.value = true

      let allFileNames = []

      for(let i = 0; i < props.file.length; i++) allFileNames.push(props.file[i].basename);

      $store.dispatch('fileManager/deleteFile', {path: props.path, file: allFileNames});

      // we don't currently have the ability to track the status of the API request. So to mitigate the
      // problem and make sure we display something to our users are running something in the background
      // we set a valid timeout time for the UI to act something
      setTimeout(() => {
        isLoading.value = false

        emit('deleted')
      }, 1000)
    }

    return {
      isLoading,

      deleteFile
    }
  },

  computed: {
    allFileNames() {
      let allFileNames = "";
      for(let i = 0; i < this.file.length; i++) {
        allFileNames += this.file[i].basename;
        if(i < this.file.length - 1) allFileNames += ", "
      }
      return allFileNames;
    }
  },
})
</script>

<template>
  <sq-dialog
    type="delete"
    :save-button-label="$t('general.delete')"
    :loading="isLoading"
    @save="deleteFile"
  >
    <template #title>
      {{ $t('browser.deleteFile') }}
    </template>

    <template #content>
      <div class="q-ml-sm q-my-md">
        {{ $t('browser.deleteFileDesc', {file: allFileNames}) }}
      </div>
    </template>
  </sq-dialog>
</template>
