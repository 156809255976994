import client from 'src/helpers/authenticated-axios'

/**
 * Validation info collection
 */
export default {
  /**
   * Retrieve list of validation infos. This endpoint is paginated by default.
   *
   * @param query {object|null}
   *
   * @returns Promise
   */
  list(query = null) {
    return client().get('/validation/infos', {
      params: query
    })
  },

  getSeverityGroup(query = null) {
    return client().get('/validation/info/group/severity', {
      params: query
    })
  },

  getCommonMessageGroup(query = null) {
    return client().get('/validation/info/group/common', {
      params: query
    })
  },

  getFlowSeveritiesGroup(query = null) {
    return client().get('/validation/info/group/flow', {
      params: query
    })
  },

  getMostSeenEntities(query = null) {
    return client().get('/validation/info/most/seen', {
      params: query
    })
  },

  getAlertEntity(query = null) {
    return client().get('/validation/info/entities/alert', {
      params: query
    })
  }
}
