import client from '../helpers/authenticated-axios'

export const heartbeatService = {
  getHeartbeat(callback) {
    client().get('/heartbeats').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },
  startHeartbeat(callback) {
    client().get('/heartbeats/start').then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  }
};
