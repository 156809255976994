import { useI18n } from 'vue-i18n'
/**
 * Used for form field validation. Verify if the emitted value of a field is valid or not
 *
 * @param message {String}
 *
 * @returns {function(*=): null|string}
 */
export function required(message) {
  return function validate(value) {
    let isValid

    if (Array.isArray(value) && !value.length) isValid = false
    else isValid = !!value

    return isValid ? null : message || useI18n().t('common._validationMessages.required')
  }
}

/**
 * Validates if the given value is a valid numeric value. The validation should only accept
 * float and integer values
 *
 * @param message {String}
 *
 * @returns {function(*=): null|string|number}
 */
export function number(message) {
  return (value) => {
    const accepted = /^[+-]?\d+(\.\d+)?$/;
    const isValid = !(value && !accepted.test(value))

    return isValid ? null : message || useI18n().t('common._validationMessages.number')
  }
}

/**
 * Validates a given number value against another value to check if the given number value is not lesser than the set min value
 *
 * @param message {String}
 * @param min {number}
 *
 * @returns {function(*=): null|string|number}
 */
export function min(message, min) {
  return (value) => {
    const errorMessage = message || useI18n().t('common._validationMessages.min', { val: min })

    if (Array.isArray(value)) {
      return value.length < Number(min) ? errorMessage : null
    }

    return Number(value) < Number(min) ? errorMessage : null
  }
}

/**
 * Validates a given number value against another value to check if the given number value is not greater than the set max value
 *
 * @param message {String}
 * @param max {number}
 *
 * @returns {function(*=): null|string|number}
 */
export function max(message, max) {
  return (value) => {
    const errorMessage = message || useI18n().t('common._validationMessages.max', { val: max })

    if (Array.isArray(value)) {
      return value.length > Number(max) ? errorMessage : null
    }

    return Number(value) > Number(max) ? errorMessage : null
  }
}

/**
 * Validates a given string if its length is not lesser than the set min len value
 *
 * @param message {String}
 * @param min {number}
 *
 * @returns {function(*=): null|string|number}
 */
export function minLen(message, min) {
  return (value) => {
    const errorMessage = message || useI18n().t('common._validationMessages.minLen', { val: min })
    if (!value) return errorMessage

    return value.length < Number(min) ? errorMessage : null
  }
}

/**
 * Validates a given string if its length is not greater than the set max len value
 *
 * @param message {String|null}
 * @param max {number}
 *
 * @returns {function(*=): null|string|number}
 */
export function maxLen(message, max) {
  return (value) => {
    const errorMessage = message || useI18n().t('common._validationMessages.maxLen', { val: max })
    if (!value) return null

    return value.length > Number(max) ? errorMessage : null
  }
}

/**
 * Validates if a given string is a valid regex pattern. Well, most of the time, a string will always be a valid regex
 * with some exceptions like additional characters at the end of the string pattern. This validator assumes also that the string pattern
 * already includes the forward slashes at the start and end of the string.
 *
 * valid samples
 * /^[0-9a-f]{32}$/
 * /test/
 *
 * invalid samples
 * ^[0-9a-f]{32}$
 * test
 * /random things here//
 *
 * @param message
 *
 * @returns {function(*): null|string}
 */
export function regExp(message) {
  return (value) => {
    let isValid = true

    try {
      const flags = value.replace(/.*\/([gimy]*)$/, '$1');
      const pattern = value.replace(new RegExp('^/(.*?)/'+flags+'$'), '$1');
      new RegExp(pattern, flags);
    } catch (e) {
      isValid = false
    }

    return isValid ? null : message || useI18n().t('common._validationMessages.regExp')
  }
}

/**
 * Compare a given string from a regex pattern.
 *
 * @param message {String}
 * @param pattern {String}
 *
 * @returns {function(*=, *=): null|string}
 */
export function pattern(message, pattern) {
  return (value) => {
    if (!value) return null

    let isValid

    try {
      isValid = new RegExp(pattern).test(value)
    } catch (e) {
      isValid = false
    }

    return isValid ? null : message || useI18n().t('common._validationMessages.regExp')
  }
}

/**
 * Checks if a given value has whitespace in it
 *
 * @param message {string}
 * @returns {function(*): string|null}
 */
export function noSpace(message) {
  return value => {
    return value?.indexOf(' ') > -1 ? message || useI18n().t('common._validationMessages.noWhiteSpace') : null
  }
}

