<script>
import { defineComponent, ref, watch } from 'vue'
import { useQuasar } from 'quasar'
import { mapActions } from 'vuex'
import { configureFakeBackend } from 'src/helpers'
import { getUserPreferencesFromLocalStorage, setDarkMode } from 'src/utils'

configureFakeBackend();

export default defineComponent({
  name: 'App',
  setup () {
    const $q = useQuasar();
  },
  methods: {
    ...mapActions('preferences', ['updatePrefInState']),

    setSiteFromUserPreferences() {
      const userSettings = getUserPreferencesFromLocalStorage()
      if (userSettings) {
        for (const pref in userSettings) {
          this.updatePrefInState({
            key: pref,
            value: userSettings[pref]
          })
          if (pref === 'locale') {
            this.$i18n.locale = this.$q.lang.isoName = userSettings[pref]
            watch(ref(this.$i18n.locale), val => {
              // dynamic import, so loading on demand only
              import(
                /* webpackInclude: /(de|en-US)\.js$/ */
              'quasar/lang/' + val
                ).then(lang => {
                this.$q.lang.set(lang.default)
              })
            })
          }
          if (pref === 'theme') setDarkMode(userSettings[pref])
        }
      }
    }
  },

  mounted() {
    this.setSiteFromUserPreferences()
  }
})
</script>

<template>
  <router-view />
</template>
