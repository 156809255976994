<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'

import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'EditFilesystemModal',

  components: {
    SqDialog,
    JsonEditor
  },

  props: {
    filesystem: {
      type: Object
    }
  },

  emits: ['updated'],

  setup (props, { emit }) {
    const $store = useStore()

    const filesystemName = ref('')
    const editFilesystemJsonConfig = ref(null)
    const isLoading = ref(false)

    function saveFilesystem() {
      isLoading.value = true

      $store.dispatch('fileSystem/editFilesystem', {name: filesystemName.value, config: editFilesystemJsonConfig.value})

      // we don't currently have the ability to track the status of the API request. So to mitigate the
      // problem and make sure we display something to our users are running something in the background
      // we set a valid timeout time for the UI to act something
      setTimeout(() => {
        isLoading.value = false
        emit('updated')
      } ,1000)
    }

    function getJson() {
      try {
        editFilesystemJsonConfig.value = JSON.stringify(props.filesystem.config);
      } catch(e) {}
    }

    function initialize() {
      filesystemName.value = props.filesystem.identifier

      getJson()
    }

    function resetState() {
      isLoading.value = false
      filesystemName.value = ''
      editFilesystemJsonConfig.value = null
    }

    return {
      filesystemName,
      editFilesystemJsonConfig,
      isLoading,

      initialize,
      resetState,
      saveFilesystem
    }
  }
})
</script>

<template>
  <sq-dialog
    type="edit"
    :save-button-label="$t('browser.modal.editFilesystem')"
    :loading="isLoading"
    size="md"
    @save="saveFilesystem"
    @hide="resetState"
    @show="initialize"
  >
    <template #title>
      {{ $t('browser.editFilesystem') }}
    </template>

    <template #content>
      <div class="text-h6 q-mt-md">{{ $t('browser.modal.jsonConfigHeadline') }}</div>
      <json-editor
        v-model="editFilesystemJsonConfig"
        wrapped
        class="q-mt-none"
        init-timeout="300"
      />
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  @media (min-width: $breakpoint-xs) {
    .app-create-filesystem-name-input {
      width: 50%;
    }
  }
</style>
