<script>
import { defineComponent } from 'vue'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'EntityRefreshModal',

  components: {
    SqDialog
  },
  emits: ['success'],
  methods: {
    confirmRefresh() {
      this.$emit('success');
    }
  }
})
</script>

<template>
  <sq-dialog
      @save="confirmRefresh"
      :save-button-label="$t('entities.refreshButton')"
  >
    <template #title>
      {{ $t('entities.refresh') }}
    </template>

    <template #content>
      {{ $t('entities.refreshDescription') }}
    </template>
  </sq-dialog>
</template>
