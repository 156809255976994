<script>
import {
  FIELD_TYPE_INPUT,
  FIELD_TYPE_INPUT_NUMBER,
  FIELD_TYPE_TEXT_AREA,
  FIELD_TYPE_CHECKBOX,
  FIELD_TYPE_SWITCH,
  FIELD_TYPE_SELECT,
  FIELD_TYPE_DATE_PICKER
} from 'pages/JsonToForm/utils'
import SqInputNumberField from 'pages/JsonToForm/Fields/SqInputNumberField.vue'
import SqDatePicker from 'pages/JsonToForm/Fields/SqDatePicker.vue'
import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
import SqCheckbox from 'pages/JsonToForm/Fields/SqCheckbox.vue'
import SqSwitch from 'pages/JsonToForm/Fields/SqSwitch.vue'

export default {
  name: 'SingleFieldConfigByType',
  components: {
    SqSwitch,
    SqCheckbox,
    SqInputField,
    SqDatePicker,
    SqInputNumberField
  },

  props: {
    modelValue: {
      required: false
    },

    type: {
      type: String,
      required: true
    },

    removable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['update:model-value', 'remove'],

  data() {
    return {
      value: this.modelValue,
      FIELD_TYPE_INPUT,
      FIELD_TYPE_INPUT_NUMBER,
      FIELD_TYPE_TEXT_AREA,
      FIELD_TYPE_CHECKBOX,
      FIELD_TYPE_SWITCH,
      FIELD_TYPE_SELECT,
      FIELD_TYPE_DATE_PICKER
    }
  },

  methods: {
    updateValue() {
      this.$emit('update:model-value', this.value)
    }
  },

  watch: {
    modelValue(newValue) {
      if (this.value !== newValue) this.value = newValue
    }
  }
}
</script>

<template>
  <div class="flex no-wrap items-center">
    <sq-input-field
      v-if="type === FIELD_TYPE_INPUT"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-input-field
      v-if="type === FIELD_TYPE_TEXT_AREA"
      v-model="value"
      type="textarea"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-input-number-field
      v-else-if="type === FIELD_TYPE_INPUT_NUMBER"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-checkbox
      v-else-if="type === FIELD_TYPE_CHECKBOX"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-switch
      v-else-if="type === FIELD_TYPE_SWITCH"
      v-model="value"
      label="Value"
      class="full-width"
      @update:model-value="updateValue"
    />

    <sq-date-picker
      v-else-if="type === FIELD_TYPE_DATE_PICKER"
      v-model="value"
        class="full-width BESCHREIBUNGTYP1"
      @update:model-value="updateValue"
    />

    <q-btn
      v-if="removable"
      dense
      flat
      padding="none"
      color="negative"
      icon="delete"
      class="q-ml-sm"
      @click="$emit('remove')"
    />
  </div>
</template>
