<script>
  export default {
    name: 'ConfigSelector',

    emits: ['selected'],

    computed: {
      selectableConfig() {
        return [
          { label: this.$t('jsonToForm.formBuilder.form.fieldConfig'), value: 'field_config' }
        ]
      }
    },

    methods: {
      onMainClick() {
        this.$emit('selected')
      },

      onItemClick(type) {
        this.$emit('selected', type)
      }
    }
  }
</script>

<template>
  <q-btn-dropdown
    v-bind="$attrs"
    dense unelevated
    split
    size="sm"
    icon-right="add"
    color="primary"
    :label="$t('jsonToForm.formBuilder.form.wrapperConfig')"
    @click="onMainClick"
  >
    <q-list>
      <q-item
        v-for="field in selectableConfig"
        :key="field.value"
        v-close-popup
        clickable
        @click="onItemClick(field.value)">
        <q-item-section>
          <q-item-label>{{ field.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>
