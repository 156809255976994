<script>
import { defineComponent } from 'vue'
import SqDialog from 'components/Common/SqDialog.vue'
import JsonEditor from 'components/JsonEditor.vue'

export default defineComponent({
  name: 'MaximizeModal',

  components: {
    SqDialog,
    JsonEditor
  },
  emits: ['success', 'hide'],

  props: {
    row: {
      type: Object,
      required: true
    },
    document: { // Already stringified document
      type: String,
      required: true
    },
    schema: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      originalCode: this.$props.document, // Editor will be reset to this when cancel of any kind happens
      appliedCode: null,
      code: this.$props.document,
      validJson: null,
      validSchema: null,
    }
  },
  methods: {
    editorInit() {
      this.code = this.originalCode;
    },
    confirmEdit() {
      this.applyEdit();
      this.closeEdit();
    },
    applyEdit() {
      this.appliedCode = this.code;
      this.$store.dispatch('alert/success', this.$t('general.applied'), {root: true});
    },
    closeEdit() {
      this.appliedCode !== null ? this.$emit('success', this.code) : this.$emit('hide');
    },
    validateJson(val) {
      this.validJson = val;
    },
    validateSchema(val) {
      this.validSchema = val;
    }
  }

})
</script>

<template>
  <sq-dialog
        class="app-full-dialog"
        type="update"
        size="lg"
        full-height
  >
    <template #title>
      {{ $props.row.document.identifier }}
    </template>

    <template #content>
      <json-editor
          v-model="code"
          wrapped
          min-height="60vh"
          max-height="100%"
          class="q-py-md"
          :schema="schema"
          @is-valid-json="validateJson"
          @is-valid-schema="validateSchema"
      />
    </template>

    <template #actions>
      <q-btn
          flat
          @click="closeEdit()"
          :label="$t('general.cancel')"
      />

      <q-btn
          flat
          :disabled="!validJson || !validSchema"
          :label="$t('general.save')"
          color="primary"
          data-cy="buttonSave"
          @click="confirmEdit()"
      />

      <q-btn
          flat
          :disabled="!validJson || !validSchema"
          :label="$t('general.apply')"
          color="primary"
          data-cy="buttonSave"
          @click="applyEdit()"
      />
    </template>
  </sq-dialog>
</template>
