import ConfigSelector from 'pages/JsonToForm/ConfigSelector'
import FieldConfigGenerator from 'pages/JsonToForm/FieldConfigGenerator'
import ConfigObjectWrapper from 'pages/JsonToForm/ConfigObjectWrapper'
import SqInputField from 'pages/JsonToForm/Fields/SqInputField'
import SqInputNumberField from 'pages/JsonToForm/Fields/SqInputNumberField'
import SqCheckbox from 'pages/JsonToForm/Fields/SqCheckbox'
import SqSwitch from 'pages/JsonToForm/Fields/SqSwitch'
import SqSelect from 'pages/JsonToForm/Fields/SqSelect'
import SqSelectWithAdd from 'pages/JsonToForm/Fields/SqSelectWithAdd'
import SqDynamicInput from 'pages/JsonToForm/Fields/SqDynamicInput'
import SqInputMapping from 'pages/JsonToForm/Fields/SqInputMapping'
import SqDatePicker from 'pages/JsonToForm/Fields/SqDatePicker'
import SqPropertyMapping from 'pages/JsonToForm/Fields/SqPropertyMapping'
import SqDynamicObjectMapping from 'pages/JsonToForm/Fields/SqDynamicObjectMapping.vue'
import SqObjectArray from 'pages/JsonToForm/Fields/SqObjectArray.vue'
import Wrapper from 'pages/JsonToForm/Fields/Wrapper.vue'

/**
 * Register your global components here
 *
 * @param app
 * @returns {Promise<void>}
 */
export default async ({ app }) => {
  app.component('config-selector', ConfigSelector)
  app.component('field-config-generator', FieldConfigGenerator)
  app.component('config-object-wrapper', ConfigObjectWrapper)
  app.component('sq-input-field', SqInputField)
  app.component('sq-input-number-field', SqInputNumberField)
  app.component('sq-checkbox', SqCheckbox)
  app.component('sq-switch', SqSwitch)
  app.component('sq-select', SqSelect)
  app.component('sq-select-with-add', SqSelectWithAdd)
  app.component('sq-dynamic-input', SqDynamicInput)
  app.component('sq-input-mapping', SqInputMapping)
  app.component('sq-date-picker', SqDatePicker)
  app.component('sq-property-mapping', SqPropertyMapping)
  app.component('sq-dynamic-object-mapping', SqDynamicObjectMapping)
  app.component('sq-object-array', SqObjectArray)
  app.component('wrapper', Wrapper)
}
