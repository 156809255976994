export function receivedData(state, data) {
  state.messageQueue = data;
  state.isLoading = false;

  let queueColumns;
  let queueRows = [];

  queueColumns = [
    {
      name: 'name', // must be 'name' - quasar default
      required: true,
      label: 'Identifier',
      align: 'left',
      field: row => row.name,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'messages',
      required: true,
      label: 'Message Count',
      align: 'left',
      field: row => row.messages,
      format: val => `${val}`,
      sortable: false
    },
    {
      name: 'status',
      required: true,
      label: 'Status',
      align: 'left',
      field: row => row.status,
      format: val => `${val}`,
      sortable: false
    }
  ];
  state.queueColumns = queueColumns;

  if (typeof data !== "undefined") {
    data.forEach(worker => {
      queueRows.push({
        name: worker.identifier,
        messages: worker.messages,
        status: worker.status
      })
    });
    state.queueRows = queueRows;
  }
}

export function startLoading(state) {
  state.messageQueue = null;
  state.isLoading = true;
}
