<script>
  import { defineComponent, ref } from 'vue'
  import { mapGetters } from 'vuex'
  import LoadingIndicator from 'components/LoadingIndicator'
  export default defineComponent({
    name: "StatisticCard",
    emits: ["goto"],
    props: {
      headline: {
        type: String,
        required: true,
        default: ''
      },
      value: {
        type: String,
        required: true,
        default: ''
      },
      icon: {
        type: String,
        required: false,
        default: ''
      },
      col: {
        type: String,
        required: false,
        default: ''
      }
    },
    components: {
      LoadingIndicator
    },
    computed: {
      ...mapGetters('mongoStatistics', [
        'isLoading',
      ])
    }
  });
</script>
<template>
  <div class="flex justify-between q-pr-md q-pt-md" :class="col">
    <q-card flat
            class="app-dashcard full-width"
            :class="{'clickable': typeof value !== 'undefined' && parseInt(value) > 0}"
            :disabled="isLoading ? true : null"
            @click.capture.stop="this.$emit('goto')"
    >
      <loading-indicator v-if="isLoading" />
      <template v-else>
          <q-icon size="3rem" :name="icon" class="app-card-bg-icon"></q-icon>
          <q-item>
            <q-item-section>
              <q-item-label class="app-dashcard-label app-text-weight-semibold">{{ headline }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-card-section horizontal>
            <q-card-section class="col q-pt-none q-ml-sm q-pb-sm" data-cy="statisticCardValue">
              <span class="app-dashcard-value text-primary app-text-weight-semibold">{{ value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</span>
            </q-card-section>
        </q-card-section>
      </template>
    </q-card>
  </div>
</template>

<style lang="scss">
  .app-dashcard {
    min-height: 80px;
    &.clickable {
      cursor: pointer;
    }
  }
  .app-dashcard-value {
    font-size: 3rem;
    line-height: .5;
  }
</style>
