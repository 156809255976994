<script>

export default {
  name: 'SelectInputFilter',

  props: {
    type: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
    },
    translatable: {
      type: Boolean,
      default: false
    },
    mapped: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      model: (this.$props.defaultValue ? [this.$props.defaultValue] : null ) ?? []
    }
  },

  emits: ['updateModel'],

  watch: {
    model: {
      handler: function (newModel) {
        this.$emit('updateModel', newModel)
      },
      deep: true
    }
  }
}
</script>

<template>
  <template v-if="type === 'multiple'" >
    <q-select
      dense
      class="app-validation-table-filter-input"
      v-model="model"
      :options="options"
      :label="label"
      multiple
      map-options
      :emit-value="$props.mapped"
    >
      <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
        <q-item v-bind="itemProps">
          <q-item-section>
            <template v-if="!translatable">
              <q-item-label v-html="opt" />
            </template>
            <template v-if="translatable">
              <q-item-label>{{ $t('validation.severityLevelTypes.' + opt) }}</q-item-label>
            </template>
          </q-item-section>
          <q-item-section side>
            <q-toggle :model-value="selected" @update:model-value="toggleOption(opt)" />
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </template>

  <template v-else>
    <q-select
        dense
        class="app-validation-table-filter-input"
        v-model="model"
        :options="options"
        :label="label"
        :map-options="$props.mapped"
        :emit-value="$props.mapped"
    >
    </q-select>
  </template>
</template>

<style lang="scss">
  .app-validation-table-filter-input {
    min-width: 150px;
    width: fit-content;
  }
  body.body--dark .q-table__container .app-validation-table-filter-input {
    &.q-field.q-select--multiple {
      background: unset;
    }
  }
</style>
