<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import FileSystem from './components/FileSystem'
import FileManager from './components/FileManager'
import { pathRules } from 'src/helpers/name-validator'

export default defineComponent({
  name: 'Browser',
  components: {
    FileSystem,
    FileManager
  },
  data () {
    return {
      path: this.$route.path?.replace('/browser', '') !== '' ? this.$route.path?.replace('/browser', '') : "/",
      pathModel: '',
      filesystemName: ""
    };
  },
  computed: {
    ...mapGetters('fileSystem', [
      'isLoading',
      'isRefreshing'
    ])
  },
  methods: {
    updatePath(path) {
      this.$router.push('/browser' + path);
      this.path = path;
    },
    updateFilesystem(filesystem) {
      this.filesystemName = filesystem;
      this.updatePath(filesystem);
    },
    pathRules (val, castAsBool) {
      return pathRules(val, castAsBool, this, true)
    }
  },
  mounted() {
    this.pathModel = this.path;
  }
})
</script>

<template>
  <q-page class="block">
    <div class="q-pa-md">
      <h1>{{ $t('browser.headline') }}</h1>
      <p>{{ $t('browser.desc') }}</p>
      <div>
        <div class="col-12 row flex full-width">
          <div class="col q-pr-md">
            <q-input
                class="app-browser-path"
                dense outlined
                v-model="pathModel"
                @keydown.enter.prevent="updatePath(pathModel)"
                @blur="pathModel = path"
                :rules="[pathRules]"
            >
              <template v-slot:append>
                <q-btn
                    v-if="pathModel !== path"
                    round dense flat
                    icon="cancel"
                    @click.capture.stop='pathModel = path'
                />
                <q-btn
                    v-if="pathModel !== path && pathModel !== ''"
                    round dense flat
                    icon="send"
                    @click.capture.stop='updatePath(pathModel)'
                />
              </template>
            </q-input>
          </div>
        </div>
        <div class="app-browser flex row q-mt-md">
          <div class="app-browser-filetree-wrapper col-12 col-sm-3">
            <file-system @path="updateFilesystem" />
          </div>
          <div class="col-auto q-ma-sm flex"><q-separator vertical /></div>
          <div class="app-browser-files col">
            <file-manager
                :path="path"
                :filesystem-name="filesystemName"
                v-if="path !== '/'"
                @path-update="updatePath"
            />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<style lang="scss">
.app-browser-path .q-field__inner {
  background-color: $background2;
}
body.body--dark {
  .app-browser-path .q-field__inner {
    background-color: $dark-page;
  }
}
</style>
