<script>
  import { defineComponent } from 'vue'
  import { useQuasar } from 'quasar'

  export default defineComponent({
    name: 'Alert',
    props: {
      message: {
        /* Allows both, an i18n key or a direct message to be passed and displayed
        It is recommended to use i18n keys, for obvious reasons
        Fixed messages will only be passed by API - an error code for example

        i18n keys are passed as string.
        Example:
        "cronjob.cronjobAlreadyExists"
        (including the quotes) */
        required: true,
        type: [String, Object]
      },
      icon: {},
      color: {
        default: 'negative'
      },
      action: {},
      label: String,
      report: {},
    },

    setup () {
      const $q = useQuasar();
      return {
        showNotif (position) {
          // This component either receives an already translated string, or an i18n key.
          // It needs to be able to handle both.

          // Keys can never have spaces, and never start with uppercase - so handle as already translated string
          if(this.message.includes(" ") || this.startsWithUppercase(this.message) ) {
            this.showAlert(this.message, position);
          } else {
            // Translating i18n key
            this.showAlert(this.$t(this.message), position);
          }
          this.$store.dispatch('alert/clear');
        },
      }
    },
    computed: {
      timeout() {
        //return 250;
        return Math.random() * 3000 + 3000;
      }
    },
    methods: {
      startsWithUppercase(str) {
        if (typeof str === 'string') {
          return /^[A-Z]/.test(str.charAt(0));
        } else {
          return false;
        }
      },
      goToDetail(itemUrl) {
        this.$router.push(itemUrl)
      },
      showAlert(msg, position) {
        if (this.$props.action) {
          this.$q.notify({
            group: false,
            message: msg,
            color: this.color,
            icon: this.icon,
            position,
            timeout: this.timeout,
            actions: [
              {
                label: this.$props.label,
                class:'app-action-btn q-mr-sm',
                handler: this.$props.action
              }
            ]
          })
        } else {
          this.$q.notify({
            message: msg,
            color: this.color,
            icon: this.icon,
            position,
            timeout: this.timeout
          })
        }
      },
    },
    mounted() {
      this.showNotif('top-right')
    }
  })
</script>

<template>
</template>
