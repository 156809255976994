<script>
  import { cloneDeep } from 'lodash'
  import {
    VALIDATIONS,
    FIELD_TYPE_OPTIONS,
    VALIDATION_REQUIRED,
    VALIDATION_PATTERN,
    VALIDATION_MIN,
    VALIDATION_MAX,
    VALIDATION_MIN_LEN,
    VALIDATION_MAX_LEN
  } from 'pages/JsonToForm/utils'
  import SqInputField from 'pages/JsonToForm/Fields/SqInputField.vue'
  import SqInputNumberField from 'pages/JsonToForm/Fields/SqInputNumberField.vue'

  export default {
    name: 'ValidationGenerator',

    components: {
      SqInputField,
      SqInputNumberField
    },

    props: {
      modelValue: {
        type: Object,
        required: true
      },

      fieldType: {
        type: String,
        required: false
      }
    },

    emits: ['update:model-value', 'delete'],

    data() {
      return {
        value: Object.keys(this.modelValue),
        validatorDictionary: this.modelValue,
        VALIDATION_PATTERN,
        VALIDATION_MIN,
        VALIDATION_MAX,
        VALIDATION_MIN_LEN,
        VALIDATION_MAX_LEN
      }
    },

    computed: {
      selectableValidations() {
        const fieldType = FIELD_TYPE_OPTIONS.find(opt => opt.value === this.fieldType)

        if (!fieldType || !fieldType?.validators?.length) return []

        return VALIDATIONS.filter(validation => fieldType.validators.includes(validation.value))
      }
    },

    methods: {
      updateValue() {
        this.$emit('update:model-value', this.validatorDictionary)
      },

      remove() {
        this.$emit('delete')
      },

      buildValidator(value) {
        const oldValidators = cloneDeep(this.validatorDictionary)
        const newValidators = {}

        value.forEach(validator => {
          if (oldValidators[validator]) {
            newValidators[validator] = oldValidators[validator]

            return
          }

          newValidators[validator] = this.getProperties(validator)
        })

        this.validatorDictionary = newValidators

        this.updateValue()
      },

      getProperties(validator) {
        let value = null

        if (validator === VALIDATION_REQUIRED) value = true

        return {
          value
        }
      }
    },

    watch: {
      modelValue() {
        this.value = Object.keys(this.modelValue)
        this.validatorDictionary = this.modelValue
      }
    }
  }
</script>

<template>
  <div class="q-py-sm">
    <q-select
      v-model="value"
      clearable
      dense
      options-dense
      filled
      emit-value
      map-options
      multiple
      use-chips
      :options="selectableValidations"
      label="Validations"
      @update:model-value="buildValidator"
    >
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey">
            No option yet, please select a field type or the field type you selected does not support any validation
          </q-item-section>
        </q-item>
      </template>

      <template #after>
        <q-btn
          dense
          flat
          color="negative"
          icon="delete"
          @click="remove"
        />
      </template>
    </q-select>

    <div class="q-ml-lg">
      <template
        v-for="validator in value"
        :key="validator"
      >
        <sq-input-field
          v-if="validator === VALIDATION_PATTERN"
          v-model="validatorDictionary[validator].value"
          label="Pattern Validation"
          :validations="[{name: 'regExp', message: 'Invalid regular expression'}]"
          hint="Always include forward slashes at the start and end of your expression"
          clearable
          @update:model-value="updateValue"
        />

        <sq-input-number-field
          v-if="validator === VALIDATION_MIN || validator === VALIDATION_MIN_LEN"
          v-model="validatorDictionary[validator].value"
          label="Min Value"
          hint="If field is number type this will be the min value, if string it would be the length of the string and if array, it will be the size of the array"
          clearable
          @update:model-value="updateValue"
        />

        <sq-input-number-field
          v-if="validator === VALIDATION_MAX || validator === VALIDATION_MAX_LEN"
          v-model="validatorDictionary[validator].value"
          label="Max Value"
          hint="If field is number type this will be the max value, if string it would be the length of the string and if array, it will be the size of the array"
          clearable
          @update:model-value="updateValue"
        />
      </template>
    </div>
  </div>
</template>
