import client from '../helpers/authenticated-axios'

export const fileManagerService = {

  filemanagerLoad(filesystem, callback) {
    client().get('/file-manager/list' + filesystem.replace("%2F", "/")).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  addFile(path, file, callback) {
    client().post("/file-manager/upload-file" + path, [
      {
        "config": {
          "visibility": "public"
        },
        "file_name": file.name,
        "file_content": file.content
      }
    ]).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  addFileFromUrl(path, file, callback) {
    client().post("/file-manager/upload-url" + path, {
      "config": {
        "visibility": "public"
      },
      "file_name": file.name,
      "url": file.url
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  addFolder(path, folder, callback) {
    client().post("/file-manager/create-directory" + path, {
      "directory_name": folder,
      "config": {
        "visibility": "public"
      }
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  renameFile(path, oldName, newName, callback) {
    client().post("/file-manager/rename" + path, {
      "oldPath": oldName,
      "newPath": newName
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  copyFile(path, body, callback) {
    client().post("/file-manager/copy-file" + path, body).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  moveFile(path, body, callback) {
    client().post("/file-manager/move-file" + path, body).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error.response);
    });
  },

  deleteFile(path, file, callback) {
    client().post("/file-manager/delete" + path, {
      "names": [
        ...file
      ]
    }).then((response) => {
      callback(response.data)
    }).catch((error) => {
      callback(error);
    });
  },

  readFile(path, file, callback) {
    client().get("/file-manager/read-file" + path + "/" + file.basename).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(error.response);
    });
  }
};
