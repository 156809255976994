export default function () {
  return {
    getMessage: function(state) {
      return state.message;
    },
    getType: function(state) {
      return state.type;
    },
    getColor: function(state) {
      return state.color;
    },
    getAction: function(state) {
      return state.action;
    },
    getLabel: function(state) {
      return state.label;
    },
    setErrorData: function (state) {
      return state.report;
    },    
  }
}