import { supervisorService } from 'src/services'
import { freeComponent } from './mutations';

export function getSupervisor({ dispatch, commit, state }) {
  commit('startLoading');
  supervisorService.getSupervisor(function(data) {
    commit('receivedData', data['hydra:member'])
  })
}

export function refreshSupervisor({ dispatch, commit, state }) {
  commit('startReloading');
}

export function restartSupervisor({ dispatch, commit, state }) {
  commit('startLoading');
  supervisorService.restartSupervisor(function(data) {
    if(typeof data !== "undefined") {
      commit('receivedData', data['hydra:member'])
      if(data.success) {
        dispatch('alert/success', 'dashboard.supervisor.restartSuccess', { root: true });
        setTimeout(() => {
          dispatch('getSupervisor')
        }, 3500)
      } else if(typeof data.data !== "undefined") {
        if (data.data['@type'] === "hydra:Error" && typeof data.data['hydra:description'] !== "undefined") {
          if(data.data['hydra:description'].includes("Access Denied")) {
            dispatch('alert/error', 'dashboard.supervisor.restartDenied', { root: true });
          } else {
            dispatch('alert/error', data.data['hydra:description'], { root: true });
          }
        }
      }
    } else {
      dispatch('alert/error', 'dashboard.supervisor.restartFailed', { root: true });
      commit('freeComponent');
    }
  })
}
