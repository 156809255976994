<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { flowService } from 'src/services'

import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent({
  name: 'DeleteFlowModal',

  components: {
    SqDialog
  },

  emits: [
    'deleted'
  ],

  props: {
    flow: {
      type: Object
    }
  },

  setup (props, { emit }) {
    const $store = useStore()

    const isLoading = ref(false)
    const confirmDelete = ref('')
    const localFlow = ref({})

    function initialize() {
      isLoading.value = false
      confirmDelete.value = ''
      localFlow.value = { ...props.flow }
    }

    function deleteFlow() {
      if (confirmDelete.value.toLowerCase() !== props.flow.name.toLowerCase()) {
        $store.dispatch('alert/error', 'flow.overview.modal.deleteFlowConfirmError', { root: true });
      } else {
        isLoading.value = true
        flowService.deleteFlow(props.flow['id'], (data) => {
          isLoading.value = false
          if (data?.data['@type'] === "hydra:Error") {
            $store.dispatch('alert/error', 'flow.overview.modal.flowDeletionFailed', { root: true });
          } else {
            $store.dispatch('alert/success', 'flow.overview.modal.flowDeletionSuccess', { root: true });

            emit('deleted', data)
          }
        });
      }
    }

    return {
      isLoading,
      localFlow,
      confirmDelete,

      deleteFlow,
      initialize
    }
  },
  computed: {
    matchesFlow() {
      return this.confirmDelete.toLowerCase() === this.localFlow.name?.toLowerCase();
    }
  }
})
</script>

<template>
  <sq-dialog
    type="delete"
    :disabled="!matchesFlow"
    :save-button-label="$t('general.delete')"
    :loading="isLoading"
    @save="deleteFlow"
    @show="initialize"
  >
    <template #title>
      {{ $t('flow.overview.modal.deleteFlow') }}
    </template>

    <template #content>
      <q-form @submit="deleteFlow()">
        <div class="q-ml-sm q-mt-md">
          {{ $t('flow.overview.modal.deleteFlowConfirm', {flowId: localFlow.id, flowName: localFlow.name}) }}<br />
          {{ $t('flow.overview.modal.deleteFlowConfirm2') }}
        </div>

        <q-input
          v-model="confirmDelete"
          dense
          borderless
          :placeholder="localFlow.name"
          :hint="$t('flow.overview.modal.deleteFlowConfirmInputTitle', {flowName: localFlow.name})"
          :label="$t('flow.overview.modal.deleteFlowConfirmInputLabel')"
          :title="$t('flow.overview.modal.deleteFlowConfirmInputTitle', {flowName: localFlow.name})"
          data-cy="inputFlowName"
          class="app-confirm-delete-field q-mb-md q-pa-sm"
          :class="{ 'match': matchesFlow }"
          @keydown.enter.prevent="deleteFlow"
        />
      </q-form>
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  .app-confirm-delete-field {
    input {
      border-bottom: 2px solid $negative;
      transition: .5s;
    }
    &.match input {
      border-bottom: 2px solid $positive;
    }
  }
</style>
