import { messageQueueService } from 'src/services'

export function getMessageQueue({ dispatch, commit, state }) {
  commit('startLoading');
  messageQueueService.getMessageQueue(function(data) {
    commit('receivedData', data['hydra:member'])
  })
}

export function refreshMessageQueue({ dispatch, commit, state }) {
  commit('startLoading');
  messageQueueService.refreshMessageQueue(function(data) {
    commit('receivedData', data['hydra:member'])
  })
}
