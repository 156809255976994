<script>
  import { defineComponent, ref } from 'vue'
  import { useStore } from 'vuex'
  import { flowService } from 'src/services'
  import { fileNameRules } from 'src/helpers/name-validator'
  import JsonEditor from 'components/JsonEditor.vue'
  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'FlowModal',

    components: {
      SqDialog,
      JsonEditor
    },

    props: {
      flow: {
        type: Object,
        required: false
      },

      onFlowSaved: {
        type: Function,
        required: false
      }
    },

    emits: ['created', 'updated'],

    setup (props, { emit }) {
      const $store = useStore()

      const isLoading = ref(false)
      const flowName = ref('')
      const createFlowJsonConfig = ref('{}')

      function saveFlow() {
        isLoading.value = true

        if (!props.flow) {
          flowService.createFlow(flowName.value, createFlowJsonConfig.value, (data) => {
            isLoading.value = false

            if (data?.data['@type'] === "hydra:Error") {
              $store.dispatch('alert/error', 'flow.overview.modal.flowCreationFailed', { root: true });
            } else {
              $store.dispatch('alert/success', 'flow.overview.modal.flowCreationSuccess', { root: true });
            }

            emit('created', data)
          });
        }
        else {
          flowService.editFlow(flowName.value, props.flow['id'], (data) => {
            if (data?.data['@type'] === "hydra:Error") {
              $store.dispatch('alert/error', 'flow.overview.modal.flowEditFailed', { root: true });
            } else {
              $store.dispatch('alert/success', 'flow.overview.modal.flowEditSuccess', { root: true });
              $store.dispatch('flows/editedFlow');

              if (props.onFlowSaved) {
                props?.onFlowSaved();
              }

              emit('updated', data)
            }
          });
        }
      }

      function initialize() {
        if (props.flow) {
          flowName.value = props.flow.name
        }
      }

      function resetState() {
        flowName.value = ''
        createFlowJsonConfig.value = '{}'
        isLoading.value = false
      }

      return {
        flowName,
        isLoading,
        createFlowJsonConfig,

        saveFlow,
        initialize,
        resetState

      }
    },

    computed: {
      displayLabel() {
        return this.flow ? this.$t('flow.overview.modal.editFlow') : this.$t('flow.overview.modal.addFlow')
      }
    },
    methods: {
      fileNameRules (val, castAsBool) {
        return fileNameRules(val, castAsBool, this, true)
      }
    }
  })
</script>

<template>
  <sq-dialog
    :type="flow ? 'update' : 'create'"
    :disabled="!flowName || !createFlowJsonConfig"
    :save-button-label="displayLabel"
    :loading="isLoading"
    size="md"
    @save="saveFlow"
    @show="initialize"
    @hide="resetState"
  >
    <template #title>
      {{ displayLabel }}
    </template>

    <template #content>
      <q-form @submit="saveFlow()">
        <q-input
          v-model="flowName"
          :label="$t('flow.overview.modal.namePlaceholder')"
          data-cy="inputFlowName"
          :rules="[val => !!val || $t('flow.overview.modal.nameRequired')]"
          class="app-create-flow-name-input full-width q-mt-sm"
        />

        <template v-if="!flow">
          <div class="text-h6 q-mt-md">{{ $t('flow.overview.modal.jsonConfigHeadline') }}</div>
          <json-editor
            v-model="createFlowJsonConfig"
            wrapped
            data-cy="jsonEditorConfig"
            allow-empty
          />
        </template>
      </q-form>
    </template>
  </sq-dialog>
</template>
