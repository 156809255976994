<script>
export default {
  name: 'DefaultPropertyGenerator',

  emits: ['update:model-value', 'delete'],

  data() {
    return {
      value: []
    }
  },

  methods: {
    updateValue() {
      this.$emit('update:model-value', this.value)
    },

    remove() {
      this.$emit('delete')
    },

    handlePopupShow() {
      this.$refs.selector.setOptionIndex(-1)
    }
  }
}
</script>

<template>
  <q-select
    ref="selector"
    v-model="value"
    clearable
    dense
    options-dense
    filled
    emit-value
    map-options
    multiple
    use-chips
    use-input
    new-value-mode="add-unique"
    label="Default Properties"
    class="q-py-sm"
    @update:model-value="updateValue"
  >
    <template #after>
      <q-btn
        color="negative"
        icon="delete"
        @click="remove"
      />
    </template>
  </q-select>
</template>
