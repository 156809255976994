<script>
  import { defineComponent } from 'vue'

  import JsonEditor from 'components/JsonEditor.vue'
  import SqDialog from 'components/Common/SqDialog.vue'

  export default defineComponent({
    name: 'FailedMessageModal',

    components: {
      SqDialog,
      JsonEditor
    },

    props: {
      failedMessage: {
        type: Object
      }
    },

    methods: {
      goToFlowExecution (flowExectionId) {
        this.$router.push('/flowexecutions/detail/' + flowExectionId)
      }
    }

  })
</script>

<template>
  <sq-dialog
    size="md"
    class="failed-messages-modal"
  >

    <template #toolbar>
      <q-toolbar class="q-my-sm">
        <q-toolbar-title class="flex items-center">
          <q-avatar icon="running_with_errors" color="primary" class="q-mr-sm" text-color="white" />
          {{ $t('failedMessages.modal.title') }} Flow Execution

          <q-btn
            flat dense
            class="app-jump-to-execution app-text-weight-semibold text-subtitle2"
            icon-right="img:assets/icons/link.svg"
            :label="`#` + failedMessage.flowExecutionId"
            :title="$t('failedMessages.modal.jumpToExecution')"
            @click="goToFlowExecution(failedMessage.flowExecutionId)"
          />
        </q-toolbar-title>

        <q-btn icon="close" flat round dense v-close-popup />
      </q-toolbar>
    </template>

    <template #content>
      <div class="failed-messages-modal-content">
        <q-chip>FQCN</q-chip>
        <q-item>
          {{ failedMessage.fqcn }}
        </q-item>

        <q-chip>{{ $t('failedMessages.label.message') }}</q-chip>
        <q-item>
          {{ failedMessage.message }}
        </q-item>

        <q-chip>{{ $t('failedMessages.label.file') }}</q-chip>
        <q-item>
          {{ failedMessage.filename }}: L{{ failedMessage.line }}
        </q-item>

        <q-chip>Stack Trace</q-chip>
        <q-item>
          <json-editor disabled :model-value="failedMessage.trace" wrapped no-validation />
        </q-item>
      </div>
    </template>

    <template #actions>
      <q-btn flat color="primary"
        v-close-popup
        :label="$t('general.close')"
      />
    </template>
  </sq-dialog>
</template>

<style lang="scss">
  .failed-messages-modal {
    .failed-messages-modal-content {
      margin: 24px 24px;
      body.screen--xs & {
        margin: 24px 0;
      }
    }
    .q-chip {
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.375rem;
      letter-spacing: 0.00714em;
    }
    .q-item {
      padding-left: 24px;
      padding-bottom: 24px;
      body.screen--xs & {
        padding-left: 0;
        padding-bottom: 24px;
      }
    }
  }
</style>
