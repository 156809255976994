export default {
  initialized(state) {
    return state.initialized;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isRefreshing(state) {
    return state.isRefreshing;
  },
  filesystems(state) {
    return state.filesystems;
  }
}
