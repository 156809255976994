<script>
import { THEMES } from 'src/meta/preferences'

  export default {
    name: 'ThemePicker',

    props: {
      modelValue: {
        type: String,
        required: false
      }
    },

    emits: ['update:model-value'],

    data() {
      return {
        value: this.modelValue,
        THEMES
      }
    },

    computed: {
      selectedTheme() {
        if (!this.value) return ''

        return this.$t(THEMES.find(theme => theme.value === this.value)?.label)
      }
    },

    methods: {
      updateValue() {
        return this.$emit('update:model-value', this.value)
      }
    },

    watch: {
      modelValue(newState) {
        this.value = newState
      }
    }
  }
</script>

<template>
  <q-select
    v-model="value"
    dense
    outlined
    emit-value
    map-options
    :display-value="selectedTheme"
    :options="THEMES"
    :label="$t('preferences.themeSelect')"
    class="app-preferences-theme-input q-mr-sm q-mb-sm"
    @update:model-value="updateValue"
  >
    <template #option="{ opt, itemProps }">
      <q-item v-bind="itemProps">
        <q-item-section>
          <q-item-label>
            <span>{{ $t(opt.label) }}</span>
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
