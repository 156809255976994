<script>
  import ConfirmationDialog from 'components/Functional/ConfirmationDialog'
  import { parseJson } from 'src/utils'

  export default {
    name: "ObjectBuilderIndex",

    components: { ConfirmationDialog },

    data() {
      return {
        showConfirmation: false,
        selectedConfig: null,
        configList: [],
        columns: [
          {
            name: 'configName',
            field: 'configName',
            label: this.$t('jsonToForm.formBuilder.table.configName'),
            align: 'left'
          },

          {
            name: 'actions',
            label: this.$t('common.action', 2),
            align: 'left'
          }
        ],
        selectedFile: null
      }
    },

    methods: {
      createNewFormConfig() {
        this.$router.push({ name: 'CreateForm' })
      },

      previewForm(row) {
        this.$router.push({ name: 'FormPreview', params: { id: row.id } })
      },

      editConfig(config) {
        this.$router.push({ name: 'UpdateForm', params: { id: config.id } })
      },

      deleteConfig(row) {
        this.showConfirmation = true
        this.selectedConfig = row
      },

      closeDeleteDialog() {
        this.showConfirmation = false
        this.selectedConfig = null
      },

      exportSchema(config) {
        const fileName = `${config.configName}.json`;
        const url = window.URL.createObjectURL(
          new Blob([JSON.stringify(config.schema, null, 2)], { type: 'application/json' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      },

      deletionConfirmed() {
        const index = this.configList.findIndex(item => item.id === this.selectedConfig.id)

        this.$q.notify({
          type: 'success',
          color: 'positive',
          position: 'top-right',
          message: this.$t('jsonToForm.formBuilder.notification.schemaDeleted', { name: this.selectedConfig.configName })
        })

        if (index > -1) {
          this.configList.splice(index, 1)

          localStorage.setItem('configs', JSON.stringify(this.configList))

          this.closeDeleteDialog()
        }
      },

      handleFileUpload() {
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          const schema = parseJson(reader.result)
          this.editConfig({
            schema,
            configName: this.selectedFile.name
          })
          this.createNewFormConfig()
        }, false);

        if (this.selectedFile) {
          reader.readAsText(this.selectedFile)
        }
      }
    },

    created() {
      const data = localStorage.getItem('configs')

      if (data) {
        this.configList = JSON.parse(data)
      }
    }
  }
</script>

<template>
  <q-page class="block q-pa-md relative-position">
    <h1>{{ $t('jsonToForm.formBuilder.formConfigList') }}</h1>

    <div class="row">
      <div class="col-12 col-sm-auto">
        <q-btn
          dense
          color="primary"
          icon="add"
          class="q-pa-sm q-pr-md q-mr-sm-sm"
          :label="$t('jsonToForm.formBuilder.createNewConfig')"
          @click="createNewFormConfig"
        />
      </div>
      <div class="col-12 col-sm-auto">
        <q-file
          v-model="selectedFile"
          type="file"
          :label="selectedFile ? $t('jsonToForm.formBuilder.loadedSchema') : $t('jsonToForm.formBuilder.loadSchema')"
          clearable
          outlined
          dense
          style="max-width: 200px"
          class="q-mt-md q-mt-sm-none q-ml-sm-md"
          @clear="selectedFile = null"
          @update:model-value="handleFileUpload"
        >
          <template v-slot:prepend>
            <q-icon name="attach_file" />
          </template>
        </q-file>
      </div>
    </div>

    <div class="q-mt-md">
      <span class="text-bold">{{ $t('common.note') }}</span>:
      <span>
        {{ $t('jsonToForm.formBuilder.formBuilderListNote') }}
      </span>
    </div>

    <q-table
      hide-bottom
      :rows="configList"
      :columns="columns"
      :rows-per-page-options="[25, 50, 100, 500]"
      :pagination="{
        sortBy: 'desc',
        page: 1,
        rowsPerPage: 0
      }"
      row-key="name"
      class="q-mt-md"
    >
      <template #body-cell-actions="{ row }">
        <q-td>
        <span>
          <q-btn
            dense
            flat
            round
            color="primary"
            size="sm"
            icon="edit"
            class="q-mx-xs"
            @click="editConfig(row)"
          />
          <q-tooltip>{{ $t('jsonToForm.formBuilder.table.editForm') }}</q-tooltip>
        </span>

        <span>
          <q-btn
            dense
            flat
            round
            size="sm"
            icon="preview"
            class="q-mx-xs"
            @click="previewForm(row)"
          />
          <q-tooltip>{{ $t('jsonToForm.formBuilder.table.previewForm') }}</q-tooltip>
        </span>

        <span>
          <q-btn
            dense
            flat
            round
            color="positive"
            size="sm"
            icon="save_alt"
            class="q-mx-xs"
            @click="exportSchema(row)"
          />
          <q-tooltip>{{ $t('jsonToForm.formBuilder.table.exportSchema') }}</q-tooltip>
        </span>

        <span>
          <q-btn
            dense
            flat
            round
            color="negative"
            size="sm"
            icon="delete"
            class="q-mx-xs"
            @click="deleteConfig(row)"
          />
          <q-tooltip>{{ $t('jsonToForm.formBuilder.table.deleteForm') }}</q-tooltip>
        </span>
        </q-td>
      </template>
    </q-table>

    <div v-if="0 <= this.configList" class="q-mt-md text-center text-italic">
      <span>
        {{ $t('jsonToForm.formBuilder.formBuilderEmptyList') }}
      </span>
    </div>

    <confirmation-dialog
      v-model="showConfirmation"
      :title="`${$t('common.delete')} '${selectedConfig?.configName}' ${$t('common.config')}`"
      type="negative"
      @confirm="deletionConfirmed"
      @close="closeDeleteDialog"
    >
      <template #content>
        <span>
          {{ $t('jsonToForm.formBuilder.dialog.deleteSchemaConfirmation') }}
        </span>
      </template>
    </confirmation-dialog>
  </q-page>
</template>