<script>
import { defineComponent, ref, computed } from 'vue'
import { useQuasar } from 'quasar'
import { useStore } from "vuex";
import { flowService } from 'src/services'

import JsonEditor from 'components/JsonEditor.vue'
import SqDialog from 'components/Common/SqDialog.vue'

export default defineComponent ({
  name: 'ImportFlowModal',

  components: {
    JsonEditor,
    SqDialog
  },

  emits: ['imported'],

  setup () {
    const $store = useStore()
    const $q = useQuasar()
  },
  data () {
    return {
      importFile: null,
      isLoading: false,
      validJson: null,
      importFlowText: ''
    }
  },
  computed: {
    canSave() {
      return this.importFlowText || this.importFile;
    }
  },
  methods: {
    initialize() {
      this.importFile = null
      this.importFlowText = ''
      this.isLoading = false
      return () => { this.importFile, this.isLoading, this.importFlow }
    },
    importFlow() {
      if (!this.canSave) {
        this.$q.notify({
          color: 'negative',
          message: this.$t('general.noValidData')
        })
        return
      }
      this.isLoading = true;
      flowService.importFlow(this.importFlowText, this.importFile, (data) => {
        try {
          if(data.message) {
            Object.entries(data.message).forEach(([key, name]) => {
              setTimeout(() => {
                this.$store.dispatch('alert/success', {
                  message: this.$t('flow.flowImportSuccess', {flowName: name}),
                  label: this.$t('flow.overview.view'),
                  action: () => { this.$router.push('/flow/' + key) }
                }, { root: true }
                );
              }, 10);
            });
          }
        } catch (e) {
          console.error(e)
          this.$store.dispatch('alert/error', { message: this.$t('flow.flowImportFail', {flowName: name})}, { root: true });
        } finally {
          this.isLoading = false;
        }

        this.$emit('imported', {
          imported: (this.importFlowText || this.importFile) !== null
        })
      });
    },
    checkValidJson(valid) {
      this.validJson = valid;
    }
  }
})
</script>

<template>
  <sq-dialog
    type="import"
    :disabled="(!importFlowText || importFlowText === '' || !validJson) && !importFile"
    :save-button-label="$t('flow.overview.modal.importButton')"
    :loading="isLoading"
    size="md"
    @save="importFlow"
    @show="initialize"
  >
    <template #title>
      {{ $t('flow.overview.modal.importFlow') }}
    </template>

    <template #content>
      <q-form>
        <div class="text-h6 q-mt-md">{{ $t('flow.overview.modal.flowsImportText') }}</div>
        <json-editor
          v-model="importFlowText"
          :disabled="importFile !== null"
          max-height="55vh"
          wrapped
          @is-valid-json="checkValidJson"
          allow-empty
        />

        <div class="row flex-center q-my-md">
          <div class="col"><q-separator /></div>
          <div class="col-auto q-mx-md">{{ $t('general.or').toUpperCase() }}</div>
          <div class="col"><q-separator /></div>
        </div>
        <div class="text-h6 q-mt-md">{{ $t('flow.overview.modal.importFile') }} (.csv/.txt/.json)</div>
        <q-file clearable outlined v-model="importFile" accept=".txt, .csv, .json" class="q-mt-sm q-mb-md">
          <template v-slot:prepend>
            <q-icon name="attach_file" />
          </template>
        </q-file>
      </q-form>
    </template>
  </sq-dialog>
</template>
